import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import UserBgImg from "../header/images/profile_1.png";
import { checkImage } from "../util/helperfunctions";

let logoutTimer;

//Check client's name contain "demo" or "test" or "champion" in any case (case-insensitive),
const isClientDemo = (name) => {
  const regex = /^(?!.*(demo|test|champion)).*$/i;
  return regex.test(name);
};

export const useAuth = () => {
  const { instance } = useMsal();
  const { i18n } = useTranslation();
  const [token, setToken] = useState(false);
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [userId, setUserId] = useState(false);
  const [fname, setFname] = useState(false);
  const [access, setAccess] = useState(false);
  const [usageId, setUsageId] = useState(false);
  const [image, setImage] = useState(false);
  const [tags, setTags] = useState(false);
  const [clientName, setClientName] = useState("");
  const [npsStatus, setNpsStatus] = useState(false);
  const [authType, setAuthType] = useState(null);
  const [email, setEmail] = useState(null);
  const [language, setLanguage] = useState(false);

  const login = useCallback(
    async (
      uid,
      token,
      fname,
      expirationDate,
      access,
      image,
      tags,
      clientName,
      npsStatus,
      authType,
      email,
      userLanguage
    ) => {
      const isValidImage = await checkImage(`${image}`);

      if (isValidImage) {
        setImage(() =>
          // force cached image to refresh
          image?.startsWith("https://")
            ? `${image}?cacheBust=${new Date().getTime()}`
            : image
        );
      } else {
        setImage(UserBgImg);
      }
      //Store data to session state
      setToken(token);
      setUserId(uid);
      setFname(fname);
      setAccess(access);
      setUsageId(usageId);
      setTags(tags);
      setClientName(clientName);
      setNpsStatus(npsStatus);
      setAuthType(authType);
      setEmail(email);
      setLanguage(userLanguage);
      const tokenExpirationDate =
        expirationDate ||
        new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 3); //3 days from loggin in

      setTokenExpirationDate(tokenExpirationDate);
      if (!!userLanguage) {
        languageHandler(userLanguage);
      }

      if (!isClientDemo(clientName)) {
        //Identify people to Customer IO - TODO Add dept, sub_dept and region
        window._CIO.identify({
          id: uid,
          email: email,
          name: fname,
          client: clientName,
          access: access,
          //department: client.departments[Number(dept) - 1],
          //sub_department: client.sub_departments[Number(dept) - 1][Number(sub_dept) - 1],
          //regionid: regionid,
        });
      }

      //Store data to local storage
      localStorage.setItem(
        "userData",
        JSON.stringify({
          userId: uid,
          token: token,
          expiration: tokenExpirationDate.toISOString(),
          fname: fname,
          access: access,
          usageId: usageId,
          image: image,
          clientName: clientName,
          npsStatus: npsStatus,
          authType: authType,
          email: email,
        })
      );
      localStorage.setItem(
        "userTags",
        JSON.stringify({
          tags: tags,
        })
      );
    },
    []
  );

  const logout = useCallback(() => {
    //Remove session from microsoft
    if (authType === "Microsoft") {
      instance.logoutRedirect();
    }

    if (!isClientDemo(clientName)) {
      //Stop identifying a person for Customer IO
      window._CIO.reset();
    }

    //Remove stored data to session state
    setToken(null);
    setTokenExpirationDate(null);
    setUserId(null);
    setFname(null);
    setAccess(null);
    setUsageId(null);
    setImage(null);
    setTags(null);
    setClientName("");
    setNpsStatus(null);
    setAuthType(null);
    setEmail(null);
    setLanguage(null);

    // To clear the whole data stored in localStorage
    localStorage.clear();

    // Remove all saved data from sessionStorage
    sessionStorage.clear();

    //Reset route location
    document.location.href = "/";
  }, []);

  const profileHandler = useCallback((user) => {
    setFname(user.name);
    setEmail(user.email);
    if (user.image.startsWith("https://championhealth1.s3")) {
      setImage(`${user.image}?cacheBust=${new Date().getTime()}`);
    }

    //Set local storage with updated values
    const data = JSON.parse(localStorage.getItem("userData"));
    data.fname = user.name;
    data.email = user.email;
    data.image = user.image;
    localStorage.setItem("userData", JSON.stringify(data));
  }, []);

  const languageHandler = useCallback(async (newLang) => {
    setLanguage(newLang);
    await i18n.changeLanguage(newLang);
    localStorage.setItem("i18nextLng", newLang); // Store the selected language
  }, []);

  const npsStatusHandler = useCallback((npsStatus) => {
    setNpsStatus(npsStatus);
    const data = JSON.parse(localStorage.getItem("userData"));
    data.npsStatus = npsStatus;
    localStorage.setItem("userData", JSON.stringify(data));
  }, []);

  const tagHandler = useCallback((tags) => {
    setTags(tags);
    //alert('taghandler='+tags.length);
    //const tags = JSON.parse(localStorage.getItem('userTags'));
    localStorage.setItem(
      "userTags",
      JSON.stringify({
        tags: tags,
      })
    );
  }, []);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    const storedTags = JSON.parse(localStorage.getItem("userTags"));
    const storedLanguage = localStorage.getItem("i18nextLng");
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(
        storedData.userId,
        storedData.token,
        storedData.fname,
        new Date(storedData.expiration),
        storedData.access,
        storedData.image,
        storedTags.tags,
        storedData.clientName,
        storedData.npsStatus,
        storedData.authType,
        storedData.email,
        storedLanguage
      );
    }
  }, [login]);

  useEffect(() => {
    const storedTags = JSON.parse(localStorage.getItem("userTags"));
    if (storedTags && storedTags.tags) {
      setTags(storedTags.tags);
    }
  }, [tagHandler]);

  return {
    token,
    login,
    logout,
    userId,
    fname,
    tokenExpirationDate,
    access,
    usageId,
    image,
    tags,
    clientName,
    npsStatus,
    authType,
    email,
    language,
    profileHandler,
    npsStatusHandler,
    tagHandler,
    languageHandler,
  };
};
