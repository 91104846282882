import { useCallback, useEffect, useState } from "react";
import PinkButton1 from "../../../shared/components/styledComponents/pinkButton1/PinkButton1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/pro-light-svg-icons";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import GreenCheckOverlay from "../../../shared/modal/greenCheckOverlay/GreenCheckOverlay";
import ChevronBackBtn from "../../../shared/components/styledComponents/chevronBackBtn/ChevronBackBtn";
import { useTranslation } from "react-i18next";

const ChangePasswordRightMenu = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { t } = useTranslation();
  const [passwordState, setPasswordState] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    validPassword: false,
    passwordsMatch: false,
    changeFailed: false,
  });

  const [disabled, setDisabled] = useState(true);
  const [showGreenCheck, setShowGreenCheck] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const toggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const showGreenCheckOverlay = () => {
    setShowGreenCheck(true);
    setTimeout(() => {
      setShowGreenCheck(false);
    }, 2000);
  };

  const handlePasswordChange = useCallback((event) => {
    const { name, value } = event.target;
    setPasswordState((prevState) => {
      const updatedState = {
        ...prevState,
        [name]: value,
      };

      if (name === "newPassword") {
        updatedState.validPassword =
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*]{8,}$/.test(value);
        updatedState.passwordsMatch = value === prevState.confirmNewPassword;
      } else if (name === "confirmNewPassword") {
        updatedState.passwordsMatch = value === prevState.newPassword;
      } else if (name === "currentPassword" && prevState.changeFailed) {
        updatedState.changeFailed = false;
      }

      return updatedState;
    });
  }, []);

  const handleChangePassword = async () => {
    if (!disabled) {
      clearError();
      let responseData;
      try {
        responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/profile/credentials`,
          "POST",
          JSON.stringify({
            currentCode: passwordState.currentPassword,
            newCode: passwordState.newPassword,
            type: "password",
          })
        );

        if (responseData) {
          showGreenCheckOverlay();
        }
      } catch (err) {
        console.log(err);
        setPasswordState((prevState) => ({ ...prevState, changeFailed: true }));
      }
    }
  };

  useEffect(() => {
    if (
      passwordState.currentPassword.length > 7 &&
      passwordState.newPassword.length > 7 &&
      passwordState.confirmNewPassword.length > 7 &&
      passwordState.validPassword &&
      passwordState.passwordsMatch
    ) {
      setDisabled(false);
    }
  }, [
    passwordState.currentPassword,
    passwordState.newPassword,
    passwordState.confirmNewPassword,
    passwordState.validPassword,
    passwordState.passwordsMatch,
  ]);

  const renderPasswordField = useCallback(
    (fieldName, fieldDescription, showPassword, toggleShowPassword) => {
      let errorCondition = false;
      let errorMessage = "";
      if (fieldName === "currentPassword" && passwordState.changeFailed) {
        errorCondition = true;
        errorMessage = (
          <>
            {t("S057")} <a href="/reset">{t("S060")}</a>
          </>
        );
      } else if (
        fieldName === "newPassword" &&
        passwordState.newPassword.length > 0 &&
        !passwordState.validPassword
      ) {
        errorCondition = true;
        errorMessage = t("S058");
      } else if (
        fieldName === "confirmNewPassword" &&
        passwordState.confirmNewPassword.length > 0 &&
        !passwordState.passwordsMatch
      ) {
        errorCondition = true;
        errorMessage = t("S059");
      }

      return (
        <>
          <div className={`field-cont ${errorCondition ? "error" : ""}`}>
            <span className="field-descr">{fieldDescription}</span>
            <div className="passw-field">
              <input
                type={showPassword ? "text" : "password"}
                name={fieldName}
                value={passwordState[fieldName]}
                onChange={handlePasswordChange}
              ></input>
              <span onClick={toggleShowPassword}>
                <FontAwesomeIcon
                  icon={showPassword ? faEye : faEyeSlash}
                  className="icon"
                />
              </span>
            </div>
          </div>
          {errorCondition && <div className="error">{errorMessage}</div>}
        </>
      );
    },
    [passwordState, handlePasswordChange]
  );

  return (
    <>
      {props.profile && (
        <>
          {showGreenCheck && <GreenCheckOverlay />}
          <div className="edit-field">
            {props.hide && (
              <div className="btn-cont ">
                <ChevronBackBtn onClick={() => props.changeMenu(1, "back")} />
              </div>
            )}
            <div className="edit-title">{t("S026")}</div>
            <p className="cm profile">{t("S033")}</p>
            {renderPasswordField(
              "currentPassword",
              t("S034"),
              showCurrentPassword,
              toggleShowCurrentPassword
            )}
            {renderPasswordField(
              "newPassword",
              t("S035"),
              showNewPassword,
              toggleShowNewPassword
            )}
            {renderPasswordField(
              "confirmNewPassword",
              t("S036"),
              showConfirmPassword,
              toggleShowConfirmPassword
            )}
            <div className="bold-sm-txt cm">
              {" "}
              <a href="/reset"> {t("S121")}</a>
            </div>
            <PinkButton1
              disabled={disabled}
              className="wide marginTop30"
              onClick={handleChangePassword}
            >
              {t("S026")}
            </PinkButton1>
          </div>
        </>
      )}
    </>
  );
};

export default ChangePasswordRightMenu;
