import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "../styles/componentStyles/TopThreeChart.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt, faCheck, faTimes } from "@fortawesome/pro-regular-svg-icons";
import {
  faQuestionCircle,
  faArrowPointer,
  faCircleX,
  faShieldCheck,
} from "@fortawesome/pro-solid-svg-icons";
import { activity_list, reasonStress } from "../../shared/components/data";

function TopThreeChart(props) {
  const { t } = useTranslation();

  const [values, setValues] = useState(null);
  // const [progress, setProgress] = useState("50%");
  const [totalValue, setTotalValue] = useState(null);

  useEffect(() => {
    if (props.data && props.data.length > 0 && props.data[0] !== null) {
      let valuesArr = [];
      if (props.title === t("D061")) {
        //top three activities
        props.data.forEach((item) => {
          let activity = activity_list.find((el) => el.id === item.id);
          valuesArr.push({
            id: item.id,
            value: item.value,
            label: activity.label,
          });
        });
      } else {
        //top three causes of stress
        props.data.forEach((item) => {
          let reason = reasonStress.find((el) => el.id === item.id);
          valuesArr.push({
            id: item.id,
            value: item.value,
            label: reason.label,
          });
        });
      }
      valuesArr.sort((a, b) => b.value - a.value);
      setValues(valuesArr);
      let totalVal = 0;
      for (let i = 0; i < props.data.length; i++) {
        totalVal += parseFloat(props.data[i].value);
      }
      setTotalValue(totalVal);
    }
  }, [props.data]);

  return (
    <div className={"chart-container " + props.width}>
      <div className="title-container">
        <div className="title">{props.title}</div>{" "}
        <div className="wtf">
          <FontAwesomeIcon
            icon={faQuestionCircle}
            className="wtf icon"
            onClick={() => props.openModal(props.title, props.modalText)}
          />
        </div>
      </div>
      <div className="top-three-list">
        {values &&
          values.map((item, index) => {
            if (index < 3) {
              return (
                <div className="row" key={index}>
                  <div className="row-title">{t(item.label)}</div>
                  <div className="row-content">
                    <div className="progress-container">
                      <div
                        className="progress"
                        style={{ width: `${(item.value * 100) / totalValue}%` }}
                      ></div>
                    </div>
                    <div className="values">
                      <div className="value">{item.value}</div>
                      <div className="units">{props.units}</div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        {!values && <div className="chart-no-data">{t("D075")}</div>}
      </div>
    </div>
  );
}

export default TopThreeChart;
