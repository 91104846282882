const TrackerType = {
  Sleep: "sleep",
  Activity: "activity",
  Stress: "stress",
  Mood: "mood",
  Nutrition: "nutrition",
  Hydration: "hydration",
  Energy: "energy",
  Alcohol: "alcohol",
  Smoking: "smoking",
  Productivity: "productivity",
};

exports.TrackerType = TrackerType;
