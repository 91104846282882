import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "../styles/componentsStyles/LeaderBoard.css";

import TopLeaders from "./TopLeaders";
import BoardList from "./BoardList";

const LeaderBoard = ({
  isActive,
  isChallengeUnavailable,
  handleToggle,
  isOn,
  leaders,
  users,
  challengeType,
  userPlace,
  buttonText,
  handleButtonClick
}) => {
  const { t } = useTranslation();
  const emptyLeaders = Array.from(Array(3).keys());
  const [dataToMap, setDataToMap] = useState(emptyLeaders);

  useEffect(() => {
    if (leaders.length > 0) {
      setDataToMap(leaders);
    } else {
      setDataToMap(emptyLeaders);
    }
  }, [leaders]);

  return (
    <div
      className={`chart-container leaderboard tile half ${
        isActive || isChallengeUnavailable ? "active" : "inactive"
      }`}
    >
      <div className="title-container leader-board">
        <div className="title">{t("CH028")}</div>
        {isActive && (
          <div className="switcher-container">
            {" "}
            <div className="subtitle">{t("CH029")}</div>
            <div className="">
              <div
                className={`whc-switch ${isOn ? "on" : "off"}`}
                onClick={handleToggle}
              >
                <div className="whc-slider-button"></div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="leaders-content-wrapper">
        <TopLeaders
          dataToMap={dataToMap}
          challengeType={challengeType}
          leadersNumber={leaders.length}
          buttonText={buttonText}
          isActive={isActive}
          handleButtonClick={handleButtonClick}
        />
        {users.length === 0 ? (
          <div className="leaders-noleaders">
            {isChallengeUnavailable ? t("CH040") : t("CH030")}
          </div>
        ) : (
          <BoardList
            users={users}
            challengeType={challengeType}
            userPlace={userPlace}
            leaders={leaders}
          />
        )}
      </div>
    </div>
  );
};

export default LeaderBoard;
