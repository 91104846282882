import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/sharp-solid-svg-icons";
import { faCalendar, faHouse } from "@fortawesome/pro-light-svg-icons";
import { faUsers } from "@fortawesome/pro-solid-svg-icons";

import "./styles/Challenges.css";
import LeaderBoard from "./components/LeaderBoard";
import FinishedChallenge from "./components/FinishedChallenge";
import {
  useUpdateChallengesConsentMutation,
  useRemoveChallengesConsentMutation,
  useFetchChallengeQuery,
} from "./challengesApiSlice";
import {
  emptyChallenge,
  setChallenge,
  setChallengeAfterToogleConsent,
} from "./challengesSlice";
import ChallengeErrorModal from "./components/ChallengeErrorModal";
import CircleChallengeGraph from "./components/CircleChallengeGraph";

import Header from "../shared/header/MainHeader";
import Footer from "../shared/navfooter/Footer";
import LoadingSpinner from "../shared/components/UIElements/LoadingSpinner";
import { AuthContext } from "../shared/context/auth-context";
import {
  getChallengeTypeUnit,
  getFormattedValue,
  shouldShowChallenge,
  shouldHideNavChallenge,
  getChallengeType,
  getChallengeIcon,
} from "../shared/util/challenges";
import { haNavigation, pages } from "../assets/constants";
import WhitePopUpCTA from "../shared/modal/whitePopUp/WhitePopUpCTA";

const Challenge = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const pageid = window.location.pathname.split("/")[1];
  const activePage = "challenge";
  const section = pages.find((page) => page.url === pageid);
  const sectionid = section?.id;

  const [showConsentModal, setShowConsentModal] = useState(false);
  const [showCompletedModal, setShowCompletedModal] = useState(false);
  const [showSubmitTrackerModal, setShowSubmitTrackerModal] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [isChallengeVisible, setIsChallengeVisible] = useState(false);
  const [isChallengeUnavailable, setIsChallengeUnavailable] = useState(false);
  const challenge = useSelector((state) => state?.challenges?.challenge);
  const [updateChallengesConsent] = useUpdateChallengesConsentMutation();
  const [removeChallengesConsent] = useRemoveChallengesConsentMutation();

  const {
    data: challengeData,
    isLoading,
    error,
  } = useFetchChallengeQuery(
    {
      token: auth.token,
      challengeType: getChallengeType(sectionid),
    },
    { skip: !getChallengeType(sectionid), refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (!isLoading && challengeData?.message === "No challenge found") {
      setIsChallengeUnavailable(true);
    } else {
      setIsChallengeUnavailable(false);
    }
  }, [isLoading, challengeData]);

  useEffect(() => {
    if (!isLoading && !challengeData && !getChallengeType(sectionid)) {
      navigate(`/${pageid}/recommended`);
    }
  }, [isLoading, challengeData, sectionid]);

  useEffect(() => {
    if (shouldShowChallenge(sectionid, challenge?.type)) {
      setIsChallengeVisible(true);
    } else {
      setIsChallengeVisible(false);
    }
  }, [sectionid, challenge]);

  useEffect(() => {
    if (error) {
      setErrorText(t("CH044"));
    }
    if (
      !isLoading &&
      !error &&
      challengeData &&
      !challengeData?.message &&
      challengeData?.start_date &&
      new Date().getTime() >= new Date(challengeData.start_date).getTime()
    ) {
      dispatch(
        setChallenge({
          challenge: challengeData,
        })
      );
    } else {
      dispatch(
        setChallenge({
          challenge: emptyChallenge,
        })
      );
    }
  }, [challengeData, isLoading, error]);

  useEffect(() => {
    if (challenge?.isActive === null) return;
    if (!isLoading && !challenge?.isActive) {
      setShowCompletedModal(true);
    } else {
      setShowCompletedModal(false);
    }
  }, [challenge, isLoading]);

  const toggleActivePage = (tabid) => {
    if (tabid === 3) return;
    navigate(`/${pageid}/${haNavigation[tabid].title}`, {
      state: { from: "challenge" },
    });
  };

  const handleToggleConsentSwitch = async () => {
    if (!challenge?.userGaveConsent && challenge?.userProgressOverall > 0) {
      setShowConsentModal(true);
    } else if (
      !challenge?.userGaveConsent &&
      challenge?.userProgressOverall === 0
    ) {
      setShowSubmitTrackerModal(true);
    } else {
      try {
        const result = await removeChallengesConsent({
          token: auth.token,
          challengeId: challenge?._id,
        });

        if (result?.data) {
          dispatch(
            setChallengeAfterToogleConsent({
              challenge: result.data,
            })
          );
        }
        if (result?.error) {
          setErrorText(t("CH043"));
        }
      } catch (error) {
        setErrorText(t("CH043"));
      }
    }
  };

  const handleCloseConsentModal = async () => {
    setShowConsentModal(false);
    try {
      const result = await updateChallengesConsent({
        token: auth.token,
        challengeId: challenge?._id,
      });
      if (result?.data) {
        dispatch(
          setChallengeAfterToogleConsent({
            challenge: result.data,
          })
        );
      }
      if (result?.error) {
        setErrorText(t("CH041"));
      }
    } catch (error) {
      setErrorText(t("CH041"));
    }
  };

  const handleCloseSubmitTrackerModal = () => {
    setShowSubmitTrackerModal(false);
    toggleActivePage(2);
  };

  const handleClosePopup = () => {
    setShowCompletedModal(false);
  };

  const consentModalContents = {
    title: t("CH003"),
    textArr: [
      {
        text: t("CH004"),
      },
    ],
    buttons: [
      {
        text: t("CH005"),
        color: "dark",
        onClick: () => {
          setShowConsentModal(false);
        },
      },
      {
        text: t("CH006"),
        color: "pink",
        onClick: () => {
          handleCloseConsentModal();
        },
      },
    ],
  };
  const submitTrackerModalContents = {
    title: t("CH035"),
    textArr: [
      {
        text: t("CH036"),
      },
    ],
    buttons: [
      {
        text: t("CH005"),
        color: "dark",
        onClick: () => {
          setShowSubmitTrackerModal(false);
        },
      },
      {
        text: t("CH037"),
        color: "pink",
        onClick: () => {
          handleCloseSubmitTrackerModal();
        },
      },
    ],
  };

  return (
    <div id="main_container" className="foryou glowrm platform challenge">
      {isLoading && <LoadingSpinner asOverlay />}
      <div id="nav_container">
        <Header />
        <div className="heading">
          <React.Fragment>
            <Link to="/for-you">
              <FontAwesomeIcon icon={faHouse} className="icon home" />
            </Link>
            <FontAwesomeIcon icon={faChevronRight} className="icon" />
            <h2>{pageid.replaceAll(/%20/g, " ").replaceAll("-", " ")}</h2>
          </React.Fragment>
          <ul className="nav_slider na">
            {haNavigation.map((item, index) => {
              if (pageid === "financial-wellbeing" && item.target === 23) {
                return null;
              } else if (
                item &&
                (shouldHideNavChallenge(pageid) ||
                  auth.access === null ||
                  auth.access === "2") &&
                item.target === 24
              ) {
                return null;
              } else {
                return (
                  <li
                    key={item.target}
                    id={index}
                    className={item.title === activePage ? "active" : ""}
                    onClick={() => {
                      toggleActivePage(index);
                    }}
                  >
                    {item.title}
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </div>
      {(isChallengeVisible || isChallengeUnavailable) && (
        <div className="page-cont visible">
          <div
            className="results-img"
            style={{
              background: `url(${
                challenge?.background_image ??
                "https://res.cloudinary.com/champion-health/image/upload/v1720000334/Challenges/no-challenge.jpg"
              }) center/cover no-repeat`,
            }}
          >
            <div className="gradient-overlay">
              <div className="hero-contents">
                <p className="hero-subtitle">{t("CH008")}</p>
                <h1 className="hero-title">{challenge?.title ?? t("CH038")}</h1>
                <h3
                  className={`challenge-description ${
                    isChallengeUnavailable || !challenge?.isActive
                      ? "challenge-description-inactive"
                      : ""
                  }`}
                >
                  {challenge?.description ?? t("CH039")}
                </h3>
                {challenge?.isActive && (
                  <button
                    className="feature-btn challenges"
                    onClick={() => toggleActivePage(2)}
                  >
                    {t("CH009")}
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="results-container challenges">
            <CircleChallengeGraph
              title={t("CH008")}
              subtitle={t("CH010")}
              value={challenge?.participantsNumber ?? 0}
              colour="#F69358"
              width="quarter"
              icon={faUsers}
              unit={t("CH011")}
            />
            <CircleChallengeGraph
              title={t("CH008")}
              subtitle={t("CH012")}
              value={challenge?.isActive ? challenge?.daysLeft : 0}
              colour="#FCC954"
              width="quarter"
              icon={faCalendar}
              unit={
                challenge?.isActive || isChallengeUnavailable
                  ? t("CH013")
                  : t("CH014")
              }
              isActive={challenge?.isActive || isChallengeUnavailable}
            />
            <LeaderBoard
              isActive={challenge?.isActive}
              isChallengeUnavailable={isChallengeUnavailable}
              handleToggle={handleToggleConsentSwitch}
              isOn={challenge?.userGaveConsent}
              leaders={challenge?.leaders || []}
              users={challenge?.users || []}
              challengeType={challenge?.type || ""}
              userPlace={challenge?.userPlace}
              buttonText={t("CH045")}
              handleButtonClick={() => toggleActivePage(2)}
            />
            {(challenge?.isActive || isChallengeUnavailable) && (
              <CircleChallengeGraph
                title={t("CH015")}
                subtitle={t("CH016")}
                value={getFormattedValue(
                  challenge?.userProgressToday ?? 0,
                  challenge?.type
                )}
                colour="#41C0D3"
                width="quarter"
                icon={getChallengeIcon(getChallengeType(sectionid))}
                unit={
                  !isChallengeUnavailable
                    ? getChallengeTypeUnit(
                        challenge?.type,
                        challenge?.userProgressToday
                      )
                    : getChallengeTypeUnit(
                      getChallengeType(sectionid),
                      0
                    )
                }
              />
            )}

            <CircleChallengeGraph
              title={
                challenge?.isActive || isChallengeUnavailable
                  ? t("CH015")
                  : t("CH017")
              }
              subtitle={
                challenge?.isActive || isChallengeUnavailable
                  ? t("CH018")
                  : t("CH019")
              }
              value={getFormattedValue(
                challenge?.userProgressOverall ?? 0,
                challenge?.type
              )}
              colour="#62BF92"
              width="quarter"
              icon={getChallengeIcon(getChallengeType(sectionid))}
              unit={
                !isChallengeUnavailable
                  ? getChallengeTypeUnit(
                      challenge?.type,
                      challenge?.userProgressOverall
                    )
                  : getChallengeTypeUnit(
                    getChallengeType(sectionid),
                    0
                  )
              }
            />

            <CircleChallengeGraph
              title={
                challenge?.isActive || isChallengeUnavailable
                  ? t("CH020")
                  : t("CH021")
              }
              subtitle={
                challenge?.isActive || isChallengeUnavailable
                  ? t("CH022")
                  : t("CH019")
              }
              value={getFormattedValue(
                challenge?.teamProgressCompleted ?? 0,
                challenge?.type
              )}
              colour="#41C0D3"
              width="quarter"
              icon={getChallengeIcon(getChallengeType(sectionid))}
              unit={
                !isChallengeUnavailable
                  ? getChallengeTypeUnit(
                      challenge?.type,
                      challenge?.teamProgressCompleted
                    )
                  : getChallengeTypeUnit(
                    getChallengeType(sectionid),
                    0
                  )
              }
            />
            {(challenge?.isActive || isChallengeUnavailable) && (
              <CircleChallengeGraph
                title={t("CH020")}
                subtitle={t("CH023")}
                value={getFormattedValue(
                  challenge?.teamProgressRemaining ?? 0,
                  challenge?.type
                )}
                colour="#62BF92"
                width="quarter"
                icon={getChallengeIcon(getChallengeType(sectionid))}
                unit={
                  !isChallengeUnavailable
                    ? getChallengeTypeUnit(
                        challenge?.type,
                        challenge?.teamProgressRemaining
                      )
                    : getChallengeTypeUnit(
                      getChallengeType(sectionid),
                      0
                    )
                }
              />
            )}
            {(showConsentModal || showSubmitTrackerModal) && (
              <WhitePopUpCTA
                popupContents={
                  showConsentModal
                    ? consentModalContents
                    : submitTrackerModalContents
                }
              />
            )}
            {showCompletedModal && (
              <FinishedChallenge
                title={
                  challenge?.teamProgressRemaining === 0
                    ? t("CH031")
                    : t("CH033")
                }
                subtitle={
                  challenge?.teamProgressRemaining === 0
                    ? t("CH032")
                    : t("CH034")
                }
                onClose={handleClosePopup}
                isSuccess={challenge?.teamProgressRemaining === 0}
              />
            )}
          </div>
        </div>
      )}
      {errorText && (
        <ChallengeErrorModal
          subtitle={errorText}
          onClose={() => {
            setErrorText(null);
          }}
        />
      )}
      <Footer />
    </div>
  );
};

export default Challenge;
