import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Nouislider from "@x1mrdonut1x/nouislider-react";
import "nouislider/distribute/nouislider.css";

import "./Style.css";

import ModalTrigger from "../../../shared/modal/ModalTrigger";

const ActivitySlider = ({
  assessmentValues,
  questionid,
  onSetValue,
  width,
  questionTitle,
  modalText,
  onOpenModal,
  leftlabel,
  rightlabel,
}) => {
  const { t } = useTranslation();

  const [textValue, setTextValue] = useState(t("A118"));
  const [styles, setStyles] = useState({ left: 0 });
  const [startValue, setStartValue] = useState(0);

  useEffect(() => {
    if (assessmentValues[questionid]) {
      setTextValue(assessmentValues[questionid].textValue);
      setStartValue(assessmentValues[questionid].value);
      setStyles({
        left: assessmentValues[questionid].percent + "%",
      });
    }
  }, [assessmentValues, questionid]);

  const onSlide = (render, handle, value, un, percent) => {
    let mins = value[0].toFixed(0);
    let hours = Math.floor(mins / 60);
    let textMessage;

    if (hours > 0) {
      let minutes = mins - hours * 60;
      textMessage = hours + t("A119") + minutes + t("A120");
    } else {
      let minutes = mins;
      textMessage = minutes + t("A120");
    }

    setTextValue(textMessage);
    setStyles({ left: percent[0].toFixed(2) + "%" });

    let item = {
      textValue: textMessage,
      value: value[0].toFixed(0),
      percent: percent[0].toFixed(2),
    };

    onSetValue({ id: questionid, value: item });
  };

  return (
    <div id={questionid} className={"req question_block" + width}>
      <h3>{questionTitle}</h3>
      {modalText && (
        <ModalTrigger modalText={modalText} onOpenModal={onOpenModal} />
      )}
      <div className="answer_block">
        <div className="activitySlider" id={questionid}>
          <div className="leftLabel">{leftlabel}</div>
          <div className="rightLabel">{rightlabel}</div>
          <Nouislider
            id="activity_slider"
            step={15}
            range={{ min: 0, "50%": [120, 15], max: 600 }}
            start={startValue}
            connect="lower"
            onSlide={onSlide}
          />
          <div
            className="valueHandle"
            id={questionid + "_value_handle"}
            style={styles}
          >
            {textValue}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivitySlider;
