import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons";
import {
  handleEidChange,
  handleNameChange,
} from "../../shared/util/handleValueChange";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import DropDownRegistration from "../components/DropDownRegistration";

function SignupScreen2(props) {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState();
  const [name, setName] = useState();
  const [eid, setEid] = useState("");
  const [region, setRegion] = useState();
  const [regions, setRegions] = useState();
  const [regionGroupArr, setRegionGroupArr] = useState([]);
  const [regionGroup, setRegionGroup] = useState();
  const [regionsOptions, setRegionsOptions] = useState([]);
  const [department, setDepartment] = useState();
  const [subDepartment, setSubDepartment] = useState();
  const [subDepts, setSubDepts] = useState([]);
  const [regionid, setRegionid] = useState();
  const [departmentid, setDepartmentid] = useState();
  const [subDepartmentid, setSubDepartmentid] = useState();

  const { isLoading } = useHttpClient();

  useEffect(() => {
    if (
      props.regions &&
      props.regions.length > 1 &&
      props.regions[0].region_group
    ) {
      let groupArr = [];
      props.regions.forEach((region, index) => {
        if (
          groupArr.filter((el) => el.title === region.region_group).length === 0
        ) {
          groupArr.push({ id: index, title: region.region_group });
        }
        setRegionGroupArr(groupArr);
      });
    }
    if (props.regions && props.regions.length > 1) {
      setRegions(props.regions);
    }
    if (props.name) {
      setName(props.name);
    }
  }, [props]);

  useEffect(() => {
    setErrorMessage(props.error);
  }, [props.error]);

  const onSetRegionGroup = (groupRegion) => {
    if (regionGroup && groupRegion !== regionGroup) {
      setRegion(" ");
    }
    let regionsArr = regions.filter(
      (region) => region.region_group === groupRegion
    );
    setRegionsOptions(regionsArr);
    setRegionGroup(groupRegion);
  };

  const onSetDept = (dept) => {
    setDepartment(dept);
    let obj = props.departments.find((el) => el.title === dept);
    setDepartmentid(obj.id);
    if (props.subDepartments && props.subDepartments.length > 0) {
      if (
        subDepartment &&
        dept !== props.departments[Number(obj.id) - 1].title
      ) {
        setSubDepartment(" ");
      }
      let subDeptsArr = props.subDepartments[Number(obj.id) - 1];
      let subDeptsArrOfObj = [];
      subDeptsArr.forEach((subdept, index) => {
        if (subdept.length > 0) {
          subDeptsArrOfObj.push({
            id: index + 1,
            title: subdept,
          });
        }
      });
      setSubDepts(subDeptsArrOfObj);
    }
  };

  const handleRegion = (region) => {
    setRegion(region);
    let obj = props.regions.find((reg) => reg.title === region);
    setRegionid(obj.id);
  };

  const handleSubDepartment = (subdept) => {
    setSubDepartment(subdept);
    let id = props.subDepartments[departmentid - 1].indexOf(subdept);
    setSubDepartmentid(id + 1);
  };

  const handleContinue = () => {
    setErrorMessage("");
    if (!name) {
      setErrorMessage(t("R055"));
      return;
    }
    if (!eid && props.erv && props.erv !== "no" && !props.fandf) {
      setErrorMessage(t("R056") + props.ervFieldName);
      return;
    }
    if (!regionid && props.regions.length > 1) {
      setErrorMessage(t("R057"));
      return;
    }
    if (!departmentid && props.departments.length > 1 && !props.fandf) {
      setErrorMessage(t("R058"));
      return;
    }
    if (!subDepartmentid && props.subDepartments.length > 1 && !props.fandf) {
      setErrorMessage(t("R059"));
      return;
    }

    props.handleContinue({
      progress: "screen2",
      name,
      regionid,
      departmentid,
      subDepartmentid,
      eid,
    });
  };

  return (
    <React.Fragment>
      <h1>{t("R026")}</h1>
      {!auth.isLoggedIn && (
        <form>
          {!props.name && (
            <input
              type="text"
              className="code_input"
              id="name"
              name="name"
              tabIndex={0}
              placeholder={t("R027")}
              onChange={(e) => handleNameChange(e, setName)}
            />
          )}
          {props.erv === "yes" && props.ervFieldName && !props.fandf && (
            <input
              type="text"
              className="code_input"
              id="erv"
              name="eid"
              placeholder={props.ervFieldName}
              onChange={(e) => handleEidChange(e, setEid)}
            />
          )}
          {regionGroupArr && regionGroupArr.length > 0 && (
            <DropDownRegistration
              activeItem={regionGroup}
              data={regionGroupArr}
              show={"profile"}
              placeholder={t("R028")}
              setActiveItem={onSetRegionGroup}
              wtfText={t("R032")}
              onOpenModal={props.onOpenModal}
            />
          )}

          {regionsOptions && regionsOptions.length > 0 && regionGroup && (
            <DropDownRegistration
              activeItem={region}
              data={regionsOptions}
              show={"profile"}
              placeholder={t("R029")}
              setActiveItem={handleRegion}
              wtfText={t("R032")}
              onOpenModal={props.onOpenModal}
            />
          )}
          {regions && regions.length > 0 && regionGroupArr.length === 0 && (
            <DropDownRegistration
              activeItem={region}
              data={regions}
              placeholder={t("R028")}
              show={"profile"}
              setActiveItem={handleRegion}
              wtfText={t("R032")}
              onOpenModal={props.onOpenModal}
            />
          )}

          {!props.fandf &&
            props.departments &&
            props.departments[0] &&
            props.departments[0].title.length > 0 && (
              <DropDownRegistration
                activeItem={department}
                data={props.departments}
                show={"profile"}
                placeholder={t("R030")}
                setActiveItem={onSetDept}
                wtfText={t("R033")}
                onOpenModal={props.onOpenModal}
              />
            )}
          {!props.fandf && subDepts && subDepts.length > 0 && (
            <DropDownRegistration
              activeItem={subDepartment}
              data={subDepts}
              show={"profile"}
              placeholder={t("R031")}
              setActiveItem={handleSubDepartment}
              wtfText={t("R033")}
              onOpenModal={props.onOpenModal}
            />
          )}
        </form>
      )}
      {isLoading && <LoadingSpinner asOverlay />}
      {errorMessage && (
        <p className="login_error">
          {" "}
          <FontAwesomeIcon icon={faTriangleExclamation} className="icon" />
          <span dangerouslySetInnerHTML={{ __html: errorMessage }} />
        </p>
      )}
      {!isLoading && (
        <button onClick={handleContinue} id="continue">
          {t("R013")}
        </button>
      )}
    </React.Fragment>
  );
}
export default SignupScreen2;
