import React, { useState } from "react";

import "./SearchBar.css";

//import Photo from "./images/photo.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-light-svg-icons";
import { faCircleXmark } from "@fortawesome/pro-solid-svg-icons";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { useTranslation } from "react-i18next";

function SearchBar(props) {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [searchTerm, setSearchTerm] = useState();
  const [times, setTimes] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState();

  const handleQuit = () => {
    setShowSuggestions(false);
    setSearchTerm("");
    setTimes(false);
    props.setMedia([]);
    props.setNoResults(false);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value.length > 0) {
      setTimes(true);
    }
    if (e.target.value.length < 3) {
      setShowSuggestions(false);
    }
    if (e.target.value.length > 2) {
      fetchData(e.target.value);
      setShowSuggestions(true);
    }
  };

  const handleTermSelect = (e) => {
    performSearch(e.target.innerText);
  };

  const performSearch = async (term) => {
    fetchData(term, "full");
    setSearchTerm(term);
  };

  const handleSearchKeyPress = (e) => {
    if (e.key === "Enter" && e.target.value.length > 2) {
      performSearch(e.target.value);
    }
  };

  const fetchData = async (term, searchType) => {
    if (searchType !== "full") {
      term = "pre-" + term;
    }
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/media/search/${term}`
      );
      if (responseData) {
        setSuggestions(responseData.suggestions);
        if (searchType === "full") {
          if (responseData.media.length > 0) {
            props.setMedia(responseData.media);
            props.setNoResults(false);
          } else {
            props.setNoResults(true);
            props.setMedia([]);
          }
        }
      }
    } catch (err) {}
  };

  return (
    <div className="search">
      <div className="search-bar-container">
        <div className="search_field">
          <FontAwesomeIcon icon={faSearch} className="icon search" />
          <input
            type="text"
            className="search_input"
            placeholder="Search for anything"
            onChange={handleSearchChange}
            onKeyDown={handleSearchKeyPress}
            value={searchTerm}
          ></input>
          {times && (
            <FontAwesomeIcon
              icon={faCircleXmark}
              className="icon times"
              onClick={handleQuit}
            />
          )}
        </div>
      </div>

      <div
        className={`search_suggestions ${
          showSuggestions && suggestions?.length > 0 ? "show" : ""
        }`}
      >
        {showSuggestions &&
          suggestions?.length > 0 &&
          suggestions.map((suggestion, index) => (
            <div
              key={index}
              onClick={handleTermSelect}
              className={
                showSuggestions && suggestions?.length > 0 ? "show" : ""
              }
            >
              {suggestion}
            </div>
          ))}
      </div>
    </div>
  );
}

export default SearchBar;
