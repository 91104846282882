import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown } from "@fortawesome/free-solid-svg-icons";
import "../styles/componentsStyles/TopLeaders.css";
import {
  getChallengeTypeUnit,
  getFormattedValue,
  getInitial,
} from "../../shared/util/challenges";
import { checkImage } from "../../shared/util/helperfunctions";

const SingleLeader = ({ leader, index, leadersNumber, challengeType }) => {
  const [imageExists, setImageExists] = useState(false);

  useEffect(() => {
    if (leader?.image) {
      checkImage(leader?.image).then(setImageExists);
    }
  }, [leader?.image]);

  return (
    <div key={index} className={`leader leader-${index}`}>
      {index !== 0 && <div className="leader-number">{index + 1}</div>}
      {index === 0 && (
        <FontAwesomeIcon style={{ color: "#62BF92" }} icon={faCrown} />
      )}
      <div className={`leader-avatar-outer leader-avatar-outer-${index}`}>
        <div
          className={`leader-avatar leader-avatar-${index}`}
          style={
            leader?.image && imageExists
              ? { backgroundImage: `url(${leader?.image})` }
              : {}
          }
        >
          <div
            className="leader-initial"
            style={
              leader?.image && imageExists
                ? {
                    display: "none",
                  }
                : {}
            }
          >
            {getInitial(leader?.name || "")}
          </div>
        </div>
      </div>
      <div
        className={`leader-progress leader-progress-${index} ${
          leadersNumber === 0 ? "inactive" : "active"
        }`}
      >
        {leader?.score ? getFormattedValue(leader?.score, challengeType) : 0}
      </div>
      <div
        className={`leader-progress-unit ${
          leadersNumber === 0 ? "inactive" : "active"
        } ${index === 0 ? "first" : "other"}`}
      >
        {getChallengeTypeUnit(challengeType, leader?.score)}
      </div>
    </div>
  );
};

export default SingleLeader;
