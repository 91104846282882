import React, { useEffect, useState } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useParams,
  useNavigate,
} from "react-router-dom";

import "./App.css";

import { isMobile, isTablet, isAndroid, isIOS } from "react-device-detect";

import { AuthContext } from "./shared/context/auth-context";
import { useAuth } from "./shared/hooks/auth-hook";

import AccessCode from "./pages/onboarding/AccessCode";
import RequestAccessCode from "./pages/onboarding/RequestAccessCode";
import Signup from "./pages/onboarding/Signup";
import Login from "./pages/onboarding/Login";
import Profile from "./profile/Profile";
import Settings from "./pages/onboarding/Settings";
import RequestPasswordReset from "./pages/onboarding/RequestPasswordReset";
import ResetPassword from "./pages/onboarding/ResetPassword";

import Assessment from "./assessment/pages/Assessment";
import ForYou from "./forYou/ForYou";
import Explore from "./explore/Explore";
import Goals from "./goals/Goals";
import Authors from "./platform/pages/Authors";

import SingleMediaCoverWrap from "./contentTypes/singleMedia/SingleMediaCoverWrap";
import SingleMediaPlayerWrap from "./contentTypes/singleMedia/SingleMediaPlayerWrap";
import MasterclassCoverWrap from "./contentTypes/masterclass/MasterclassCoverWrap";

import ProgrammeCoverWrap from "./contentTypes/programme/ProgrammeCoverWrap";
import ProgrammePlayerWrap from "./contentTypes/programme/ProgrammePlayerWrap";

import CompanyReport from "./companyReport/pages/CompanyReport";

import Results from "./results/Results";
import Support from "./support/Support";
import MasterclassPlayerWrap from "./contentTypes/masterclass/MasterclassPlayerWrap";
import WhitePopUpCTA from "./shared/modal/whitePopUp/WhitePopUpCTA";

import iosImg from "./assets/images/App_Store_Badge.png";
import androidImg from "./assets/images/GoogleStore.png";
import Challenges from "./challenges/Challenges";
import i18n from "./i18n";

function App(props) {
  const {
    token,
    login,
    logout,
    userId,
    fname,
    access,
    email,
    image,
    tags,
    clientName,
    npsStatus,
    authType,
    language,
    profileHandler,
    npsStatusHandler,
    tagHandler,
    languageHandler,
  } = useAuth();

  // google analytics collecting client name
  window.gtag("set", "user_properties", {
    Client_Name: clientName,
  });

  const [openOverlay, setOpenOverlay] = useState(false);
  const [isAndroidState, setIsAndroidState] = useState(false);
  const [isIOSState, setIsIOSState] = useState(false);

  useEffect(() => {
    if (isMobile) {
      if (isTablet) {
        //this is a tablet
      } else {
        setOpenOverlay(true);
        if (isAndroid) {
          setIsAndroidState(true);
        } else if (isIOS) {
          setIsIOSState(true);
        }
      }
    } else {
      //this is not a mobile device
    }
  }, []);

  const [lostNetwork, setLostNetwork] = useState(false);
  const whitePopUpCTAContents = {
    title: "You're offline",
    textArr: [
      {
        text: "You are currently offline. Please check your internet connection.",
        className: "",
      },
    ],
    buttons: [
      {
        text: false,
        color: false,
        onClick: () => {
          return;
        },
      },
      {
        text: false,
        color: false,
        onClick: () => {
          return;
        },
      },
    ],
  };

  useEffect(() => {
    function updateOnlineStatus() {
      if (navigator.onLine) {
        setLostNetwork(false);
      } else {
        setLostNetwork(true);
      }
    }

    // Check online status immediately
    updateOnlineStatus();

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    // Cleanup function to remove the event listeners when the component unmounts
    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []); // Empty dependency array so the effect only runs once on mount

  const validToken = () => {
    let now = new Date().getTime();
    let userData = JSON.parse(localStorage.getItem("userData"));
    let expiry = userData && new Date(userData.expiration).getTime();
    if (!expiry) {
      return false;
    } else if (now > expiry) {
      logout();
      return false;
    } else {
      return true;
    }
  };

  const RedirectToCode = () => {
    const { codeid } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
      navigate(`/code/${codeid}`);
    }, [codeid, navigate]);

    return null;
  };

  //TODO: uncommment when translation goes live
  // useEffect(() => {
  //   if (!token && !language && !localStorage.getItem("i18nextLng")) {
  //     let deviceLanguage = navigator.language.substring(0, 2);
  //     switch (deviceLanguage) {
  //       case "de":
  //         deviceLanguage = "de";
  //         break;
  //       case "sv":
  //         deviceLanguage = "sv";
  //         break;
  //       default:
  //         deviceLanguage = "en";
  //         break;
  //     }
  //       languageHandler(deviceLanguage);
  // localStorage.setItem("deviceLanguage", language);
  //   }
  // });

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        fname: fname,
        access: access,
        email: email,
        login: login,
        logout: logout,
        image: image,
        tags: tags,
        clientName: clientName,
        npsStatus: npsStatus,
        authType: authType,
        language: language,
        profileHandler: profileHandler,
        npsStatusHandler: npsStatusHandler,
        tagHandler: tagHandler,
        languageHandler: languageHandler,
      }}
    >
      <main>
        {openOverlay && (
          <div id="mobile-device-overlay">
            <div className="mobile-device-txt">
              Dive into your world of wellbeing with the Champion Health app!
            </div>
            {isIOSState && (
              <a
                href="https://apps.apple.com/us/app/champion-health/id1580359828"
                target="_blank"
                rel="noopener noreferrer"
                alt="Link to App Store"
              >
                <div
                  className="store-btn"
                  style={{ backgroundImage: `url(${iosImg})`, width: "180px" }}
                ></div>
              </a>
            )}
            {isAndroidState && (
              <a
                href="https://play.google.com/store/apps/details?id=com.championhealth.app"
                target="_blank"
                rel="noopener noreferrer"
                alt="Link to Google Play"
              >
                <div
                  className="store-btn"
                  style={{
                    backgroundImage: `url(${androidImg})`,
                    width: "203px",
                  }}
                ></div>{" "}
              </a>
            )}
          </div>
        )}
        {lostNetwork && (
          <WhitePopUpCTA popupContents={whitePopUpCTAContents} offline />
        )}
        <Router>
          <Routes>
            {/* Access Code Pages/redirect */}
            <Route path="*" element={<Navigate replace to="/code" />} />
            <Route path="/" element={<Navigate replace to="/code" />} />
            <Route path="/" element={<AccessCode />} />
            <Route path="/register" element={<Navigate replace to="/code" />} />
            <Route path="/register/:codeid" element={<RedirectToCode />} />
            <Route path="/code" element={<AccessCode />} />
            <Route path="/code/:codeid" element={<AccessCode />} />
            <Route path="/find-code" element={<RequestAccessCode />} />
            {/* Onboarding Pages */}
            <Route
              path="/signup"
              element={validToken() ? <Navigate to="/for-you" /> : <Signup />}
            />
            <Route
              path="/sign-in"
              element={validToken() ? <Navigate to="/for-you" /> : <Login />}
            />
            <Route
              path="/sign-in/*"
              element={validToken() ? <Navigate to="/for-you" /> : <Login />}
            />
            <Route
              path="/signin"
              element={<Navigate replace to="/sign-in" />}
            />
            {/* Password Reset Pages */}
            <Route
              path="/reset-sign-in"
              element={validToken() ? <Navigate to="/for-you" /> : <Login />}
            />
            <Route path="/reset/:token" element={<ResetPassword />} />
            <Route path="/reset" element={<RequestPasswordReset />} />
            {/* In App Pages */}

            {/* routes only available once the user signed in */}
            <Route
              path="/assessment"
              element={
                validToken() ? (
                  <Assessment />
                ) : (
                  <Navigate
                    to="/code"
                    state={{ from: window.location.pathname }}
                  />
                )
              }
            />
            <Route
              path="/for-you"
              element={
                validToken() && tags.length > 0 ? (
                  <ForYou />
                ) : validToken() && tags.length < 1 ? (
                  <Navigate to="/assessment" />
                ) : (
                  <Navigate
                    to="/code"
                    state={{ from: window.location.pathname }}
                  />
                )
              }
            />
            <Route
              path="/:healthareaid/recommended"
              element={
                validToken() && tags.length > 0 ? (
                  <ForYou />
                ) : validToken() && tags.length < 1 ? (
                  <Navigate to="/assessment" />
                ) : (
                  <Navigate
                    to="/code"
                    state={{ from: window.location.pathname }}
                  />
                )
              }
            />
            <Route
              path="/:healthareaid/challenge"
              element={
                validToken() && tags.length > 0 ? (
                  <Challenges />
                ) : validToken() && tags.length < 1 ? (
                  <Navigate to="/assessment" />
                ) : (
                  <Navigate
                    to="/code"
                    state={{ from: window.location.pathname }}
                  />
                )
              }
            />
            <Route
              path="/:healthareaid/results"
              element={
                validToken() && tags.length > 0 ? (
                  <Results />
                ) : validToken() && tags.length < 1 ? (
                  <Navigate to="/assessment" />
                ) : (
                  <Navigate
                    to="/code"
                    state={{ from: window.location.pathname }}
                  />
                )
              }
            />
            <Route
              path="/:healthareaid/track"
              element={
                validToken() && tags.length > 0 ? (
                  <Results />
                ) : validToken() && tags.length < 1 ? (
                  <Navigate to="/assessment" />
                ) : (
                  <Navigate
                    to="/code"
                    state={{ from: window.location.pathname }}
                  />
                )
              }
            />
            <Route
              path="/goals"
              element={
                validToken() && tags.length > 0 ? (
                  <Goals />
                ) : validToken() && tags.length < 1 ? (
                  <Navigate to="/assessment" />
                ) : (
                  <Navigate
                    to="/code"
                    state={{ from: window.location.pathname }}
                  />
                )
              }
            />
            <Route
              path="/explore"
              element={
                validToken() && tags.length > 0 ? (
                  <Explore />
                ) : validToken() && tags.length < 1 ? (
                  <Navigate to="/assessment" />
                ) : (
                  <Navigate
                    to="/code"
                    state={{ from: window.location.pathname }}
                  />
                )
              }
            />

            <Route
              path="/masterclass/:chid/:title"
              element={
                validToken() && tags.length > 0 ? (
                  <MasterclassCoverWrap />
                ) : validToken() && tags.length < 1 ? (
                  <Navigate to="/assessment" />
                ) : (
                  <Navigate
                    to="/code"
                    state={{ from: window.location.pathname }}
                  />
                )
              }
            />
            <Route
              path="/masterclass/:chid/:title/:chapterNo/:chapterTitle"
              element={
                validToken() && tags.length > 0 ? (
                  <MasterclassPlayerWrap />
                ) : validToken() && tags.length < 1 ? (
                  <Navigate to="/assessment" />
                ) : (
                  <Navigate
                    to="/code"
                    state={{ from: window.location.pathname }}
                  />
                )
              }
            />

            <Route
              path="/programme/:chid/:title"
              element={
                validToken() && tags.length > 0 ? (
                  <ProgrammeCoverWrap />
                ) : validToken() && tags.length < 1 ? (
                  <Navigate to="/assessment" />
                ) : (
                  <Navigate
                    to="/code"
                    state={{ from: window.location.pathname }}
                  />
                )
              }
            />
            <Route
              path="/programme/:chid/:title/:day"
              element={
                validToken() && tags.length > 0 ? (
                  <ProgrammePlayerWrap />
                ) : validToken() && tags.length < 1 ? (
                  <Navigate to="/assessment" />
                ) : (
                  <Navigate
                    to="/code"
                    state={{ from: window.location.pathname }}
                  />
                )
              }
            />

            <Route
              path="/media/player/:chid/:title"
              element={
                validToken() && tags.length > 0 ? (
                  <SingleMediaPlayerWrap />
                ) : validToken() && tags.length < 1 ? (
                  <Navigate to="/assessment" />
                ) : (
                  <Navigate
                    to="/code"
                    state={{ from: window.location.pathname }}
                  />
                )
              }
            />
            <Route
              path="/media/:chid/:title"
              element={
                validToken() && tags.length > 0 ? (
                  <SingleMediaCoverWrap />
                ) : validToken() && tags.length < 1 ? (
                  <Navigate to="/assessment" />
                ) : (
                  <Navigate
                    to="/code"
                    state={{ from: window.location.pathname }}
                  />
                )
              }
            />
            <Route
              path="/take-actions/:chid/:title"
              element={
                validToken() && tags.length > 0 ? (
                  <SingleMediaCoverWrap />
                ) : validToken() && tags.length < 1 ? (
                  <Navigate to="/assessment" />
                ) : (
                  <Navigate
                    to="/code"
                    state={{ from: window.location.pathname }}
                  />
                )
              }
            />
            <Route
              path="/company-report/:pageid?/:subpageid?"
              element={
                validToken() ? (
                  <CompanyReport />
                ) : (
                  <Navigate
                    to="/code"
                    state={{ from: window.location.pathname }}
                  />
                )
              }
            />
            <Route
              path="/authors/:authorid"
              element={
                validToken() && tags.length > 0 ? (
                  <Authors />
                ) : validToken() && tags.length < 1 ? (
                  <Navigate to="/assessment" />
                ) : (
                  <Navigate
                    to="/code"
                    state={{ from: window.location.pathname }}
                  />
                )
              }
            />
            <Route
              path="/profile"
              element={
                validToken() ? (
                  <Navigate to="/profile/settings" />
                ) : (
                  <Navigate
                    to="/code"
                    state={{ from: window.location.pathname }}
                  />
                )
              }
            />
            <Route
              path="/profile/:subpage"
              element={
                validToken() ? (
                  <Profile />
                ) : (
                  <Navigate
                    to="/code"
                    state={{ from: window.location.pathname }}
                  />
                )
              }
            />
            <Route
              path="/profile/:subpage/:subsubpage"
              element={
                validToken() ? (
                  <Profile />
                ) : (
                  <Navigate
                    to="/code"
                    state={{ from: window.location.pathname }}
                  />
                )
              }
            />
            <Route
              path="/support"
              element={
                validToken() ? (
                  <Support />
                ) : (
                  <Navigate
                    to="/code"
                    state={{ from: window.location.pathname }}
                  />
                )
              }
            />

            <Route
              path="/settings"
              element={
                validToken() ? (
                  <Settings />
                ) : (
                  <Navigate
                    to="/code"
                    state={{ from: window.location.pathname }}
                  />
                )
              }
            />

            <Route render={() => <Navigate to="/" replace />} />
          </Routes>
        </Router>
      </main>
    </AuthContext.Provider>
  );
}
export default App;
