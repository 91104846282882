import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCalendar } from "@fortawesome/pro-light-svg-icons";
import { faThumbsUp, faTrophy } from "@fortawesome/sharp-regular-svg-icons";
import {
  faCircleChevronLeft,
  faArrowLeft,
} from "@fortawesome/pro-solid-svg-icons";

import DarkButton from "../../shared/components/styledComponents/darkButton/DarkButton";
// import TeamChallenge from "../components/TeamChallenge";
import Journal from "../components/Journal";
import Influencers from "../components/Influencers";
import DrinksList from "../components/DrinksList";
import TrackerHeading from "../components/TrackerHeading";
import Warning from "../components/Warning";
import { useHttpClient } from "../../shared/hooks/http-hook";

const AlcoholTracker = (props) => {
  const { t } = useTranslation();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [drinkFree, setDrinkFree] = useState(false);
  const [hadDrink, setHadDrink] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [date, setDate] = useState(false);
  const [data, setData] = useState(null);
  const [responseRequired, setResponseRequired] = useState([]);
  const [currentTracker, setCurrentTracker] = useState(null);
  const [journal, setJournal] = useState(null);
  const [daysNotConsumedAlcohol, setDaysNotConsumedAlcohol] = useState(false);

  const warningRef = useRef(null);

  useEffect(() => {
    if (warningRef.current && responseRequired) {
      const modalElement = warningRef.current.closest(".trackers");

      if (modalElement) {
        modalElement.scrollTop = warningRef.current.offsetTop;
      } else {
        window.scrollTo({
          top: warningRef.current.offsetTop,
          behavior: "smooth",
        });
      }
    }
  }, [warningRef, responseRequired]);

  const getData = async (date) => {
    if (date.length > 0) {
      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/trackers/alcohol/${date}`
        );
        if (response && response.tracker?._id) {
          let tracker = response.tracker;
          setCurrentTracker({
            id: tracker._id,
            trackerDate: tracker.tracker_date.toString().slice(0, 10),
          });
          let data = JSON.parse(tracker.data);
          setData(data);
          setDrinkFree(data.drink_free);
          setHadDrink(!data.drink_free);
          setDaysNotConsumedAlcohol(response.daysNotConsumedAlcohol);
          setJournal({
            enhancers: tracker.enhancers,
            inhibitors: tracker.inhibitors,
            journal: tracker.journal,
          });
          setIsSaved(true);
        } else {
          setCurrentTracker({
            id: "",
            trackerDate: date,
          });
          setData({
            drink_free: "",
            drink_alcohol: [],
          });
          setDrinkFree(false);
          setHadDrink(false);
          setDaysNotConsumedAlcohol(1);
          setJournal(() => ({
            enhancers: [],
            inhibitors: [],
            journal: "",
          }));
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    getData(date);
  }, [date]);

  const clearTracker = () => {};

  const saveResponse = (location, stat) => {
    if (location === "journal") {
      setJournal((prevState) => {
        return {
          ...prevState,
          [stat.id]: stat.value,
        };
      });
    } else {
      setData((prevState) => {
        return {
          ...prevState,
          [stat.id]: stat.value,
        };
      });
    }
  };

  const sendData = async () => {
    let method = "POST";
    let url = `${process.env.REACT_APP_BACKEND_URL}/trackers/`;
    if (currentTracker.id !== "") {
      method = "PUT";
      url = `${process.env.REACT_APP_BACKEND_URL}/trackers/${currentTracker.id}`;
    }

    try {
      const response = await sendRequest(
        url,
        method,
        JSON.stringify({
          trackerDate: date,
          type: "alcohol",
          data: data,
          hidden: false,
          enhancers: journal.enhancers,
          inhibitors: journal.inhibitors,
          journal: journal.journal,
          platform: "web",
        })
      );
      if (response) {
        setIsSaved(true);

        props.closeTrackers();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = () => {
    const emptyKeys = [];

    for (const [key, value] of Object.entries(data)) {
      if (
        (!data.drink_free &&
          key === "drink_alcohol" &&
          (value === "" || value.length < 1)) ||
        (key === "drink_free" && value === "")
      ) {
        emptyKeys.push(key);
      }
    }

    if (emptyKeys.length > 0) {
      setResponseRequired(emptyKeys);
    } else {
      setResponseRequired([]);
      sendData();
    }
  };

  const handleYesNo = (e) => {
    if (e.currentTarget.id === "alc-yes") {
      setDrinkFree(true);
      setHadDrink(false);
      setData((prevState) => {
        return {
          ...prevState,
          drink_free: true,
          drink_alcohol: [],
        };
      });
    } else if (e.currentTarget.id === "alc-no") {
      setHadDrink(true);
      setDrinkFree(false);
      setData((prevState) => {
        return {
          ...prevState,
          drink_free: false,
        };
      });
    }
  };

  const [narrowButtons, setNarrowButtons] = useState(true);

  useEffect(() => {
    //if .trackers-inner is scrolled to bottom - set narrow buttons to false)
    const trackersInner = document.querySelector(".trackers-inner");
    const handleScroll = () => {
      if (
        trackersInner.scrollTop + trackersInner.clientHeight >=
        trackersInner.scrollHeight
      ) {
        setNarrowButtons(false);
      } else {
        setNarrowButtons(true);
      }
    };
    trackersInner.addEventListener("scroll", handleScroll);
    return () => {
      trackersInner.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div id="snm-alcohol" className="tracker-container">
      <div className="trackers-inner">
        <div className="back-to-main">
          <div className="back-btn" onClick={props.handleBackToMain}>
            <FontAwesomeIcon icon={faCircleChevronLeft} />
          </div>
        </div>
        <TrackerHeading
          title="T137"
          setDate={setDate}
          isSaved={isSaved}
          clearTracker={clearTracker}
        />
        <div className="trackers-body">
          <div className="ind-tracker">
            {responseRequired.includes("drink_free") && (
              <React.Fragment>
                <div className="buffer" ref={warningRef}></div>
                <Warning />
              </React.Fragment>
            )}
            {/* <TeamChallenge /> */}
            <div className="title">
              {t("T139")}
              {/* <div className="tc-badge">
                  <FontAwesomeIcon icon={faTrophy} /> <p>{t("T017")}</p>
                </div> */}{" "}
              {responseRequired.includes("drink_free") && (
                <FontAwesomeIcon className="red-arrow" icon={faArrowLeft} />
              )}
            </div>
            <div className="buffer"></div>
            <div className="yes-no-btn">
              <p
                id="alc-yes"
                className={drinkFree ? "alc-btn selected" : "alc-btn"}
                onClick={handleYesNo}
              >
                {t("T066")}
              </p>
              <p
                id="alc-no"
                className={hadDrink ? "alc-btn selected" : "alc-btn"}
                onClick={handleYesNo}
              >
                {t("T067")}
              </p>
            </div>
            <div className="buffer"></div>
          </div>
          {drinkFree && (
            <div className="whc-cont alc">
              <div className="whc-body">
                <div className="whc-heading">
                  <FontAwesomeIcon icon={faThumbsUp} /> {t("T108")}!{" "}
                  {t("T561W")}
                  {daysNotConsumedAlcohol}
                  {t("T562W")}
                </div>
                <div className="whc-text">
                  {t("T563W")} {daysNotConsumedAlcohol} {t("T564W")}
                </div>
              </div>
              <div className="icon-cont">
                <FontAwesomeIcon icon={faCalendar} />
                <div className="days">{daysNotConsumedAlcohol}</div>
              </div>
            </div>
          )}
          {hadDrink && (
            <React.Fragment>
              <div className="ind-tracker">
                {responseRequired.includes("drink_alcohol") && (
                  <React.Fragment>
                    <div className="buffer" ref={warningRef}></div>
                    <Warning />
                  </React.Fragment>
                )}
                <div className="title">
                  {t("T152")}{" "}
                  {responseRequired.includes("drink_alcohol") && (
                    <FontAwesomeIcon className="red-arrow" icon={faArrowLeft} />
                  )}
                </div>
                <DrinksList
                  questionid="drink_alcohol"
                  data={data}
                  saveResponse={saveResponse}
                />
              </div>
              <div className="ind-tracker">
                <div className="title">
                  {t("T140")}
                  <p>{t("T019")}</p>
                </div>
                <Influencers
                  alcohol
                  questionid={["enhancers", "inhibitors"]}
                  data={journal}
                  saveResponse={saveResponse}
                />
                <div className="buffer"></div>
              </div>
            </React.Fragment>
          )}
          <div className="ind-tracker">
            <Journal
              alcohol
              placeholder={hadDrink ? t("T545") : drinkFree ? t("T546") : ""}
              questionid="journal"
              data={journal}
              saveResponse={saveResponse}
            />
          </div>
        </div>
      </div>
      <div className={"buttons " + (narrowButtons ? "narrow" : "")}>
        <div className="close-btn" onClick={props.closeTrackers}>
          {t("T015")}
        </div>
        <DarkButton className="submit" onClick={handleSubmit}>
          {t("T016")}
          <FontAwesomeIcon className="icon" icon={faArrowRight} />
        </DarkButton>
      </div>
    </div>
  );
};
export default AlcoholTracker;
