import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CircleGraph from "../../components/graphs/circleGraph/CircleGraph";
import BarChart from "../../components/graphs/barChart/BarChart";

function Demographics(props) {
  const { t } = useTranslation();
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const sleep_hours = [
    { date: t("SLP001"), value: props.data.sleep_hours_6, height: "0%" },
    { date: t("SLP002"), value: props.data.sleep_hours_6_9, height: "0%" },
    { date: t("SLP003"), value: props.data.sleep_hours_9, height: "0%" },
  ];
  const sleep_quality = [
    { date: t("SLP004"), value: props.data.sleep_quality_poor, height: "0%" },
    {
      date: t("SLP005"),
      value: props.data.sleep_quality_average,
      height: "0%",
    },
    { date: t("SLP006"), value: props.data.sleep_quality_good, height: "0%" },
    {
      date: t("SLP007"),
      value: props.data.sleep_quality_excellent,
      height: "0%",
    },
  ];
  const sleep_pattern = [
    {
      date: t("SLP008"),
      value: props.data.sleep_pattern_irregular,
      height: "0%",
    },
    {
      date: t("SLP009"),
      value: props.data.sleep_pattern_somewhat,
      height: "0%",
    },
    {
      date: t("SLP010"),
      value: props.data.sleep_pattern_regular,
      height: "0%",
    },
  ];
  // const sleep_quality_mental = [
  //   {
  //     date: "Low wellbeing",
  //     value: props.data.low_wellbeing_sleep_score,
  //     height: "0%",
  //   },
  //   {
  //     date: "Moderate wellbeing",
  //     value: props.data.moderate_wellbeing_sleep_score,
  //     height: "0%",
  //   },
  //   {
  //     date: "High wellbeing",
  //     value: props.data.high_wellbeing_sleep_score,
  //     height: "0%",
  //   },
  // ];
  const sleep_productivity = [
    {
      date: t("SLP011"),
      value: props.data.poor_sleep_productivity,
      height: "0%",
    },
    {
      date: t("SLP012"),
      value: props.data.average_sleep_productivity,
      height: "0%",
    },
    {
      date: t("SLP013"),
      value: props.data.good_sleep_productivity,
      height: "0%",
    },
    {
      date: t("SLP014"),
      value: props.data.excellent_sleep_productivity,
      height: "0%",
    },
  ];
  return (
    <React.Fragment>
      <BarChart
        title={t("SLP015")}
        data={sleep_hours}
        unit="%"
        modalText={t("SLP016")}
        onOpenModal={props.onOpenModal}
      />
      <BarChart
        title={t("SLP017")}
        subTitle={t("SLP018")}
        data={sleep_quality}
        unit="%"
        modalText={t("SLP019")}
        onOpenModal={props.onOpenModal}
      />
      {props.activeFilter !== "day-shift" &&
        props.activeFilter !== "night-shift" &&
        !props.isLoading && (
          <BarChart
            title={t("SLP020")}
            data={sleep_pattern}
            unit="%"
            modalText={t("SLP021")}
            onOpenModal={props.onOpenModal}
          />
        )}
      {/* <BarChart
        title="Sleep Quality &amp; Wellbeing"
        data={sleep_quality_mental}
        unit=""
        max={10}
        yaxis="Average sleep quality"
        width="wide"
        modalText="This result looks at the relationship between sleep quality and mental health.<br/><br/>Sleep and mental wellbeing are closely connected. We tend to see that those who sleep poorly have poorer mental health, and those who sleep well have better mental health. This provides you with an additional avenue to improve your employees' sleep, by also addressing their mental wellbeing and stress levels. "
        onOpenModal={props.onOpenModal}
      /> */}
      <BarChart
        title={t("SLP022")}
        data={sleep_productivity}
        unit=""
        yaxis={t("SLP023")}
        max={10}
        width="wide"
        modalText={t("SLP024")}
        onOpenModal={props.onOpenModal}
      />
      <CircleGraph
        title={t("SLP025")}
        square
        colour="colour1"
        value={props.data.productivity_tiredness_no}
        large
        percent={props.data.productivity_tiredness}
        modalText={t("SLP026")}
        onOpenModal={props.onOpenModal}
      />
    </React.Fragment>
  );
}
export default Demographics;
