import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { handleEmailChange } from "../../shared/util/handleValueChange";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons";

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

import GoogleAuthButton from "../../shared/components/logIn/singleSignOn/GoogleAuthButton";
import MicrosoftAuthButton from "../../shared/components/logIn/singleSignOn/MicrosoftAuthButton";
import OpenIDAuthButton from "../../shared/components/logIn/singleSignOn/OpenIDAuthButton";

import PasswordInput from "../components/PasswordInput";

function SignupScreen1(props) {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();
  const { isLoading } = useHttpClient();

  const [isCompLoading, setCompLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleContinue = () => {
    if (email && password && confirmPassword) {
      //setting field input type to "password" before submission
      setSubmitting(true);
      let dataObj = {
        progress: "screen1",
        email,
        password,
      };
      setTimeout(() => {
        props.handleContinue(dataObj);
      }, 100);
    }
  };

  useEffect(() => {
    setErrorMessage(props.error);
  }, [props.error]);

  return (
    <React.Fragment>
      <h1>{t("R018")}</h1>
      {!auth.isLoggedIn && props.isStandardAuthActive && (
        <>
          <form>
            <input
              type="email"
              className={"code_input "}
              id="email"
              name="email"
              placeholder={props.domain_validation ? t("R045") : t("R023")}
              onChange={(e) => handleEmailChange(e, setEmail)}
            />
            <PasswordInput
              visible={true}
              handlePassword={(value) => setPassword(value)}
              confirmPassword={confirmPassword}
              submitting={submitting}
            />
            <PasswordInput
              visible={false}
              handleConfirmPassword={(value) => setConfirmPassword(value)}
              password={password}
            />
          </form>
          <span className="small_text"> {t("R019")}</span>
        </>
      )}
      {errorMessage && (
        <p className="login_error">
          {" "}
          <FontAwesomeIcon icon={faTriangleExclamation} className="icon" />
          {errorMessage}
        </p>
      )}
      {isLoading && isCompLoading && <LoadingSpinner asOverlay />}
      {(!isLoading || !isCompLoading) && props.isStandardAuthActive && (
        <button onClick={handleContinue} id="continue">
          {t("R013")}
        </button>
      )}
      {(!isLoading || !isCompLoading) &&
        props.isStandardAuthActive &&
        (props.isMicrosoftAuthActive ||
          props.isGoogleAuthActive ||
          props.singleSignOn.length > 0) && <p className="or">{t("R071")}</p>}
      {(!isLoading || !isCompLoading) && props.isMicrosoftAuthActive && (
        <MicrosoftAuthButton
          handleResponse={props.handleResponseSSO}
          fandfUser={props.fandfUser}
          clientid={props.clientid}
          setErrorMessage={setErrorMessage}
          setCompLoading={setCompLoading}
        ></MicrosoftAuthButton>
      )}
      {(!isLoading || !isCompLoading) && props.isGoogleAuthActive && (
        <GoogleAuthButton
          handleResponse={props.handleResponseSSO}
          fandfUser={props.fandfUser}
          clientid={props.clientid}
          setErrorMessage={setErrorMessage}
          setCompLoading={setCompLoading}
        ></GoogleAuthButton>
      )}

      {props.singleSignOn.map((sso) => {
        if (!isLoading || !isCompLoading) {
          return (
            <OpenIDAuthButton
              key={sso._id}
              configurationName={sso.type}
              ssoId={sso.id}
              redirectUri={`${window.location.origin}/sign-in/callback`}
              scope={sso.scope}
              authority={sso.issuer}
              secret={sso.secret}
              clientId={props.clientid}
              setErrorMessage={setCompLoading}
              handleResponse={props.handleResponseSSO}
            />
          );
        }

        return null;
      })}
    </React.Fragment>
  );
}
export default SignupScreen1;
