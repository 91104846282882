import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";

const ACTIVITY_MAX = 30000;
const outerWidth = 300;
const PI = Math.PI;
const TAU = 2 * PI;
const centre = { x: outerWidth / 2, y: outerWidth / 2 };
const radius = outerWidth / 2;

const angleForValue = (value, startValue, endValue, startAngle, endAngle) => {
  const progress = (value - startValue) / (endValue - startValue);
  return (endAngle - startAngle) * progress + startAngle;
};

const valueForAngle = (angle, startValue, endValue, startAngle, endAngle) => {
  const progress = (angle - startAngle) / (endAngle - startAngle);
  return (endValue - startValue) * progress + startValue;
};

/** Converts polar coordinates to cartesian, given the parameters of the circle */
const polarToCartesian = (centerX, centerY, radius, angleInRadians) => {
  return {
    x: centerX + radius * Math.cos(angleInRadians + Math.PI / 2),
    y: centerY + radius * Math.sin(angleInRadians + Math.PI / 2),
  };
};

/** Given the parameters of the arc, returns an SVG path description matching the parameters */
const describeArc = (x, y, radius, startAngle, endAngle) => {
  let start = polarToCartesian(x, y, radius, endAngle);
  let end = polarToCartesian(x, y, radius, startAngle);
  let largeArcFlag = endAngle - startAngle <= Math.PI ? "0" : "1";

  return [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(" ");
};

const normalize = (angle) => {
  const rest = angle % TAU;
  return rest > 0 ? rest : TAU + rest;
};

const pointOnCircle = (centerX, centerY, radius, angle) => {
  return {
    x: centerX + radius * Math.cos(angle + Math.PI / 2),
    y: centerY + radius * Math.sin(angle + Math.PI / 2),
  };
};

const calculateRotationAngle = (value, endValue) => {
  const minAngle = -130;
  const maxAngle = 130;
  const valueInPercent = (value * 100) / endValue;
  let angle = 2.6 * valueInPercent;

  if (valueInPercent < 50) {
    return minAngle + angle;
  } else {
    return angle - maxAngle;
  }
};

/* Covert steps into miles */
const coventValueToAltValue = (value) => {
  return Math.round((value / 1250) * 0.621371 * 10) / 10;
};

const HorseshoeSlider = ({
  sliderSteps,
  handleSliderSteps,
  startValue = 0,
  endValue = ACTIVITY_MAX,
  startAngle = 0.25 * PI,
  endAngle = 1.75 * PI,
  thumbSize = 40,
  stroke = 10,
  fgColor = "#41c0d3",
  bgColor = "#d4ccd9",
  margin = 20,
  wheelchair,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(sliderSteps);
  const [isThumbPressed, setIsThumbPressed] = useState(false);
  const [cursor, setCursor] = useState("grab");
  const [rotationAngle, setRotationAngle] = useState(() =>
    calculateRotationAngle(sliderSteps, endValue)
  );
  const [pos, setPos] = useState({
    x: pointOnCircle(
      centre.x,
      centre.y,
      radius - stroke / 2 - margin,
      angleForValue(value, startValue, endValue, startAngle, endAngle)
    ).x,
    y: pointOnCircle(
      centre.x,
      centre.y,
      radius - stroke / 2 - margin,
      angleForValue(value, startValue, endValue, startAngle, endAngle)
    ).y,
  });

  const [text, setText] = useState({
    description: `${wheelchair ? t("T551") : t("T092")}`,
    value: wheelchair
      ? coventValueToAltValue(Number(sliderSteps))
      : Math.round(sliderSteps).toLocaleString(),
    altValue: wheelchair
      ? `${coventValueToAltValue(Number(sliderSteps) * 1.60934).toFixed()} ${t(
          "T552"
        )}`
      : `${coventValueToAltValue(Number(sliderSteps))} ${t("T094")}`,
  });

  useEffect(() => {
    let totalSteps = Number(sliderSteps);

    if (totalSteps < 0) {
      totalSteps = 0;
    }

    setText({
      description: `${wheelchair ? t("T551") : t("T092")}`,
      value: wheelchair
        ? coventValueToAltValue(totalSteps)
        : Math.round(totalSteps).toLocaleString(),
      altValue: wheelchair
        ? `${coventValueToAltValue(totalSteps * 1.60934).toFixed(2)} ${t(
            "T552"
          )}`
        : `${coventValueToAltValue(totalSteps)} ${t("T094")}`,
    });

    handleSliderSteps(totalSteps);

    let steps = totalSteps >= ACTIVITY_MAX ? ACTIVITY_MAX : totalSteps;

    setValue(steps);
    setRotationAngle(() => calculateRotationAngle(steps, endValue));

    const newPos = pointOnCircle(
      centre.x,
      centre.y,
      radius - stroke / 2 - margin,
      angleForValue(steps, startValue, endValue, startAngle, endAngle)
    );

    setPos({
      x: newPos.x,
      y: newPos.y,
    });
  }, [sliderSteps, wheelchair]);

  const handleMouseMove = (event) => {
    if (isThumbPressed) {
      //check if the event is a touch event
      if (event.type === "touchmove") {
        event.preventDefault(); // Prevent the default behavior
        event = event.touches[0];
      }
      const { clientX, clientY } = event;
      const slider = document.getElementById("hs-slider");
      const rect = slider.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;

      const angleInRadians =
        Math.atan2(clientY - centerY, clientX - centerX) - 0.5 * PI;
      const normalizedAngle = normalize(angleInRadians);
      const newValue = valueForAngle(
        normalizedAngle,
        startValue,
        endValue,
        startAngle,
        endAngle
      );

      if (newValue >= startValue && newValue <= endValue) {
        setValue(newValue);
        handleSliderSteps(newValue);
        setRotationAngle(() => calculateRotationAngle(newValue, endValue));
        setText({
          description: `${wheelchair ? t("T551") : t("T092")}`,
          value: wheelchair
            ? coventValueToAltValue(Number(newValue))
            : Math.round(newValue).toLocaleString(),
          altValue: wheelchair
            ? `${coventValueToAltValue(Number(newValue) * 1.60934).toFixed(
                2
              )} ${t("T552")}`
            : `${coventValueToAltValue(Number(newValue))} ${t("T094")}`,
        });

        const newPos = pointOnCircle(
          centre.x,
          centre.y,
          radius - stroke / 2 - margin,
          angleForValue(newValue, startValue, endValue, startAngle, endAngle)
        );

        setPos({
          x: newPos.x,
          y: newPos.y,
        });
      }
    }
  };

  const handleMouseUp = () => {
    setIsThumbPressed(false);
    setCursor("grab");
    let thumb = document.getElementById("thumb");
    thumb.removeEventListener("mousemove", handleMouseMove);
  };

  const handleMouseDown = () => {
    setIsThumbPressed(true);
    setCursor("grabbing");
  };

  useEffect(() => {
    const thumb = document.getElementById("thumb");
    // const handleMouseUp = () => setIsThumbPressed(false);

    if (isThumbPressed && thumb) {
      thumb.addEventListener("mousemove", handleMouseMove);
      thumb.addEventListener("touchmove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
      document.addEventListener("touchend", handleMouseUp);
    } else if (thumb) {
      thumb.removeEventListener("mousemove", handleMouseMove);
      thumb.removeEventListener("touchmove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchend", handleMouseUp);
    }

    // Clean up function
    return () => {
      if (thumb) {
        thumb.removeEventListener("mousemove", handleMouseMove);
        thumb.removeEventListener("touchmove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
        document.removeEventListener("touchend", handleMouseUp);
      }
    };
  }, [isThumbPressed]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ width: "450px", height: "450px", alignSelf: "centre" }}>
        <svg
          id="hs-slider"
          height="450px"
          width="450px"
          viewBox={`0 0 ${outerWidth} ${outerWidth}`}
        >
          {new Array(19).fill(0).map((_, i) => {
            const delta = (TAU * 0.75) / 19;
            const theta = delta * i - PI / 2 - 0.71 * PI;
            const p1 = {
              x: centre.x + (radius - 15) * Math.cos(theta),
              y: centre.y + (radius - 15) * Math.sin(theta),
            };
            const p2 = {
              x: centre.x + (radius - 5) * Math.cos(theta),
              y: centre.y + (radius - 5) * Math.sin(theta),
            };
            return (
              <React.Fragment key={i}>
                <line
                  stroke="#281d2d"
                  strokeWidth={1}
                  strokeLinecap="round"
                  x1={p1.x}
                  y1={p1.y}
                  x2={p2.x}
                  y2={p2.y}
                />
              </React.Fragment>
            );
          })}
          <path
            d={describeArc(
              centre.x,
              centre.y,
              radius - stroke / 2 - margin,
              startAngle,
              endAngle
            )}
            stroke={bgColor}
            strokeWidth={stroke}
            fill="transparent"
            strokeLinecap="round"
          />
          <path
            d={describeArc(
              centre.x,
              centre.y,
              radius - stroke / 2 - margin,
              startAngle,
              angleForValue(value, startValue, endValue, startAngle, endAngle)
            )}
            stroke={fgColor}
            strokeWidth={stroke}
            fill="transparent"
            strokeLinecap="round"
          />
          <g style={{ position: "relative" }}>
            <circle
              id="thumb"
              cx={pos.x}
              cy={pos.y}
              r={thumbSize / 2}
              fill="#fff"
              style={{ filter: "url(#shadow)" }}
              cursor={cursor}
              onMouseDown={handleMouseDown}
              onTouchStart={handleMouseDown}
            />
            <g transform={`translate(${pos.x - 20}, ${pos.y - 20})`}>
              <foreignObject
                width={40}
                height={40}
                style={{
                  textAlign: "center",
                  color: "#ddcedb",
                  pointerEvents: "none",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    transform: `rotate(${rotationAngle}deg)`,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    style={{
                      height: "15px",
                      width: "15px",
                      color: "#281d2d",
                    }}
                  />
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{
                      height: "15px",
                      width: "15px",
                      color: "#281d2d",
                    }}
                  />
                </div>
              </foreignObject>
            </g>
          </g>

          <filter
            id="shadow"
            x="-10"
            y="-10"
            width={thumbSize * 2 + 20}
            height={thumbSize * 2 + 20}
          >
            <feDropShadow
              dx="-2"
              dy="3"
              stdDeviation="0"
              floodColor="#d4ccd9"
              floodOpacity="1"
            />
          </filter>

          {text && (
            <g>
              <text
                x={centre.x}
                y={centre.y - 35}
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                  fontFamily: "futura-pt-medium",
                  fontSize: "12px",
                  textTransform: "uppercase",
                  fill: "#64596b",
                }}
              >
                {text.description}
              </text>
              <text
                x={centre.x}
                y={centre.y - 10}
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                  fontFamily: "futura-pt-demi",
                  fontSize: "30px",
                  fill: "#281d2d",
                }}
              >
                {text.value}
              </text>{" "}
              <text
                x={centre.x}
                y={centre.y + 20}
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                  fontFamily: "futura-pt-demi",
                  fontSize: "15px",
                  fill: "#281d2d",
                }}
              >
                {text.altValue}
              </text>
            </g>
          )}
        </svg>
      </div>
    </div>
  );
};

export default HorseshoeSlider;
