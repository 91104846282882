import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CircleGraph from "../../components/graphs/circleGraph/CircleGraph";
import BarChart from "../../components/graphs/barChart/BarChart";

function Demographics(props) {
  const { t } = useTranslation();
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const caffeine_daily = [
    { date: "0", value: props.data.caffeine_0, height: "0%" },
    { date: "1-3", value: props.data.caffeine_3, height: "0%" },
    { date: "4-5", value: props.data.caffeine_5, height: "0%" },
    { date: "6+", value: props.data.caffeine_6, height: "0%" },
  ];
  const caffeine_hours = [
    {
      date: t("HR001"),
      value: props.data.sleep_quality_caffeine_4,
      height: "0%",
    },
    {
      date: t("HR002"),
      value: props.data.sleep_quality_caffeine_4_8,
      height: "0%",
    },
    {
      date: t("HR003"),
      value: props.data.sleep_quality_caffeine_8,
      height: "0%",
    },
  ];
  return (
    <React.Fragment>
      <CircleGraph
        title={t("HR004")}
        square
        colour="black"
        value={props.data.water_no}
        large
        percent={props.data.water}
        modalText={t("HR005")}
        onOpenModal={props.onOpenModal}
      />
      <BarChart
        title={t("HR006")}
        data={caffeine_daily}
        unit="%"
        width="wide"
        modalText={t("HR007")}
        onOpenModal={props.onOpenModal}
      />
      <CircleGraph
        title={t("HR008")}
        square
        colour="colour1"
        value={props.data.productivity_dehydration_no}
        large
        percent={props.data.productivity_dehydration}
        modalText={t("HR009")}
        onOpenModal={props.onOpenModal}
      />
      <BarChart
        title={t("HR010")}
        data={caffeine_hours}
        unit=""
        yaxis={t("HR011")}
        width="wide"
        modalText={t("HR012")}
        onOpenModal={props.onOpenModal}
      />
    </React.Fragment>
  );
}
export default Demographics;
