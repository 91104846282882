import { useTranslation } from "react-i18next";

import {
  faClock,
  faCouch,
  faShoePrints,
  faWheelchairMove,
} from "@fortawesome/pro-regular-svg-icons";

import BarChart from "../components/BarChart";
import Slider from "../../shared/components/slider/Slider";

import { colours } from "../../assets/colours";
import MotivationTile from "../components/MotivationTile";
import TopThreeChart from "../components/TopThreeChart";
import JournalBlock from "../components/JournalBlock";
import {
  blockers_exercise,
  motivators_exercise,
} from "../../shared/components/data";

const MovementResults = (props) => {
  const { t } = useTranslation();
  const { assessScores, trackerScores, trackerJournals } = props.dataObj;
  const { assessDates, assessmentNumber } = props.pageObjRef?.current;

  return (
    <div className="results-container">
      {props.showAssessment && assessScores?.exercise_data && (
        <div className="results-row ">
          <BarChart
            title={t("D056")}
            average={assessScores.exercise_data_average}
            data={assessScores.exercise_data}
            icon={faClock}
            units={t("D076")}
            average_type={t("D079")}
            width="half bars"
            color={colours.blue}
            modalText={[t("D064"), t("D065"), t("D066")]}
            openModal={props.openModal}
            assessmentNumber={assessmentNumber}
            assessDates={assessDates}
          />
          <BarChart
            title={t("D057")}
            data={assessScores.sitting_data}
            icon={faCouch}
            average={assessScores.sitting_data_average}
            units={t("D077")}
            average_type={t("D079")}
            width="half bars"
            color={colours.yellow}
            modalText={[t("D067")]}
            openModal={props.openModal}
            assessmentNumber={assessmentNumber}
            assessDates={assessDates}
          />
        </div>
      )}
      {!props.showAssessment &&
        trackerScores?.activity?.time_active.length > 0 && (
          <div className="results-row ">
            <BarChart
              title={t("D058")} //time spent active
              color={colours.blue}
              icon={faClock}
              units={t("D076")}
              average_type={t("DW032")}
              width="half bars"
              data={trackerScores.activity.time_active}
              average={trackerScores.activity.time_active_average}
              modalText={[t("D068"), t("D065"), t("D069")]}
              openModal={props.openModal}
            />
            <BarChart
              title={t("D059")} //daily step count
              data={trackerScores.activity.daily_steps_counters}
              average={trackerScores.activity.daily_steps_counters_average}
              icon={faShoePrints}
              units={t("T092")}
              average_type={t("DW032")}
              width="half bars"
              color={colours.green}
              modalText={[t("D070")]}
              openModal={props.openModal}
            />
            {trackerScores.activity?.has_wheelchair && (
              <BarChart
                title={t("D060")} //distance covered
                data={trackerScores.activity.distance_covered}
                average={trackerScores.activity.distance_covered_average}
                icon={faWheelchairMove}
                units={t("T094")}
                average_type={t("DW032")}
                width="half bars"
                color={colours.yellow}
                modalText={[t("D071")]}
                openModal={props.openModal}
              />
            )}
            <TopThreeChart
              title={t("D061")} //top three activities
              data={trackerScores.activity.top_activities}
              units={t("D076")}
              width="half bars"
              colour={colours.blue}
              modalText={[t("D072")]}
              openModal={props.openModal}
            />
            <MotivationTile
              title={t("D062")} //activity motivators
              colour={colours.green}
              data={trackerScores.activity.topEnhancers}
              valueArr={motivators_exercise}
              width="quarter"
              modalText={[t("D073")]}
              openModal={props.openModal}
            />
            <MotivationTile
              title={t("D063")} //activity blockers
              colour={colours.red}
              data={trackerScores.activity.topInhibitors}
              valueArr={blockers_exercise}
              width="quarter"
              modalText={[t("D074")]}
              openModal={props.openModal}
            />
            <JournalBlock
              width="quarter"
              startWith={6}
              journalArr={trackerJournals}
              openModal={props.openModal}
            />
          </div>
        )}

      {/* {props.slidersList?.[1] && (
            <div className="results-row">
              <Slider
                title={props.slidersList[1].title}
                key={props.slidersList[1].id}
                id={props.slidersList[1].id}
                media={props.slidersList[1].media}
                favourites={favourites}
                dislikes={dislikes}
                progressList={progressList}
                onOpenMediaModal={openMediaModal}
                addLikeDislike={addLikeDislike}
                target={props.slidersList[1].target || false}
              />
            </div>
          )} */}
    </div>
  );
};
export default MovementResults;
