import React from "react";
import "../styles/componentStyles/ColourBars.css";

const colors = [
  "#ef575a",
  "#f0605a",
  "#f26e59",
  "#f47f59",
  "#f79858",

  "#fca655",
  "#fcb354",
  "#fcbf54",
  "#fcc854",
  "#eec959",

  "#cfc966",
  "#d0c966",
  "#a7c776",
  "#83c285",
  "#65bf91",
];

function ColourBars(props) {
  return (
    <div className={"chart-container " + props.width}>
      <div className="title-container">
        <div className="title">{props.title}</div>
      </div>
      <div className="rainbow-box">
        <div className="rainbow-text">{props.text}</div>
        <div className="rainbow-bars-cont">
          {colors.map((color, index) => {
            if (index + 1 <= props.value * 3) {
              return (
                <div
                  key={index}
                  className="rainbow-bars"
                  style={{ backgroundColor: `${color}` }}
                ></div>
              );
            } else {
              return (
                <div
                  key={index}
                  className="rainbow-bars"
                  style={{ backgroundColor: "#ddcedb" }}
                ></div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}

export default ColourBars;
