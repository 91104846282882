import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CircleGraph from "../../components/graphs/circleGraph/CircleGraph";
import BarChart from "../../components/graphs/barChart/BarChart";

function Demographics(props) {
  const { t } = useTranslation();
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const activity = [
    { date: t("AC001"), value: props.data.activity_0_75, height: "0%" },
    { date: t("AC002"), value: props.data.activity_75_150, height: "0%" },
    { date: t("AC003"), value: props.data.activity_150, height: "0%" },
  ];

  const sitting = [
    { date: t("AC004"), value: props.data.break_sitting_30, height: "0%" },
    { date: t("AC005"), value: props.data.break_sitting_1, height: "0%" },
    { date: t("AC006"), value: props.data.break_sitting_2, height: "0%" },
    { date: t("AC007"), value: props.data.break_sitting_3, height: "0%" },
  ];

  return (
    <React.Fragment>
      <BarChart
        title={t("AC008")}
        subTitle={t("AC009")}
        data={activity}
        unit="%"
        modalText={t("AC010")}
        onOpenModal={props.onOpenModal}
      />
      <CircleGraph
        title={t("AC011")}
        square
        colour="colour1"
        subText={t("AC012")}
        value={props.data.resistance_no}
        large
        percent={props.data.resistance}
        modalText={t("AC013")}
        onOpenModal={props.onOpenModal}
      />
      <CircleGraph
        title={t("AC014")}
        square
        colour="colour2"
        subText={t("AC015")}
        value={props.data.stretching_no}
        large
        percent={props.data.stretching}
        modalText={t("AC016")}
        onOpenModal={props.onOpenModal}
      />
      <CircleGraph
        title={t("AC017")}
        subTitle={t("AC018")}
        square
        colour="colour3"
        value={props.data.sitting_hours}
        unit=""
        large
        modalText={t("AC019")}
        onOpenModal={props.onOpenModal}
      />
      <CircleGraph
        title={t("AC020")}
        square
        colour="colour1"
        value={props.data.sitting_longer_no}
        large
        percent={props.data.sitting_longer}
        modalText={t("AC021")}
        onOpenModal={props.onOpenModal}
      />
      <BarChart
        title={t("AC022")}
        data={sitting}
        unit="%"
        modalText={t("AC023")}
        onOpenModal={props.onOpenModal}
      />
      <CircleGraph
        title={t("AC024")}
        square
        colour="colour1"
        value={props.data.productivity_inactivity_no}
        large
        percent={props.data.productivity_inactivity}
        modalText={t("AC025")}
        onOpenModal={props.onOpenModal}
      />
    </React.Fragment>
  );
}
export default Demographics;
