import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CircleGraph from "../../components/graphs/circleGraph/CircleGraph";
import BarChart from "../../components/graphs/barChart/BarChart";

function Demographics(props) {
  const { t } = useTranslation();
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const age = [
    {
      date: "16-24",
      value: Math.round((100 / props.data.total) * props.data.ages[0]),
      height: "0%",
    },
    {
      date: "25-34",
      value: Math.round((100 / props.data.total) * props.data.ages[1]),
      height: "0%",
    },
    {
      date: "35-44",
      value: Math.round((100 / props.data.total) * props.data.ages[2]),
      height: "0%",
    },
    {
      date: "45-54",
      value: Math.round((100 / props.data.total) * props.data.ages[3]),
      height: "0%",
    },
    {
      date: "55-64",
      value: Math.round((100 / props.data.total) * props.data.ages[4]),
      height: "0%",
    },
    {
      date: "65+",
      value: Math.round((100 / props.data.total) * props.data.ages[5]),
      height: "0%",
    },
  ];

  return (
    <React.Fragment>
      <BarChart title={t("DM001")} data={age} unit="%" width="wide" />
      <CircleGraph
        title={t("DM002")}
        square
        colour="colour1"
        value={props.data.have_children}
        subText=""
        large
        percent={Math.round(
          (100 / props.data.total) * props.data.have_children
        )}
      />
      <CircleGraph
        title={t("DM003")}
        square
        colour="colour2"
        value={props.data.night_shift}
        large
        percent={Math.round((100 / props.data.total) * props.data.night_shift)}
        modalText={t("DM004")}
        onOpenModal={props.onOpenModal}
      />
    </React.Fragment>
  );
}
export default Demographics;
