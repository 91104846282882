import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import "../styles/componentStyles/DrinksList.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faPlus,
  faMinus,
  faChevronDown as faChevronDownLight,
} from "@fortawesome/pro-light-svg-icons";

import { faChevronDown } from "@fortawesome/sharp-regular-svg-icons";

const data = [
  { label: "T154", value: "beer" },
  { label: "T155", value: "cider" },
  { label: "T156", value: "stout" },
  { label: "T157", value: "red_wine" },
  { label: "T158", value: "white_wine" },
  { label: "T159", value: "rose_wine" },
  { label: "T160", value: "sparkling_wine" },
  { label: "T161", value: "spirit_with_mixer" },
  { label: "T162", value: "spirit" },
];

const measureBeer = [
  { label: "T540W", value: "pints" },
  { label: "T541W", value: "half_pints" },
];

const measureWine = [
  { label: "T542W", value: "125" },
  { label: "T543W", value: "175" },
  { label: "T544W", value: "250" },
];

const measureSpirits = [
  { label: "T545W", value: "single" },
  { label: "T546W", value: "double" },
];

const getDrinkLabel = (drinktype) => {
  let drink = data.find((el) => el.value === drinktype);
  return drink.label;
};

const getDrinkSize = (drinksize) => {
  let drink = measureBeer.find((el) => el.value === drinksize);
  if (!drink) drink = measureWine.find((el) => el.value === drinksize);
  if (!drink) drink = measureSpirits.find((el) => el.value === drinksize);
  return drink.label;
};

const abvBeer = [3, 4, 5, 6, 7];

const abvWine = [11, 12, 13, 14, 15];

const abvSpirits = [20, 37.5, 40];

const DrinksList = (props) => {
  const { t } = useTranslation();
  const [lines, setLines] = useState([
    {
      id: 0,
      group: "",
      type: t("T153"),
      number: 0,
      size: t("T163"),
      strength: "%",
      // value: "",
    },
  ]);

  useEffect(() => {
    if (props.data?.[props.questionid]?.length > 0) {
      let dataIntoLines = [];
      props.data[props.questionid].forEach((bev, index) => {
        let index2 = data.findIndex((el) => el.value === bev.type);
        let group;
        if (index2 < 3) {
          group = "beer";
        } else if (index2 > 6) {
          group = "spirits";
        } else {
          group = "wine";
        }
        dataIntoLines.push({
          id: index,
          group,
          type: bev.type,
          number: bev.number,
          size: bev.size,
          strength: bev.strength,
          // value: "",
        });
      });
      if (dataIntoLines.length > 0) {
        setLines(dataIntoLines);
      }
    }
  }, [props.data?.[props.questionid]]);

  const [sizeArr, setSizeArr] = useState([]);
  const [strengthArr, setStrengthArr] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const [identifier, setIdentifier] = useState("");

  const handleDeleteLine = (id) => {
    setLines(lines.filter((line) => line.id !== id));
  };

  const handleAddLine = () => {
    const newLineId = lines.length > 0 ? lines[lines.length - 1].id + 1 : 0;
    setLines([
      ...lines,
      {
        id: newLineId,
        group: "",
        type: t("T153"),
        number: 0,
        size: t("T163"),
        strength: "%",
        // value: "",
      },
    ]);
  };

  const handleDropDown = (id, identifier, group, line) => {
    setIdentifier(identifier);
    setActiveId((prevActiveId) => (prevActiveId === id ? null : id));

    //TO DO get the correct type array
    if (group && identifier === "size") {
      if (group === "beer") setSizeArr([...measureBeer]);
      if (group === "wine") setSizeArr([...measureWine]);
      if (group === "spirits") setSizeArr([...measureSpirits]);
    } else if (group && identifier === "abv") {
      if (group === "beer") setStrengthArr([...abvBeer]);
      if (group === "wine") setStrengthArr([...abvWine]);
      if (group === "spirits") setStrengthArr([...abvSpirits]);
    }
  };

  const handleItemSelect = (event, lineId, identifier, item) => {
    if (identifier === "main") {
      // const selectedItem = event.target.innerHTML;
      let index = data.findIndex((el) => el.value === item.value);
      let group;
      if (index < 3) {
        setSizeArr([...measureBeer]);
        setStrengthArr([...abvBeer]);
        group = "beer";
      } else if (index > 6) {
        setSizeArr([...measureSpirits]);
        setStrengthArr([...abvSpirits]);
        group = "spirits";
      } else {
        setSizeArr([...measureWine]);
        setStrengthArr([...abvWine]);
        group = "wine";
      }
      setLines((prevLines) =>
        prevLines.map((line) => {
          if (line.id === lineId) {
            return {
              ...line,
              group,
              type: item.value,
              size: t("T163"),
              strength: "%",
            };
          }
          return line;
        })
      );
    } else if (identifier === "size") {
      const size = item.value;
      setLines((prevLines) =>
        prevLines.map((line) => {
          if (line.id === lineId) {
            return {
              ...line,
              size,
            };
          }
          return line;
        })
      );
    } else if (identifier === "abv") {
      const strength = item;
      setLines((prevLines) =>
        prevLines.map((line) => {
          if (line.id === lineId) {
            return {
              ...line,
              strength,
            };
          }
          return line;
        })
      );
    }
  };

  const handleDecreaseDrinks = (lineId, number) => {
    if (number > 0) {
      let newNumber;
      newNumber = number - 1;

      setLines((prevLines) =>
        prevLines.map((line) => {
          if (line.id === lineId) {
            return { ...line, number: newNumber };
          }
          return line;
        })
      );
    }
  };

  const handleIncreaseDrinks = (lineId, number) => {
    let newNumber;
    newNumber = number + 1;

    setLines((prevLines) =>
      prevLines.map((line) => {
        if (line.id === lineId) {
          return { ...line, number: newNumber };
        }
        return line;
      })
    );
  };

  const saveDrinks = () => {
    let drinksData = [];
    lines.forEach((line) => {
      if (line.number > 0 && line.type && line.size && line.strength) {
        // let drink = data.filter((el) => el.label === line.selectedItem);
        drinksData.push({
          type: line.type,
          number: line.number,
          size: line.size,
          strength: line.strength,
        });
      }
    });
    if (drinksData.length > 0) {
      props.saveResponse("data", { id: props.questionid, value: drinksData });
    }
  };

  return (
    <div onMouseLeave={saveDrinks} onTouchEnd={saveDrinks}>
      {lines.map((line) => (
        <div className={line.id} key={line.id}>
          <div className="buffer"></div>
          <div className="alc-line">
            <div className="part-line">
              <div
                className="custom-select"
                style={{
                  zIndex: activeId === line.id && identifier === "main" ? 1 : 0,
                }}
              >
                <div
                  className="selected"
                  onClick={() => handleDropDown(line.id, "main")}
                >
                  {line.type === t("T153")
                    ? t("T153")
                    : t(getDrinkLabel(line.type))}
                  <FontAwesomeIcon id={line.id} icon={faChevronDown} />
                </div>
                {activeId === line.id && identifier === "main" && (
                  <div
                    className="list-cont"
                    onMouseLeave={() => {
                      setActiveId(null);
                      setIdentifier("");
                    }}
                    onTouchEnd={() => {
                      setActiveId(null);
                      setIdentifier("");
                    }}
                  >
                    <ul className="list">
                      {data &&
                        data.map((item, index) => {
                          return (
                            <li
                              key={index + "main"}
                              id={item.id}
                              onClick={(event) =>
                                handleItemSelect(event, line.id, "main", item)
                              }
                            >
                              {t(item.label)}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                )}
              </div>
              <div className="bottom-row">
                <div className="column">
                  <FontAwesomeIcon
                    icon={faMinus}
                    className="icon clock"
                    onClick={(e) => handleDecreaseDrinks(line.id, line.number)}
                  />
                  <div className="number">{line.number}</div>
                  <FontAwesomeIcon
                    icon={faPlus}
                    className="icon clock"
                    onClick={(e) => handleIncreaseDrinks(line.id, line.number)}
                  />
                </div>
                <div className="column">
                  <div
                    className="custom-select bev"
                    style={{
                      zIndex:
                        activeId === line.id && identifier === "size" ? 1 : 0,
                    }}
                  >
                    <div
                      className="selected"
                      onClick={() =>
                        handleDropDown(line.id, "size", line.group, line)
                      }
                    >
                      {line.size === t("T163")
                        ? t("T163")
                        : t(getDrinkSize(line.size))}

                      <FontAwesomeIcon
                        id={line.id}
                        icon={faChevronDownLight}
                        className="column-bev-icon"
                      />
                    </div>
                    {activeId === line.id && identifier === "size" && (
                      <div
                        className="list-cont"
                        onMouseLeave={() => {
                          setActiveId(null);
                          setIdentifier("");
                        }}
                        onTouchLeave={() => {
                          setActiveId(null);
                          setIdentifier("");
                        }}
                      >
                        <ul className="list small">
                          {sizeArr &&
                            sizeArr.map((item, index) => {
                              return (
                                <li
                                  key={index + "size"}
                                  id={item.id}
                                  onClick={(event) =>
                                    handleItemSelect(
                                      event,
                                      line.id,
                                      "size",
                                      item
                                    )
                                  }
                                >
                                  {t(item.label)}
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div className="column">
                  <div
                    className="custom-select bev"
                    style={{
                      zIndex:
                        activeId === line.id && identifier === "abv" ? 1 : 0,
                    }}
                  >
                    <div
                      className="selected"
                      onClick={() =>
                        handleDropDown(line.id, "abv", line.group, line)
                      }
                    >
                      {line.strength === "%"
                        ? "%"
                        : `${t("T547W")} ${line.strength}%`}
                      <FontAwesomeIcon
                        id={line.id}
                        icon={faChevronDownLight}
                        className="column-bev-icon"
                      />
                    </div>
                    {activeId === line.id && identifier === "abv" && (
                      <div
                        className="list-cont"
                        onMouseLeave={() => {
                          setActiveId(null);
                          setIdentifier("");
                        }}
                        onTouchEnd={() => {
                          setActiveId(null);
                          setIdentifier("");
                        }}
                      >
                        <ul className="list small">
                          {strengthArr &&
                            strengthArr.map((item, index) => {
                              return (
                                <li
                                  key={index + "strength"}
                                  id={item}
                                  onClick={(event) =>
                                    handleItemSelect(
                                      event,
                                      line.id,
                                      "abv",
                                      item
                                    )
                                  }
                                >
                                  {t("T547W")} {item}%
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="part-line">
              <FontAwesomeIcon
                icon={faTimes}
                className="icon times"
                onClick={() => handleDeleteLine(line.id)}
              />
            </div>
          </div>
        </div>
      ))}
      <div className="add-line">
        <div className="icon-cont">
          <FontAwesomeIcon
            icon={faPlus}
            className="icon plus"
            onClick={handleAddLine}
          />
        </div>

        <div> Add another </div>
      </div>
    </div>
  );
};

export default DrinksList;
