import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faCalendarAlt,
  faSlidersHSquare,
  faSlidersSimple,
  faSquare,
  faPlusCircle,
  faCircle,
} from "@fortawesome/pro-light-svg-icons";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import Overview from "./statistics/Overview";
import Demographics from "./statistics/Demographics";
import Activity from "./statistics/Activity";
import AlcoholSmoking from "./statistics/AlcoholSmoking";
import BehaviourChange from "./statistics/BehaviourChange";
import Energy from "./statistics/Energy";
import FinancialWellbeing from "./statistics/FinancialWellbeing";
import Hydration from "./statistics/Hydration";
import MSKHealth from "./statistics/MSKHealth";
import Nutrition from "./statistics/Nutrition";
import PhysicalHealth from "./statistics/PhysicalHealth";
import Sleep from "./statistics/Sleep";
import Stress from "./statistics/Stress";
import MentalWellbeing from "./statistics/MentalWellbeing";

function CompanyStatistics(props) {
  const { t } = useTranslation();
  let limit = 15;
  let healthAreas = [
    t("CS001"),
    t("CS002"),
    t("CS003"),
    t("CS004"),
    t("CS005"),
    t("CS006"),
    t("CS007"),
    t("CS008"),
    t("CS009"),
    t("CS010"),
    t("CS011"),
    t("CS012"),
    t("CS013"),
    t("CS014"),
  ];

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);

    if (document.getElementById("nav")) {
      if (position >= 50) {
        document.getElementById("nav").classList.add("scrolled");
      } else if (position < 50) {
        document.getElementById("nav").classList.remove("scrolled");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [activeData, setActiveData] = useState(props.data);

  //apply filter to data
  useEffect(() => {
    let data = props.data;
    setActiveData(data);
  }, [props.data]);

  const [dataFilterOpen, setDataFilterOpen] = useState(false);

  function toggleDataFilterOpen() {
    dataFilterOpen ? setDataFilterOpen(false) : setDataFilterOpen(true);
  }

  const [dataActiveFilter, setDataActiveFilter] = useState("Overview");

  function dataFilterSelectHandler(event) {
    if (
      event.target.tagName === "svg" &&
      event.target.parentElement.title !== dataActiveFilter
    ) {
      setDataActiveFilter(event.target.parentElement.title);
    } else if (
      event.target.tagName === "DIV" &&
      event.target.title !== dataActiveFilter
    ) {
      setDataActiveFilter(event.target.title);
    }
    setDataFilterOpen(false);
  }

  //filter
  // const [filterOpen, setFilterOpen] = useState(false);

  function toggleFilterOpen() {
    props.toggleFilterOpen();
  }

  // const [activeFilter, setActiveFilter] = useState(props.filtersArr);

  const [noOfFilters, setNoOfFilters] = useState();

  useEffect(() => {
    let count = 0;
    if (props.filtersArr) {
      props.filtersArr.map((filter, index) => {
        if (index > 1 && filter) {
          count++;
        }
      });
      setNoOfFilters(count);
    }
  }, [props.filtersArr]);

  return (
    <div id="statistics-container">
      <div id="nav" className="data_filter">
        {activeData && activeData.total >= limit && (
          <div id="corep-type-bar">
            <div
              id="data-filter-container"
              className={dataFilterOpen ? "open" : "closed"}
            >
              <div id="filter-select" onClick={toggleDataFilterOpen}>
                <div className="corep-filter-main">
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    className="icon corep-icon"
                  />
                  {t("CS015")}
                </div>
              </div>
              <div id="data-filter-options">
                {healthAreas.map((area, index) => {
                  let checked;
                  area === dataActiveFilter
                    ? (checked = "checked")
                    : (checked = "");
                  return (
                    <div
                      className="filter-option"
                      title={area}
                      onClick={dataFilterSelectHandler}
                      key={index}
                    >
                      {checked ? (
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="icon checked"
                        />
                      ) : (
                        <FontAwesomeIcon icon={faCircle} className="icon" />
                      )}{" "}
                      {area}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}

        <div id="data-filter">
          <div
            id="data-filter-container"
            className={props.filterOpen ? "open" : "closed"}
          >
            {noOfFilters ? (
              <div
                id="filter-select"
                className="right active"
                onClick={toggleFilterOpen}
              >
                <div className="filter-number">{noOfFilters}</div>
                <div className="corep-filter-main">
                  <FontAwesomeIcon
                    icon={faSlidersSimple}
                    className="icon corep-icon"
                  />{" "}
                  {t("CS016")}
                </div>
              </div>
            ) : (
              <div
                id="filter-select"
                className="right"
                onClick={toggleFilterOpen}
              >
                <div className="corep-filter-main">
                  <FontAwesomeIcon
                    icon={faSlidersSimple}
                    className="icon corep-icon"
                  />{" "}
                  {t("CS016")}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div id="results" className="results-content">
        {(dataActiveFilter === "Overview" ||
          dataActiveFilter === "Health Areas") &&
          activeData &&
          activeData.total >= limit && (
            <Overview
              data={props.data}
              onOpenModal={props.onOpenModal}
              coData={props.coData}
              reportPeriod={props.reportPeriod}
            />
          )}
        {dataActiveFilter === t("CS003") && (
          <Demographics data={props.data} onOpenModal={props.onOpenModal} />
        )}
        {dataActiveFilter === t("CS013") && (
          <AlcoholSmoking
            data={props.data}
            onOpenModal={props.onOpenModal}
            isLoading={props.isLoading}
          />
        )}
        {dataActiveFilter === t("CS006") && (
          <Activity data={props.data} onOpenModal={props.onOpenModal} />
        )}
        {dataActiveFilter === t("CS002") && (
          <BehaviourChange data={props.data} onOpenModal={props.onOpenModal} />
        )}
        {dataActiveFilter === t("CS014") && (
          <Energy data={props.data} onOpenModal={props.onOpenModal} />
        )}
        {dataActiveFilter === t("CS009") && (
          <FinancialWellbeing
            data={props.data}
            onOpenModal={props.onOpenModal}
          />
        )}
        {dataActiveFilter === t("CS012") && (
          <Hydration data={props.data} onOpenModal={props.onOpenModal} />
        )}
        {dataActiveFilter === t("CS010") && (
          <MentalWellbeing
            data={props.data}
            onOpenModal={props.onOpenModal}
            isLoading={props.isLoading}
          />
        )}
        {dataActiveFilter === t("CS004") && (
          <MSKHealth data={props.data} onOpenModal={props.onOpenModal} />
        )}
        {dataActiveFilter === t("CS011") && (
          <Nutrition data={props.data} onOpenModal={props.onOpenModal} />
        )}
        {dataActiveFilter === t("CS005") && (
          <PhysicalHealth
            data={props.data}
            onOpenModal={props.onOpenModal}
            isLoading={props.isLoading}
          />
        )}
        {dataActiveFilter === t("CS007") && (
          <Sleep
            data={props.data}
            onOpenModal={props.onOpenModal}
            isLoading={props.isLoading}
          />
        )}
        {dataActiveFilter === t("CS008") && (
          <Stress data={props.data} coData={props.coData} onOpenModal={props.onOpenModal} />
        )}
      </div>
    </div>
  );
}
export default CompanyStatistics;
