import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "../styles/componentStyles/HorizontalSlider.css";

const bars = [
  "#64bf91", //1
  "#7dc287", //3
  "#87c383", //4
  "#8bc381", //5
  "#97c57d", //6
  "#9cc57b", //7
  "#aac775", //8
  "#b1c772",
  "#adc774", //9
  "#c2c96b", //10
  "#d2c965", //11
  "#e1c95f", //12
  "#efc959", //13
  "#fac955", //14
  "#f8c956", //15
  "#FCC954", //16
  "#FCC954", //17
  "#FCC454", //18
  "#FDB955", //19
  "#FDB257", //20
  "#FDB558", //21
  "#FDB05A", //22
  "#FCAF5B", //23
  "#FCA95C", //24
  "#FCA45E", //25
  "#FC9F60", //26
  "#FC9962", //27
  "#FC9364", //29
  "#FC8D67", //29
  "#FC8769", //30
  "#FC816C", //31
  "#FC7B6F", //32
];

const valuesStress = [
  { id: 1, title: "low", label: "T188" },
  { id: 2, title: "low-moderate", label: "T189" },
  { id: 3, title: "moderate", label: "T190" },
  { id: 4, title: "moderate-high", label: "T191" },
  { id: 5, title: "high", label: "T192" },
];

const values1to10 = [
  { id: 1, title: "1" },
  { id: 2, title: "2" },
  { id: 3, title: "3" },
  { id: 4, title: "4" },
  { id: 5, title: "5" },
  { id: 6, title: "6" },
  { id: 7, title: "7" },
  { id: 8, title: "8" },
  { id: 9, title: "9" },
  { id: 10, title: "10" },
];

const indexToValue = (index, scale, valuesArr) => {
  let value = Math.ceil((index * scale) / 32);
  return value === 0 ? valuesArr[0] : valuesArr[value - 1];
};

const Slider = (props) => {
  const { t } = useTranslation();
  const scale = props.scale1to10 ? 10 : 5;
  const [value, setValue] = useState("");
  const [valuesArr, setValuesArr] = useState([]);
  const [indexValue, setIndexValue] = useState("");

  useEffect(() => {
    // let initialValue;
    if (props.scale1to10) {
      setValuesArr([...values1to10]);
      if (props.data?.[props.questionid] && props.data?.["bar_index"]) {
        setValue(values1to10[Number(props.data?.[props.questionid]) - 1]);
        setIndexValue(props.data?.["bar_index"]);
        // initialValue =
        //   values1to10[Number(props.data?.[props.questionid]) - 1].id;
      } else {
        setValue(values1to10[4]);
        setIndexValue(16);
        // initialValue = values1to10[4].id;
      }
    } else {
      setValuesArr([...valuesStress]);
      if (props.data?.[props.questionid] && props.data?.["bar_index"]) {
        setValue(valuesStress[Number(props.data?.[props.questionid]) - 1]);
        setIndexValue(props.data?.["bar_index"]);
        // initialValue =
        //   valuesStress[Number(props.data?.[props.questionid]) - 1].id;
      } else {
        setValue(valuesStress[2]);
        // initialValue = valuesStress[2].id;
        setIndexValue(16);
      }
    }
    // const newIndexValue = Math.ceil(
    //   (32 / scale) * initialValue - 32 / scale / 2
    // );
    // setIndexValue(newIndexValue);
  }, [props]);

  const handleHover = (event) => {
    const newIndex = parseInt(event.target.id);
    if (newIndex) {
      setIndexValue(newIndex);
      const newValue = indexToValue(newIndex, scale, valuesArr);
      setValue(newValue);
    }
  };

  const handleSave = () => {
    props.saveResponse("data", {
      id: props.questionid,
      value: value.id,
    });
    props.saveResponse("data", {
      id: "bar_index",
      value: indexValue,
    });
  };

  return (
    <React.Fragment>
      {props.scale1to10 && (
        <div className="hor-slider-scale">
          <p>{t("T044")}</p>
          <p>{t("T045")}</p>
        </div>
      )}
      <div
        className="hor-slider"
        onMouseLeave={handleSave}
        onTouchEnd={handleSave}
      >
        {valuesArr &&
          value &&
          indexValue &&
          bars.map((bar, index) =>
            indexValue >= index + 1 ? (
              value && indexValue === index + 1 ? (
                <div
                  className="bar"
                  key={index}
                  id={index + 1}
                  onMouseEnter={handleHover}
                  onTouchStart={(event) => handleHover(event.touches[0])}
                >
                  <div
                    id={index + 1}
                    className="color-bar"
                    style={{
                      backgroundColor: `${bar}`,
                    }}
                    onMouseEnter={handleHover}
                    onTouchStart={(event) => handleHover(event.touches[0])}
                  >
                    <div className="hor-slider-tooltip aa">
                      {props.scale1to10 ? value.title : t(value.label)}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="bar"
                  key={index}
                  id={index + 1}
                  onMouseEnter={handleHover}
                  onTouchStart={(event) => handleHover(event.touches[0])}
                >
                  {" "}
                  <div
                    id={index + 1}
                    className="color-bar"
                    style={{
                      backgroundColor: `${bar}`,
                    }}
                    onMouseEnter={handleHover}
                    onTouchStart={(event) => handleHover(event.touches[0])}
                  ></div>
                </div>
              )
            ) : (
              <div
                className="bar"
                key={index}
                id={index + 1}
                onMouseEnter={handleHover}
                onTouchStart={(event) => handleHover(event.touches[0])}
              >
                {" "}
                <div
                  id={index + 1}
                  className="color-bar"
                  style={{
                    backgroundColor: `#f1ebf4`,
                  }}
                  onMouseEnter={handleHover}
                  onTouchStart={(event) => handleHover(event.touches[0])}
                ></div>
              </div>
            )
          )}
      </div>
    </React.Fragment>
  );
};

export default Slider;
