import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../styles/componentStyles/TrackerHeading.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";
import { formatDate } from "../../shared/util/helperfunctions";
import DarkButton from "../../shared/components/styledComponents/darkButton/DarkButton";

const TrackerHeading = ({ title, setDate, isSaved }) => {
  const [numDays, setNumDays] = useState(0);
  const [popupOpen, setPopupOpen] = useState(null);
  const { t } = useTranslation();

  const forceDecrease = () => {
    if (numDays > -7 && popupOpen === "decrease") {
      setNumDays((prevNumDays) => prevNumDays - 1);
      setPopupOpen(null);
    }
  };

  const decreaseDays = () => {
    if (!isSaved && numDays > -7) {
      setPopupOpen("decrease");
    } else {
      if (numDays > -7) {
        setNumDays(numDays - 1);
      }
    }
  };

  const forceIncrease = () => {
    if (numDays < 0 && popupOpen === "increase") {
      setNumDays((prevNumDays) => prevNumDays + 1);
      setPopupOpen(null);
    }
  };

  const increaseDays = () => {
    if (!isSaved && numDays < 0) {
      setPopupOpen("increase");
    } else {
      if (numDays < 0) {
        setNumDays(numDays + 1);
      }
    }
  };

  const getDay = () => {
    let date = new Date();
    date.setDate(date.getDate() + numDays);
    setDate(() => formatDate(date));
    if (numDays === 0) {
      return t("T464");
    } else if (numDays === -1) {
      return t("T022");
    } else if (numDays >= -7 && numDays < -1) {
      return date.toLocaleDateString("en-US", {
        weekday: "long",
        month: "long",
        day: "numeric",
      });
    }
  };

  return (
    <div className="tracker-heading">
      {popupOpen && (
        <div className="popup">
          <div className="popup-heading">{t("T538")}</div>
          <div className="popup-text">{t("T539")}</div>
          <div className="btn-cont">
            <DarkButton
              className="pink submit"
              onClick={popupOpen === "increase" ? forceIncrease : forceDecrease}
            >
              {t("T066")}
            </DarkButton>
            <DarkButton
              className="pink submit"
              onClick={() => {
                setPopupOpen(null);
              }}
            >
              {t("T067")}
            </DarkButton>
          </div>
        </div>
      )}
      <FontAwesomeIcon
        icon={faChevronLeft}
        style={{ color: `${numDays <= -7 ? "#ddcedb" : "#64596b"}` }}
        className="icon"
        onClick={decreaseDays}
      />
      <div>
        {t(title)}: {getDay()}
      </div>
      <FontAwesomeIcon
        icon={faChevronRight}
        style={{ color: `${numDays >= 0 ? "#ddcedb" : "#64596b"}` }}
        className="icon"
        onClick={increaseDays}
      />
    </div>
  );
};

export default TrackerHeading;
