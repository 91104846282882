import { useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DarkButton from "../../shared/components/styledComponents/darkButton/DarkButton";
import {
  faArrowRight,
  faBullseyeArrow,
} from "@fortawesome/pro-light-svg-icons";

import { useTranslation } from "react-i18next";

import InfoSlider from "../../shared/components/slider/InfoSlider";

const ReviewGoals = (props) => {
  const { t } = useTranslation();
  const goals = useSelector((state) => state.goals.goals);

  return (
    <div className="popup-overlay">
      <div className="popup-container results goals ">
        <div className="popup-title">{t("G024")}</div>
        <div className="goalsrw">
          <InfoSlider title={""} id={`review-goals-slider`} textArr={goals} />
          <div className="orange-box">
            <div className="ob-title">
              <FontAwesomeIcon icon={faBullseyeArrow} /> {t("G086")}
            </div>
            <div className="ob-text">{t("G087")}</div>
            <DarkButton onClick={props.editGoals} className={"nb"}>
              {t("G031")}
            </DarkButton>
          </div>
          <DarkButton onClick={props.handleClose} className={"full_width"}>
            {t("G032")} <FontAwesomeIcon icon={faArrowRight} className="icon" />
          </DarkButton>
        </div>
      </div>
    </div>
  );
};

export default ReviewGoals;
