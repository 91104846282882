import React, { useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { faTrophy } from "@fortawesome/sharp-regular-svg-icons";
import {
  faCircleChevronLeft,
  faArrowLeft,
} from "@fortawesome/pro-solid-svg-icons";

import { activity_list } from "../../shared/components/data.js";
import DarkButton from "../../shared/components/styledComponents/darkButton/DarkButton.js";
// import TeamChallenge from "../../components/TeamChallenge";
import Journal from "../components/Journal.js";
import Influencers from "../components/Influencers.js";
import GreyToggle from "../components/GreyToggle.js";
import ActivityList from "../components/ActivityList.js";
import HorseshoeSlider from "../components/HorseshoeSlider.js";
import TrackerHeading from "../components/TrackerHeading.js";

import { useHttpClient } from "../../shared/hooks/http-hook.js";
import Warning from "../components/Warning.js";
import TrackerChallengeBox from "../../challenges/components/TrackerChallengeBox.js";
import { shouldShowChallengeOnTracker } from "../../shared/util/challenges.js";

const ActivityTracker = (props) => {
  const pageid = window.location.pathname;
  const healthArea = pageid.split("/")[1];
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { t } = useTranslation();
  const warningRef = useRef(null);
  const [isSaved, setIsSaved] = useState(false);
  const [date, setDate] = useState(false);
  const [sliderSteps, setSliderSteps] = useState(0);
  const [existingTracker, setExistingTracker] = useState(false);
  const [estimateSteps, setEstimateSteps] = useState(true);
  const [estimatedSteps, setEstimatedSteps] = useState(0);
  const [data, setData] = useState(null);
  const [journal, setJournal] = useState(null);
  const [responseRequired, setResponseRequired] = useState([]);
  const [currentTracker, setCurrentTracker] = useState(null);
  const [showWheelchairToggle, setShowWheelchairToggle] = useState(true);
  const [showStepsToggle, setShowStepsToggle] = useState(true);
  const [wheelchair, setWheelchair] = useState(false);
  const [resetLines, setResetLines] = useState(false);
  const challenge = useSelector((state) => state?.challenges?.challenge);

  useEffect(() => {
    if (warningRef.current && responseRequired) {
      const modalElement = warningRef.current.closest(".trackers");

      if (modalElement) {
        modalElement.scrollTop = warningRef.current.offsetTop;
      } else {
        window.scrollTo({
          top: warningRef.current.offsetTop,
          behavior: "smooth",
        });
      }
    }
  }, [warningRef, responseRequired]);

  const getData = async (date) => {
    if (date.length > 0) {
      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/trackers/activity/${date}`
        );
        if (response && response.tracker?._id) {
          let tracker = response.tracker;
          setCurrentTracker({
            id: tracker._id,
            trackerDate: tracker.tracker_date.toString().slice(0, 10),
          });
          let data = JSON.parse(tracker.data);
          setData(data);
          setSliderSteps(Number(data.steps));
          setJournal({
            enhancers: tracker.enhancers,
            inhibitors: tracker.inhibitors,
            journal: tracker.journal,
          });
          setIsSaved(true);
          setExistingTracker(true);
        } else {
          setSliderSteps(0);
          setCurrentTracker({
            id: "",
            trackerDate: date,
          });
          setData({
            activity: [],
            steps: "",
            wheelchair: false,
            distance_covered: "",
          });
          setJournal({
            enhancers: [],
            inhibitors: [],
            journal: "",
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    setResetLines(true);
    getData(date);
  }, [date]);

  const saveResponse = (location, stat) => {
    if (location === "journal") {
      setJournal((prevState) => {
        return {
          ...prevState,
          [stat.id]: stat.value,
        };
      });
    } else {
      setData((prevState) => {
        return {
          ...prevState,
          [stat.id]: stat.value,
        };
      });
      if (stat.id === "activity") {
        //calculate estimated steps
        exerciseIntoSteps(stat.value);
      }
    }
  };

  const exerciseIntoSteps = (exerciseArr) => {
    let steps = 0;
    exerciseArr.forEach((item) => {
      let activity = activity_list.find((actvty) => actvty.id === item.id);
      let durationInSteps = Number(activity.value) * Number(item.duration);
      steps += durationInSteps;
    });
    setEstimatedSteps(steps);
    if (estimateSteps && !existingTracker) {
      setSliderSteps(() => steps);
    }
  };

  // useEffect(() => {
  //   if (data && data.activity.length > 0) {
  //     exerciseIntoSteps(data.activity);
  //   }
  //   if (data && !data.wheelchair) {
  //     setSliderSteps(Number(data.steps));
  //   } else if (data && data.wheelchair) {
  //     setWheelchair(data.wheelchair);
  //     setSliderSteps(
  //       Math.round(data.distance_covered * 1.60934 * 1250 * 10) / 10
  //     );
  //   }
  // }, [data]);

  const sendData = async () => {
    let method = "POST";
    let url = `${process.env.REACT_APP_BACKEND_URL}/trackers/`;
    if (currentTracker.id !== "") {
      method = "PUT";
      url = `${process.env.REACT_APP_BACKEND_URL}/trackers/${currentTracker.id}`;
    }
    try {
      const response = await sendRequest(
        url,
        method,
        JSON.stringify({
          trackerDate: date,
          type: "activity",
          data: data,
          hidden: false,
          enhancers: journal.enhancers,
          inhibitors: journal.inhibitors,
          journal: journal.journal,
          platform: "web",
        })
      );
      if (response) {
        setIsSaved(true);
        props.closeTrackers();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = () => {
    const emptyKeys = [];

    if (data.steps === "") {
      emptyKeys.push("steps");
    }

    if (emptyKeys.length > 0) {
      setResponseRequired(emptyKeys);
    } else {
      setResponseRequired([]);
      sendData();
    }
  };

  const handleToggleSteps = () => {
    if (estimateSteps) {
      setEstimateSteps(false);
      setSliderSteps(0);
      // setEstimatedSteps(0);
    } else {
      setEstimateSteps(true);
      setSliderSteps(estimatedSteps);
      // setEstimatedSteps(steps);
    }
  };

  const handleSliderSteps = (newValue) => {
    // if (estimateSteps) {
    //   setSliderSteps(() => Number(newValue) - Number(estimatedSteps));
    //   saveResponse("data", {
    //     id: "steps",
    //     value: (Number(newValue) - Number(estimatedSteps)).toFixed(),
    //   });
    //   saveResponse("data", {
    //     id: "distance_covered",
    //     value:
    //       Math.round(
    //         ((Number(newValue) - estimatedSteps) / 1250) * 0.621371 * 10
    //       ) / 10,
    //   });
    // } else {
    setSliderSteps(newValue);
    saveResponse("data", {
      id: "steps",
      value: Number(newValue).toFixed(),
    });
    saveResponse(
      "data",

      {
        id: "distance_covered",
        value: Math.round((Number(newValue) / 1250) * 0.621371 * 10) / 10,
      }
    );
    // }
  };

  const closeWheelchairToggle = () => {
    setShowWheelchairToggle(false);
  };

  const closeStepsToggle = () => {
    setShowStepsToggle(false);
  };

  const handleToggleWheelchair = (on) => {
    // use wheelchair estimates and change steps to miles in the horseshoe slider
    setWheelchair(!wheelchair);
    saveResponse("data", {
      id: "wheelchair",
      value: on,
    });
  };

  const [narrowButtons, setNarrowButtons] = useState(true);

  useEffect(() => {
    //if .trackers-inner is scrolled to bottom - set narrow buttons to false)
    const trackersInner = document.querySelector(".trackers-inner");
    const handleScroll = () => {
      if (
        trackersInner.scrollTop + trackersInner.clientHeight >=
        trackersInner.scrollHeight
      ) {
        setNarrowButtons(false);
      } else {
        setNarrowButtons(true);
      }
    };
    trackersInner.addEventListener("scroll", handleScroll);
    return () => {
      trackersInner.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div id="snm-activity" className="tracker-container">
      <div className="trackers-inner">
        <div className="back-to-main">
          <div className="back-btn" onClick={props.handleBackToMain}>
            <FontAwesomeIcon icon={faCircleChevronLeft} />
          </div>
        </div>
        <TrackerHeading title="T064" setDate={setDate} isSaved={isSaved} />
        {data && (
          <div className="trackers-body">
            <div className="ind-tracker">
              {showWheelchairToggle && (
                <GreyToggle
                  questionid="wheelchair"
                  wheelchair={wheelchair}
                  toggleWheelchair={handleToggleWheelchair}
                  closeWheelchairToggle={closeWheelchairToggle}
                />
              )}
              {shouldShowChallengeOnTracker(
                healthArea,
                challenge?.type,
                challenge?.isActive
              ) && <TrackerChallengeBox />}
              {/* <TeamChallenge /> */}
              <div className="title">
                {t("T068")} <p>{t("T019")}</p>
              </div>
              <ActivityList
                questionid="activity"
                data={data}
                saveResponse={saveResponse}
                resetLines={resetLines}
                setResetLines={setResetLines}
                setExistingTracker={setExistingTracker}
              />
            </div>
            <div className="ind-tracker">
              {responseRequired.includes("steps") && (
                <React.Fragment>
                  <div className="buffer" ref={warningRef}></div>
                  <Warning />
                </React.Fragment>
              )}
              <div className="title">
                {wheelchair ? t("T550") : t("T072")}{" "}
                {shouldShowChallengeOnTracker(
                  healthArea,
                  challenge?.type,
                  challenge?.isActive
                ) && (
                  <div className="tc-badge">
                    <FontAwesomeIcon icon={faTrophy} /> <p>{t("T017")}</p>
                  </div>
                )}
                {responseRequired.includes("steps") && (
                  <FontAwesomeIcon className="red-arrow" icon={faArrowLeft} />
                )}
              </div>
              <div className="buffer"></div>
              {showStepsToggle && (
                <GreyToggle
                  wheelchair={wheelchair}
                  steps
                  toggleSteps={handleToggleSteps}
                  closeStepsToggle={closeStepsToggle}
                />
              )}
              <HorseshoeSlider
                estimateSteps={estimateSteps}
                // estimatedSteps={estimatedSteps}
                sliderSteps={sliderSteps}
                handleSliderSteps={handleSliderSteps}
                qustionid="steps"
                data={data}
                wheelchair={wheelchair}
              />
            </div>
            <div className="ind-tracker">
              <div className="title ">
                {t("T091")} <p>{t("T019")}</p>
              </div>
              <Influencers
                activity
                questionid={["enhancers", "inhibitors"]}
                data={journal}
                saveResponse={saveResponse}
              />
            </div>
            <div className="ind-tracker">
              <Journal
                placeholder={t("T542")}
                questionid="journal"
                data={journal}
                saveResponse={saveResponse}
              />
            </div>
          </div>
        )}
      </div>
      <div className={"buttons " + (narrowButtons ? "narrow" : "")}>
        <div className="close-btn" onClick={props.closeTrackers}>
          {t("T015")}
        </div>
        <DarkButton className="submit" onClick={handleSubmit}>
          {t("T016")} <FontAwesomeIcon className="icon" icon={faArrowRight} />
        </DarkButton>
      </div>
    </div>
  );
};
export default ActivityTracker;
