import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CircleGraph from "../../components/graphs/circleGraph/CircleGraph";
import TopThreeGraph from "../../components/graphs/topThreeGraph/TopThreeGraph";
import BarChart from "../../components/graphs/barChart/BarChart";

function Demographics(props) {
  const { t } = useTranslation();
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const nutrition_quality = [
    {
      date: t("NTR001"),
      value: props.data.nutrition_quality_very_poor,
      height: "0%",
    },
    {
      date: t("NTR002"),
      value: props.data.nutrition_quality_poor,
      height: "0%",
    },
    {
      date: t("NTR003"),
      value: props.data.nutrition_quality_average,
      height: "0%",
    },
    {
      date: t("NTR004"),
      value: props.data.nutrition_quality_good,
      height: "0%",
    },
    {
      date: t("NTR005"),
      value: props.data.nutrition_quality_excellent,
      height: "0%",
    },
  ];
  const nutrition_veg = [
    { date: "≤ 3", value: props.data.nutrition_veg_3, height: "0%" },
    { date: "4-6", value: props.data.nutrition_veg_6, height: "0%" },
    { date: "≥7", value: props.data.nutrition_veg_7, height: "0%" },
  ];
  return (
    <React.Fragment>
      <TopThreeGraph
        title={t("NTR006")}
        square
        data={props.data.nutrition_diet_type}
        onOpenModal={props.onOpenModal}
      />
      <BarChart
        title={t("NTR007")}
        data={nutrition_quality}
        unit="%"
        width="wide"
        modalText={t("NTR008")}
        onOpenModal={props.onOpenModal}
      />
      <BarChart
        title={t("NTR009")}
        data={nutrition_veg}
        unit="%"
        width="wide"
        modalText={t("NTR010")}
        onOpenModal={props.onOpenModal}
      />
      <CircleGraph
        title={t("NTR011")}
        square
        colour="colour1"
        value={props.data.nutrition_productivity_no}
        large
        percent={props.data.nutrition_productivity}
        modalText={t("NTR012")}
        onOpenModal={props.onOpenModal}
      />
    </React.Fragment>
  );
}
export default Demographics;
