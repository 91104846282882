import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";

import { faShieldAlt } from "@fortawesome/sharp-solid-svg-icons";
import { useFetchSingleMediaQuery } from "../../../contentTypes/mediaApiSlice";
import useComponentVisible from "../../hooks/useComponentVisible";
import { convertTime } from "../../util/helperfunctions";
import SliderTooltip from "./SliderTooltip";
import { AuthContext } from "../../context/auth-context";
import { setSingleMedia } from "../../../contentTypes/mediaSlice";

const ContentItem = (props) => {
  let navigate = useNavigate();
  const auth = useContext(AuthContext);
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const [openTooltip, setOpenTooltip] = useState(false);

  const [isClicked, setIsClicked] = useState(false);

  const {
    data: media,
    error,
    isLoading,
  } = useFetchSingleMediaQuery(
    {
      token: auth?.token,
      url: `/${
        props.slideContent?.media?.category?.toLowerCase() === "programme"
          ? "programme"
          : props.slideContent?.media?.category.toLowerCase() === "masterclass"
          ? "masterclass"
          : props.slideContent?.media?.chid.startsWith("TA") ||
            props.slideContent.chid.startsWith("TA")
          ? "take-actions"
          : "media"
      }/${
        props.slideContent.chid.startsWith("TA")
          ? props.slideContent?._id
          : props.slideContent?.media?.chid.startsWith("TA")
          ? props.slideContent?.media?._id
          : props.slideContent?.media?.chid
      }`,
    },
    {
      skip: !isClicked,
    }
  );

  useEffect(() => {
    if (!isLoading && media && isClicked) {
      setIsClicked(false);
      dispatch(setSingleMedia(media));
      let url = "media";
      if (media.chid.startsWith("TA")) {
        url = "take-actions";
      } else if (media?.category?.toLowerCase() === "programme") {
        url = "programme";
      } else if (media.category?.toLowerCase() === "masterclass") {
        url = "masterclass";
      }
      navigate(`/${url}/${media.chid}/${media.title.replace(/ /g, "_")}`);
      // } else {
      //   navigate(
      //     `/${media.label.toLowerCase().replace(/ /g, "_")}/player/` +
      //       media.chid +
      //       "/" +
      //       media.title.replace(/ /g, "_")
      //   );
      // }
    }
  }, [media, isLoading, isClicked, dispatch]);

  const openMediaModal = (preferences) => {
    if (
      !(
        location.pathname.startsWith("/media") ||
        location.pathname.startsWith("/take-actions") ||
        location.pathname.startsWith("/programme") ||
        location.pathname.startsWith("/masterclass")
      )
    ) {
      sessionStorage.setItem(
        "previousLocation",
        JSON.stringify({
          pathname: location.pathname,
          scrollY: window.scrollY,
        })
      );
    }
    if (props.slideContent.chid.startsWith("PB") && props.slideContent.url) {
      window.open(props.slideContent.url, "_blank");
    } else if (
      (props.slideContent.chid.startsWith("PB") && props.slideContent.media) ||
      props.slideContent.chid.startsWith("TA")
    ) {
      setIsClicked(true);
      //get single media item and trigger useEffect
    } else {
      let url = "media";
      if (props.slideContent?.category?.toLowerCase() === "programme") {
        url = "programme";
      } else if (
        props.slideContent?.category?.toLowerCase() === "masterclass"
      ) {
        url = "masterclass";
      } else if (props.slideContent?.media?.chid.startsWith("TA")) {
        url = "take-actions";
      }
      navigate(
        `/${url}/` +
          props.slideContent.chid +
          "/" +
          props.slideContent.title.replace(/ /g, "_")
      );
    }
  };

  const handleTileTooltip = (e) => {
    e.stopPropagation();
    if (openTooltip) {
      setOpenTooltip(false);
    } else {
      setOpenTooltip(true);
    }
  };

  const closeTooltip = () => {
    setOpenTooltip(false);
  };

  //detect click outside of SliderTooltip and close it
  useEffect(() => {
    if (openTooltip) {
      const closeTooltip = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
          setOpenTooltip(false);
        }
      };
      document.addEventListener("click", closeTooltip);
      return () => document.removeEventListener("click", closeTooltip);
    }
  }, [openTooltip]);

  return (
    <React.Fragment>
      <div
        className="innerSlide"
        style={{
          backgroundImage: `url(${
            props.slideContent.chid.startsWith("PB") || props?.author
              ? props.slideContent.background_image
              : props.slideContent.thumbnail_image
          })`,
        }}
        onClick={openMediaModal}
        ref={ref}
      >
        {props?.author && (
          <div className="authorPage_featured_info">
            <div className="authorPage_featured_star" />
            <div className="authorPage_featured_text">{t("AT005")}</div>
            <div className="authorPage_featured_play"/>
          </div>
         
        )}
        {props.slideContent.chid.startsWith("PB") && (
          <img
            src={props.slideContent.logo_image}
            preserveAspectRatio="none"
            className="pb-badge"
            alt="Logo"
          />
        )}

        {props.slideContent.chid.startsWith("TA") && !props?.author && (
          <span className="ta-badge">
            <FontAwesomeIcon className="ta-shield" icon={faShieldAlt} />
          </span>
        )}
        {!(
          props.promo ||
          props.slideContent.chid.startsWith("TA") ||
          props?.author
        ) && (
          <span className="ellipsis" onClick={handleTileTooltip}>
            <FontAwesomeIcon icon={faEllipsis} className="icon active_fav" />
            {openTooltip && (
              <SliderTooltip
                slideContent={props.slideContent}
                handleTileTooltip={handleTileTooltip}
                closeTooltip={closeTooltip}
              />
            )}
          </span>
        )}
        {!props.slideContent.chid.startsWith("PB") && (
          <div className="innerFade">
            <div className="innerContent">
              <h2>{props.slideContent?.title?.replace(/&amp;/gi, "&")}</h2>

              {props.slideContent?.length > 0 && (
                <span className="length">
                  {props.slideContent?.length
                    ? convertTime(props.slideContent.length)
                    : props.slideContent?.days
                    ? props.slideContent?.days.length + " days"
                    : ""}
                </span>
              )}

              {props.slideContent?.label && (
                <span
                  className={
                    props.slideContent?.length > 0
                      ? "category"
                      : "category full_width"
                  }
                >
                  {props.slideContent.label}
                </span>
              )}
              {(props.slideContent?.type ||
                props.slideContent.category?.toLowerCase() === "masterclass" ||
                props.slideContent.category?.toLowerCase() === "programme") &&
                !props?.author && (
                  <div className="progress_container">
                    <div
                      className="progress_bar"
                      style={{
                        width: props.slideContent.progress + "%",
                      }}
                    ></div>
                  </div>
                )}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
export default ContentItem;
