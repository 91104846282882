// goalsSlice.js
import { current, createSlice } from "@reduxjs/toolkit";

const mediaSlidersSlice = createSlice({
  name: "mediaSliders",
  initialState: {
    title: "",
    sliders: [],
    navigation: [
      { target: 11, title: "Physical Wellbeing" },
      { target: 12, title: "Movement" },
      { target: 13, title: "Sleep & Rest" },
      { target: 14, title: "Mental Wellbeing" },
      { target: 15, title: "Financial Wellbeing" },
      { target: 16, title: "Nutrition" },
      { target: 17, title: "Hydration & Alcohol" },
      { target: 18, title: "Work & Productivity" },
      { target: 19, title: "Energy" },
    ],
    likes: [],
    dislikes: [],
    feature: [],
    promotions: [],
    takeAction: [],
    favourites: {
      _id: "",
      title: "Your List",
      type: "slider",
      media: [],
    },
  },
  reducers: {
    setMediaSliders: (state, action) => {
      if (action.payload.mediaSliders) {
        if (action.payload.sectionid === "foryou") {
          const favouriteIndex = action.payload.mediaSliders.sliders.findIndex(
            (slider) => slider.title === "Your List"
          );
          // if we  have a favourites slider
          if (favouriteIndex !== -1) {
            state.favourites =
              action.payload.mediaSliders.sliders[favouriteIndex];
          }
        }
        state.sliders = action.payload.mediaSliders.sliders;
        state.likes = action.payload.mediaSliders.likes;
        state.dislikes = action.payload.mediaSliders.dislikes;
      }
    },
    setFeature: (state, action) => {
      if (action.payload) {
        state.title = action.payload.sectionid;
        state.feature = action.payload.feature;
        state.promotions = action.payload.promotions;
      }
    },
    setTakeAction: (state, action) => {
      if (action.payload) {
        state.takeAction = action.payload;
      }
    },
    updateFavouritesSlider: (state, action) => {
      let exists = state.favourites.media.some(
        (element) => element._id === action.payload._id
      );
      if (exists) {
        state.favourites.media = state.favourites.media.filter(
          (element) => element._id !== action.payload._id
        );
      } else {
        state.favourites.media.push(action.payload);
      }

      //if it's for you page, update the slider
      const favouriteIndex = state.sliders.findIndex(
        (slider) => slider.title === "Your List"
      );

      if (favouriteIndex !== -1) {
        state.sliders[favouriteIndex].media = state.favourites.media;
      }
    },
    updateLikesDislikes: (state, action) => {
      let existsLike = state.likes.includes(action.payload.mediaId);
      let existsDislike = state.dislikes.includes(action.payload.mediaId);

      if (action.payload.preferences === "like") {
        if (existsLike) {
          state.likes = state.likes.filter(
            (element) => element !== action.payload.mediaId
          );
        } else {
          state.likes.push(action.payload.mediaId);
          if (existsDislike)
            state.dislikes = state.dislikes.filter(
              (element) => element !== action.payload.mediaId
            );
        }
      } else if (action.payload.preferences === "dislike") {
        if (existsDislike) {
          state.dislikes = state.dislikes.filter(
            (element) => element !== action.payload.mediaId
          );
        } else {
          state.dislikes.push(action.payload.mediaId);
          if (existsLike)
            state.likes = state.likes.filter(
              (element) => element !== action.payload.mediaId
            );
        }
      }
    },
    resetMediaSliders: (state) => {
      return {
        title: "",
        sliders: [],
        navigation: [
          { target: 11, title: "Physical Wellbeing" },
          { target: 12, title: "Movement" },
          { target: 13, title: "Sleep & Rest" },
          { target: 14, title: "Mental Wellbeing" },
          { target: 15, title: "Financial Wellbeing" },
          { target: 16, title: "Nutrition" },
          { target: 17, title: "Hydration & Alcohol" },
          { target: 18, title: "Work & Productivity" },
          { target: 19, title: "Energy" },
        ],
        likes: [],
        dislikes: [],
        feature: [],
        promotions: [],
        takeAction: [],
        favourites: {
          _id: "",
          title: "Your List",
          type: "slider",
          media: [],
        },
      };
    },
  },
});

// your exported actions and reducer...
export const {
  setMediaSliders,
  setFeature,
  setTakeAction,
  updateFavouritesSlider,
  updateLikesDislikes,
  resetMediaSliders,
} = mediaSlidersSlice.actions;
export default mediaSlidersSlice.reducer;
