import { t } from "i18next";
import {
  faShoePrints,
  faDroplet,
  faSpa,
} from "@fortawesome/pro-solid-svg-icons";

export const shouldShowChallenge = (sectionid, challengeType) => {
  if (
    (challengeType === "hydration" && sectionid === "hydration") ||
    (challengeType === "step" && sectionid === "movement") ||
    (challengeType === "mindfulness" && sectionid === "mental")
  ) {
    return true;
  }
  return false;
};

export const getChallengeTypeUnit = (challengeType, value = 0) => {
  if (challengeType === "mindfulness") {
    if (value < 120) {
      return t("CH024");
    }
    return t("CH025");
  }
  if (challengeType === "step") return t("CH026");
  if (challengeType === "hydration") return t("CH027");
  return "";
};

const formatMovementValue = (value) => {
  if (value < 999) {
    return value;
  } else if (value <= 100000) {
    const valueString = value.toString();
    const part1 = valueString.slice(0, -3);
    const part2 = valueString.slice(-3);
    return `${part1},${part2}`;
  } else if (value > 100000 && value <= 1000000) {
    return Math.round(value / 1000) + "K";
  } else {
    return (value / 1000000).toFixed(1) + "M";
  }
};

const formatMinutesValue = (value) => {
  if (value < 120) {
    return value;
  } else {
    const hours = Math.floor(value / 60);
    const minutesLeft = value % 60;
    let minutes;
    if (minutesLeft < 30) {
      minutes = 0;
    } else {
      minutes = 0.5;
    }
    return parseFloat(hours + minutes).toFixed(1);
  }
};

export const getFormattedValue = (value, challengeType) => {
  if (challengeType === "mindfulness") {
    return formatMinutesValue(value);
  } else {
    return formatMovementValue(value);
  }
};

export const formatName = (name) => {
  if (name.length > 10) {
    return name.slice(0, 10) + "...";
  }
  return name;
};

export const getInitial = (name) => {
  if (name) {
    return name.charAt(0).toUpperCase();
  }
  return "";
};

export const shouldHideNavChallenge = (pageId) => {
  if (
    pageId === "movement" ||
    pageId === "hydration-&-alcohol" ||
    pageId === "mental-wellbeing"
  ) {
    return false;
  } else {
    return true;
  }
};

export const getChallengeType = (sectionid) => {
  if (sectionid === "movement") {
    return "step";
  } else if (sectionid === "hydration") {
    return "hydration";
  } else if (sectionid === "mental") {
    return "mindfulness";
  }
  return null;
};

export const shouldShowChallengeOnTracker = (
  healtharea,
  challengeType,
  isActive
) => {
  if (!isActive) return false;
  if (
    (challengeType === "hydration" && healtharea === "hydration-&-alcohol") ||
    (challengeType === "step" && healtharea === "movement") ||
    (challengeType === "mindfulness" && healtharea === "mental-wellbeing")
  ) {
    return true;
  }
  return false;
};

export const getChallengeIcon = (challengeType) => {
  switch (challengeType) {
    case "mindfulness":
      return faSpa;
    case "step":
      return faShoePrints;
    case "hydration":
      return faDroplet;
    default:
      return faShoePrints;
  }
};
