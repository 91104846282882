import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CircleGraph from "../../components/graphs/circleGraph/CircleGraph";

import TopSixGraph from "../../components/graphs/topSixGraph/TopSixGraph";

import BarChart from "../../components/graphs/barChart/BarChart";

function Demographics(props) {
  const { t } = useTranslation();
  const [showLegacySuport, setShowLegacySuport] = useState(false);
  const [showNewSuport, setShowNewSuport] = useState(false);
  const [showLegacyPartOfTeam, setShowLegacyPartOfTeam] = useState(false);
  const [showNewPartOfTeam, setShowNewPartOfTeam] = useState(false);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (props.coData?.showLegacySupport) {
      setShowLegacySuport(true);
    }
    if (props.coData?.showNewSupport) {
      setShowNewSuport(true);
    }
    if (props.coData?.showLegacyPartOfTeam) {
      setShowLegacyPartOfTeam(true);
    }
    if (props.coData?.showNewPartOfTeam) {
      setShowNewPartOfTeam(true);
    }
  }, [props]);

  const stress_levels = [
    { date: t("STR001"), value: props.data.stress_low, height: "0%" },
    { date: t("STR002"), value: props.data.stress_low_moderate, height: "0%" },
    { date: t("STR003"), value: props.data.stress_moderate, height: "0%" },
    { date: t("STR004"), value: props.data.stress_moderate_high, height: "0%" },
    { date: t("STR005"), value: props.data.stress_high, height: "0%" },
  ];
  const stress_work = [
    { date: t("STR006"), value: props.data.work_stress_none, height: "0%" },
    {
      date: t("STR007"),
      value: props.data.work_stress_perfect,
      height: "0%",
    },
    { date: t("STR008"), value: props.data.work_stress_too_much, height: "0%" },
    {
      date: t("STR009"),
      value: props.data.work_stress_overwhelmed,
      height: "0%",
    },
  ];
  const feeling_supported = [
    { date: t("STR010"), value: props.data?.supported_yes ?? 0, height: "0%" },
    {
      date: t("STR011"),
      value: props.data?.supported_sometimes ?? 0,
      height: "0%",
    },
    { date: t("STR012"), value: props.data?.supported_no ?? 0, height: "0%" },
  ];
  const part_of_team = [
    { date: t("STR010"), value: props.data?.team_yes ?? 0, height: "0%" },
    { date: t("STR011"), value: props.data?.team_sometimes ?? 0, height: "0%" },
    { date: t("STR012"), value: props.data?.team_no ?? 0, height: "0%" },
  ];
  const feeling_supported_at_work = [
    {
      date: t("STR036"),
      value: props.data?.supported_work_always ?? 0,
      height: "0%",
    },
    {
      date: t("STR035"),
      value: props.data?.supported_work_often ?? 0,
      height: "0%",
    },
    {
      date: t("STR034"),
      value: props.data?.supported_work_sometimes ?? 0,
      height: "0%",
    },
    {
      date: t("STR033"),
      value: props.data?.supported_work_rarely ?? 0,
      height: "0%",
    },
    {
      date: t("STR032"),
      value: props.data?.supported_work_never ?? 0,
      height: "0%",
    },
  ];
  const part_of_team_at_work = [
    {
      date: t("STR036"),
      value: props.data?.part_of_team_always ?? 0,
      height: "0%",
    },
    {
      date: t("STR035"),
      value: props.data?.part_of_team_often ?? 0,
      height: "0%",
    },
    {
      date: t("STR034"),
      value: props.data?.part_of_team_sometimes ?? 0,
      height: "0%",
    },
    {
      date: t("STR033"),
      value: props.data?.part_of_team_rarely ?? 0,
      height: "0%",
    },
    {
      date: t("STR032"),
      value: props.data?.part_of_team_never ?? 0,
      height: "0%",
    },
  ];

  return (
    <React.Fragment>
      <BarChart
        title={t("STR013")}
        data={stress_levels}
        unit="%"
        width="wide"
        modalText={t("STR014")}
        onOpenModal={props.onOpenModal}
      />
      <CircleGraph
        title={t("STR015")}
        square
        colour="colour1"
        value={props.data.stress_level_moderate_to_high_no}
        large
        percent={props.data.stress_level_moderate_to_high}
        modalText={t("STR016")}
        onOpenModal={props.onOpenModal}
      />

      <BarChart
        title={t("STR017")}
        data={stress_work}
        unit="%"
        modalText={t("STR018")}
        onOpenModal={props.onOpenModal}
      />
      <TopSixGraph
        title={t("STR019")}
        square
        preText={t("STR020")}
        modalText={t("STR021")}
        onOpenModal={props.onOpenModal}
        data={props.data.work_stress_cause}
      />

      <CircleGraph
        title={t("STR022")}
        square
        colour="colour1"
        value={props.data.productivity_high_stress_no}
        large
        percent={props.data.productivity_high_stress}
        modalText={t("STR023")}
        onOpenModal={props.onOpenModal}
      />
      {showNewSuport && (
        <BarChart
          title={t("STR030")}
          data={feeling_supported_at_work}
          unit="%"
          startZero
          modalText={t("STR038")}
          onOpenModal={props.onOpenModal}
        />
      )}
      {showNewPartOfTeam && (
        <BarChart
          title={t("STR031")}
          data={part_of_team_at_work}
          unit="%"
          startZero
          modalText={t("STR039")}
          onOpenModal={props.onOpenModal}
        />
      )}
      {showLegacySuport && (
        <BarChart
          title={t("STR024")}
          subTitle={t("STR037")}
          data={feeling_supported}
          unit="%"
          startZero
          modalText={t("STR025")}
          onOpenModal={props.onOpenModal}
        />
      )}
      {showLegacyPartOfTeam && (
        <BarChart
          title={t("STR026")}
          subTitle={t("STR037")}
          data={part_of_team}
          unit="%"
          startZero
          modalText={t("STR027")}
          onOpenModal={props.onOpenModal}
        />
      )}
      <TopSixGraph
        title={t("STR028")}
        square
        modalText={t("STR029")}
        onOpenModal={props.onOpenModal}
        data={props.data.outside_work_stressful}
      />
    </React.Fragment>
  );
}
export default Demographics;
