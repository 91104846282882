import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CircleGraph from "../../components/graphs/circleGraph/CircleGraph";
import TopThreeGraph from "../../components/graphs/topThreeGraph/TopThreeGraph";
import BarChart from "../../components/graphs/barChart/BarChart";

function Demographics(props) {
  const { t } = useTranslation();
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const pain = [
    { date: t("MSKH001"), value: props.data.pain, height: "0%" },
    { date: t("MSKH002"), value: props.data.no_pain, height: "0%" },
  ];

  const pain_severity = [
    { date: t("MSKH003"), value: props.data.mild_pain, height: "0%" },
    { date: t("MSKH004"), value: props.data.moderate_pain, height: "0%" },
    { date: t("MSKH005"), value: props.data.severe_pain, height: "0%" },
  ];

  const treatment = [
    { date: t("MSKH006"), value: props.data.seen_specialist_yes, height: "0%" },
    { date: t("MSKH007"), value: props.data.seen_specialist_no, height: "0%" },
  ];

  return (
    <React.Fragment>
      <BarChart
        title={t("MSKH008")}
        data={pain}
        unit="%"
        modalText={t("MSKH009")}
        onOpenModal={props.onOpenModal}
      />
      <TopThreeGraph
        title={t("MSKH010")}
        square
        preText={t("MSKH011")}
        modalText={t("MSKH012")}
        onOpenModal={props.onOpenModal}
        data={props.data.areas_of_pain}
      />
      <CircleGraph
        title={t("MSKH013")}
        subTitle={t("MSKH014")}
        square
        colour="colour1"
        value={props.data.chronic_pain_no}
        large
        percent={props.data.chronic_pain}
        modalText={t("MSKH015")}
        onOpenModal={props.onOpenModal}
      />
      <BarChart
        title={t("MSKH016")}
        data={pain_severity}
        unit="%"
        modalText={t("MSKH017")}
        onOpenModal={props.onOpenModal}
      />
      <BarChart
        title={t("MSKH018")}
        data={treatment}
        unit="%"
        modalText={t("MSKH019")}
        onOpenModal={props.onOpenModal}
      />
      <CircleGraph
        title={t("MSKH020")}
        subTitle=""
        square
        colour="colour2"
        value={props.data.msk_productivity_no}
        large
        percent={props.data.msk_productivity}
        modalText={t("MSKH021")}
        onOpenModal={props.onOpenModal}
      />
    </React.Fragment>
  );
}
export default Demographics;
