import { useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DarkButton from "../../shared/components/styledComponents/darkButton/DarkButton";
import { faArrowRight, faTimesCircle } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { faCheckCircle } from "@fortawesome/sharp-solid-svg-icons";
import { useContext, useEffect, useState } from "react";
import { useAddGoalMutation, useDeleteGoalMutation } from "../goalsApiSlice";
import WhitePopUpCTA from "../../shared/modal/whitePopUp/WhitePopUpCTA";
import { AuthContext } from "../../shared/context/auth-context";

const GoalSelector = (props) => {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();
  const possibleGoals = useSelector((state) => state.goals.possibleGoals);
  const goals = useSelector((state) => state.goals.goals);
  const [addGoalAPI] = useAddGoalMutation();
  const [deleteGoalAPI] = useDeleteGoalMutation();
  const [activeIds, setActiveIds] = useState([]);
  const [clickedGoal, setClickedGoal] = useState(null);
  const [error, setError] = useState(null);
  const errorMessages = [
    { id: "tooManyGoals", message: t("G023") },
    { id: "noGoals", message: t("G053") },
  ];
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (goals.length > 0) {
      setActiveIds(goals.map((goal) => goal.id));
    }
  }, [goals]);

  const handleAddGoal = (newGoal) => {
    addGoalAPI({
      token: auth.token,
      goalId: newGoal.id,
    }).catch((err) => {
      console.log(err);
    });
  };

  const handleDeleteGoal = (oldGoal) => {
    deleteGoalAPI({
      token: auth.token,
      goalId: oldGoal.id,
    }).catch((err) => {
      console.log(err);
    });
  };

  const handleClick = (goal) => {
    setError(null);
    setClickedGoal(goal);
    if (activeIds.includes(goal.id)) {
      if (activeIds.length === 1 && goals.some((item) => item.id === goal.id)) {
        setError(errorMessages[1]);
      } else if (
        activeIds.length > 1 &&
        goals.some((item) => item.id === goal.id)
      ) {
        setOpenModal(true);
      } else {
        // remove goal from active goals
        const newActiveIds = activeIds.filter((id) => id !== goal.id);
        setActiveIds(newActiveIds);
      }
    } else {
      // selecting a goal that is not active
      if (activeIds.length === 3) {
        setError(errorMessages[0]);
      } else {
        // add goal to active goals ids
        setActiveIds([...activeIds, goal.id]);
        handleAddGoal(goal);
      }
    }
  };

  const handleCloseModal = (clickedGoal) => {
    setActiveIds(() => activeIds.filter((id) => id !== clickedGoal.id));
    handleDeleteGoal(clickedGoal);
    setOpenModal(false);
  };

  const saveChanges = () => {
    props.handleClose();
  };

  return (
    <div className="popup-overlay">
      {openModal && (
        <WhitePopUpCTA
          popupContents={{
            title: t("G044"),
            textArr: [
              { text: t("S020"), className: "sub-heading" },
              {
                text: t("G054"),
                className: "",
              },
            ],
            buttons: [
              {
                text: t("R013"),
                color: "dark",
                onClick: () => {
                  handleCloseModal(clickedGoal);
                },
              },
              {
                text: t("G055"),
                color: "pink",
                onClick: () => {
                  setOpenModal(false);
                },
              },
            ],
          }}
        />
      )}
      <div className="popup-container results goals">
        <div className="popup-title">
          {t("G010")}
          <FontAwesomeIcon
            icon={faTimesCircle}
            className="icon"
            onClick={props.handleClose}
          />
        </div>
        <div className="popup-body">
          <div className="popup-heading dark">{t("G011")}</div>
          <div className="popup-btns-container">
            {possibleGoals.map((goal, index) => (
              <div key={index}>
                <div
                  onClick={() => handleClick(goal)}
                  className={`goal-btn ${
                    clickedGoal?.id === goal.id && error ? "error" : ""
                  } ${activeIds.includes(goal.id) ? "active" : ""} `}
                >
                  {t(goal.title)}
                  <FontAwesomeIcon icon={faCheckCircle} className="icon" />
                </div>
                {clickedGoal?.id === goal.id && error && (
                  <div className="goal-error">{error.message}</div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="popup-cta single">
          <DarkButton onClick={saveChanges}>
            {t("M018")}{" "}
            <FontAwesomeIcon icon={faArrowRight} className="cta-icon" />
          </DarkButton>
        </div>
      </div>
    </div>
  );
};

export default GoalSelector;
