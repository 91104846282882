import React, { useState, useEffect } from "react";
import Nouislider from "@x1mrdonut1x/nouislider-react";
import "nouislider/distribute/nouislider.css";
import "./Style.css";
import ModalTrigger from "../../../shared/modal/ModalTrigger";

const EnergySlider = (props) => {
  const c1 = [239, 84, 90];
  const c2 = [246, 147, 88];
  const c3 = [252, 201, 84];
  const c4 = [175, 196, 115];
  const c5 = [98, 191, 146];

  const [textValue, setTextValue] = useState("5");
  const [startValue, setStartValue] = useState(50);
  const [styles, setStyles] = useState({ color: "rgb(252,201,84)" });
  const [labelstyles, setLabelstyles] = useState({ left: "50%" });

  useEffect(() => {
    if (props.assessmentValues[props.questionid]) {
      const assessmentValue = props.assessmentValues[props.questionid];
      setTextValue(assessmentValue);
      setStyles({
        color: `rgb(${calculateRGB(Number(assessmentValue) * 10)})`,
      });
      setStartValue(Number(assessmentValue) * 10);
      setLabelstyles({ left: Number(assessmentValue) * 10 + "%" });
    } else {
      props.onSetValue({
        id: props.questionid,
        value: "5",
      });
    }
  }, []);

  useEffect(() => {
    if (styles?.color) {
      let energyHandle = document
        .getElementById("energy_slider")
        .querySelectorAll(".noUi-handle");
      if (energyHandle) {
        energyHandle[0].style.backgroundColor = styles.color;
      }
    }
  }, [styles.color]);

  const calculateRGB = (value) => {
    let color1;
    let color2;
    let w1;

    if (value < 26) {
      color1 = c2;
      color2 = c1;
      w1 = (value * 4) / 100;
    } else if (value < 51) {
      color1 = c3;
      color2 = c2;
      w1 = ((value - 25) * 4) / 100;
    } else if (value < 76) {
      color1 = c4;
      color2 = c3;
      w1 = ((value - 50) * 4) / 100;
    } else {
      color1 = c5;
      color2 = c4;
      w1 = ((value - 75) * 4) / 100;
    }

    let w2 = 1 - w1;
    let rgb = [
      Math.round(color1[0] * w1 + color2[0] * w2),
      Math.round(color1[1] * w1 + color2[1] * w2),
      Math.round(color1[2] * w1 + color2[2] * w2),
    ];
    return rgb;
  };

  const onSlide = (render, handle, value, un, percent) => {
    let slideVal = value[0];

    let energyLevel;

    if (slideVal <= 10) {
      energyLevel = "1";
    } else if (slideVal <= 20) {
      energyLevel = "2";
    } else if (slideVal <= 30) {
      energyLevel = "3";
    } else if (slideVal <= 40) {
      energyLevel = "4";
    } else if (slideVal <= 50) {
      energyLevel = "5";
    } else if (slideVal <= 60) {
      energyLevel = "6";
    } else if (slideVal <= 70) {
      energyLevel = "7";
    } else if (slideVal <= 80) {
      energyLevel = "8";
    } else if (slideVal <= 90) {
      energyLevel = "9";
    } else {
      energyLevel = "10";
    }

    let rgb = calculateRGB(slideVal);

    setTextValue(energyLevel);
    setStyles({ color: `rgb(${rgb})` });
    setLabelstyles({ left: percent[0] + "%" });

    props.onSetValue({ id: props.questionid, value: energyLevel });
  };

  return (
    <div id={props.questionid} className={"req question_block" + props.width}>
      <h3>{props.questionTitle}</h3>
      {props.questionTitleSuffix && (
        <span className="titleSuffix">{props.questionTitleSuffix}</span>
      )}
      {props.modalText && (
        <ModalTrigger
          modalText={props.modalText}
          onOpenModal={props.onOpenModal}
        />
      )}
      <div className="answer_block">
        <div className="energySlider" id={props.questionid}>
          <div className="leftLabel">{props.leftlabel}</div>
          <div className="rightLabel">{props.rightlabel}</div>
          <Nouislider
            id="energy_slider"
            range={{ min: 0, max: 100 }}
            start={startValue}
            connect="upper"
            onSlide={onSlide}
          />
          <div
            className="valueHandle"
            id={props.questionid + "_value_handle"}
            style={labelstyles}
          >
            {textValue}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnergySlider;
