import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CircleGraph from "../../components/graphs/circleGraph/CircleGraph";
import EnergyGraph from "../../components/graphs/energyGraph/EnergyGraph";
import BarChart from "../../components/graphs/barChart/BarChart";
import TopSixGraph from "../../components/graphs/topSixGraph/TopSixGraph";

function Demographics(props) {
  const { t } = useTranslation();
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const energy_levels = [
    { date: "1-2", value: props.data.energy_calc_2, height: "0%" },
    { date: "3-4", value: props.data.energy_calc_4, height: "0%" },
    { date: "5-6", value: props.data.energy_calc_6, height: "0%" },
    { date: "7-8", value: props.data.energy_calc_8, height: "0%" },
    { date: "9-10", value: props.data.energy_calc_10, height: "0%" },
  ];
  const productivity_levels = [
    { date: "< 2", value: props.data.productivity_2, height: "0%" },
    { date: "2-4", value: props.data.productivity_4, height: "0%" },
    { date: "5-6", value: props.data.productivity_6, height: "0%" },
    { date: "7-8", value: props.data.productivity_8, height: "0%" },
    { date: "9-10", value: props.data.productivity_10, height: "0%" },
  ];

  let mprod = props.data.most_energetic.split(".");
  const most_energetic =
    mprod[0] +
    ":" +
    Math.round((mprod[1] / 100) * 60)
      .toString()
      .padStart(2, "0");
  let lprod = props.data.least_energetic.split(".");
  const least_energetic =
    lprod[0] +
    ":" +
    Math.round((lprod[1] / 100) * 60)
      .toString()
      .padStart(2, "0");

  return (
    <React.Fragment>
      {/* <EnergyGraph
        title="Average Energy Optimisation"
        value={energy_levels_av}
        wide
        colour={energy_levels_colour}
        modalText="This is the average Energy Levels score for employees in your organisation.
              <br/><br/>
              Champion Health is the only workplace health company worldwide providing this tool.
              <br/><br/>    
              The Energy Calculator provides unique insight into the health of your workforce, showing the extent to which your employees are optimising their energy levels.
              <br/><br/>
              The energy score is calculated through a complex algorithm, taking into consideration every area of health and wellbeing covered in this report."
        onOpenModal={props.onOpenModal}
      /> */}
      <BarChart
        title={t("EG001")}
        data={energy_levels}
        unit="%"
        modalText={t("EG002")}
        onOpenModal={props.onOpenModal}
      />
      {/* <CircleGraph
        title="High Risk of Burnout"
        square
        colour="colour1"
        value={props.data.burnout_ec_30_no}
        large
        percent={props.data.burnout_ec_30}
        modalText="This result shows the % of employees that are at high risk of burnout.<br></br>Burnout is a state of emotional, physical, and mental exhaustion.<br></br>Employees scoring less than 40% on The Energy Calculator are at risk of burnout. However, by following their action plans, using the platform and addressing their suggested areas of health, they can reduce their risk of burnout significantly."
        onOpenModal={props.onOpenModal}
      /> */}
      <CircleGraph
        title={t("EG003")}
        subTitle={t("EG004")}
        square
        value={most_energetic}
        large
        modalText={t("EG005")}
        onOpenModal={props.onOpenModal}
      />
      <CircleGraph
        title={t("EG006")}
        subTitle={t("EG007")}
        square
        value={least_energetic}
        large
        modalText={t("EG008")}
        onOpenModal={props.onOpenModal}
      />
      <TopSixGraph
        title={t("EG009")}
        preText={t("EG010")}
        square
        modalText={t("EG011")}
        onOpenModal={props.onOpenModal}
        data={props.data.negative_productivity}
      />
      <CircleGraph
        title={t("EG012")}
        square
        colour="colour2"
        value={props.data.no_negative_productivity_no}
        subText=""
        large
        percent={props.data.no_negative_productivity}
      />
      <CircleGraph
        title={t("EG013")}
        square
        colour="colour3"
        value={props.data.average_productivity}
        //percent={props.data.average_productivity * 10}
        subText={t("EG014")}
        large
        modalText={t("EG015")}
        onOpenModal={props.onOpenModal}
      />
      <BarChart
        title={t("EG016")}
        subTitle={t("EG017")}
        data={productivity_levels}
        unit="%"
        width="wide"
        startZero
        modalText={t("EG018")}
        onOpenModal={props.onOpenModal}
      />
    </React.Fragment>
  );
}
export default Demographics;
