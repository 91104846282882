import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "../styles/componentStyles/Journal.css";

const Journal = (props) => {
  const { t } = useTranslation();
  const [text, setText] = useState();

  useEffect(() => {
    if (props.data?.[props.questionid]) {
      setText(props.data[props.questionid]);
    } else {
      setText("");
    }
  }, [props.data?.[props.questionid]]);

  const handleChange = (e) => {
    props.saveResponse("journal", {
      id: props.questionid,
      value: e.target.value,
    });
  };

  return (
    <React.Fragment>
      {" "}
      <div className="title ">
        {t("T018")}
        <p>{t("T019")}</p>
      </div>
      <textarea
        className={props.alcohol ? "journal-input alc" : "journal-input"}
        placeholder={props.placeholder}
        // maxLength="650"
        onChange={handleChange}
        value={text}
      ></textarea>
    </React.Fragment>
  );
};
export default Journal;
