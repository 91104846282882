import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CircleGraph from "../../components/graphs/circleGraph/CircleGraph";
import BarChart from "../../components/graphs/barChart/BarChart";

function Demographics(props) {
  const { t } = useTranslation();
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const treatment = [
    {
      date: t("PHL001"),
      value: props.data.seeking_treatment_yes,
      height: "0%",
    },
    { date: t("PHL002"), value: props.data.seeking_treatment_no, height: "0%" },
  ];

  const physical_health = [
    {
      date: t("PHL003"),
      value: props.data.physical_productivity,
      height: "0%",
    },
    {
      date: t("PHL004"),
      value: props.data.physical_productivity_hayfever,
      height: "0%",
    },
    {
      date: t("PHL005"),
      value: props.data.physical_productivity_headaches,
      height: "0%",
    },
    {
      date: t("PHL006"),
      value: props.data.physical_productivity_female_health,
      height: "0%",
    },
  ];

  return (
    <React.Fragment>
      <CircleGraph
        title={t("PHL007")}
        subTitle=""
        square
        colour="colour1"
        value={props.data.lthc_yes_no}
        large
        percent={props.data.lthc_yes}
        modalText={t("PHL008")}
        onOpenModal={props.onOpenModal}
      />
      <BarChart
        title={t("PHL009")}
        subtitle={t("PHL010")}
        data={treatment}
        unit="%"
        modalText={t("PHL011")}
        onOpenModal={props.onOpenModal}
      />
      <CircleGraph
        title={t("PHL012")}
        square
        colour="colour2"
        value={props.data.eye_strain_no}
        large
        percent={props.data.eye_strain}
        modalText={t("PHL013")}
        onOpenModal={props.onOpenModal}
      />
      <CircleGraph
        title={t("PHL014")}
        subtitle={t("PHL015")}
        square
        colour="colour3"
        value={props.data.colds_flu_no}
        large
        percent={props.data.colds_flu}
        modalText={t("PHL016")}
        onOpenModal={props.onOpenModal}
      />
      {!props.activeFilter && !props.isLoading && (
        <CircleGraph
          title={t("PHL017")}
          square
          colour="colour1"
          value={props.data.menopause_no}
          large
          percent={props.data.menopause}
          modalText={t("PHL018")}
          onOpenModal={props.onOpenModal}
        />
      )}
      <CircleGraph
        title={t("PHL019")}
        subTitle={t("PHL020")}
        square
        colour="colour2"
        value={props.data.absence}
        subText={t("PHL021")}
        large
        modalText={t("PHL022")}
        onOpenModal={props.onOpenModal}
      />
      <BarChart
        title={t("PHL023")}
        data={physical_health}
        width="wide"
        unit="%"
        modalText={t("PHL024")}
        onOpenModal={props.onOpenModal}
      />
      <CircleGraph
        title={t("PHL025")}
        square
        colour="colour3"
        value={props.data.bmi_healthy_no}
        large
        percent={props.data.bmi_healthy}
        modalText={t("PHL026")}
        onOpenModal={props.onOpenModal}
      />
    </React.Fragment>
  );
}
export default Demographics;
