import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CircleGraph from "../../components/graphs/circleGraph/CircleGraph";
import TopThree from "../../components/graphs/topThree/TopThree";
import BarChart from "../../components/graphs/barChart/BarChart";
import Radar from "../../components/graphs/radarGraph/RadarGraph";
import ReportBox from "../../components/ReportBox";

function Demographics(props) {
  const { t } = useTranslation();
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const anxiety_radar_labels = [
    t("MW001"),
    [t("MW002"), t("MW003")],
    t("MW004"),
    [t("MW005"), t("MW006")],
    t("MW007"),
    t("MW008"),
    t("MW009"),
  ];
  const anxiety_radar_values = [
    props.data.nervous,
    props.data.control_worrying,
    props.data.trouble_relaxing,
    props.data.worrying_too_much,
    props.data.restless,
    props.data.annoyed,
    props.data.afraid,
  ];

  const depression_radar_labels = [
    t("MW010"),
    t("MW011"),
    [t("MW012"), t("MW013")],
    t("MW014"),
    t("MW015"),
    [t("MW016"), t("MW017")],
    [t("MW018"), t("MW019")],
    t("MW020"),
  ];
  const depression_radar_values = [
    props.data.little_interest,
    props.data.feeling_down,
    props.data.trouble_falling_asleep,
    props.data.feeling_tired,
    props.data.poor_appetite,
    props.data.feeling_bad,
    props.data.trouble_concentrating,
    props.data.slow,
  ];

  // const wellbeing = [
  //   { date: "Low", value: props.data.low_wellbeing, height: "0%" },
  //   { date: "Moderate", value: props.data.moderate_wellbeing, height: "0%" },
  //   { date: "High", value: props.data.high_wellbeing, height: "0%" },
  // ];

  const anxiety = [
    { date: t("MW021"), value: props.data.anxiety_level_none, height: "0%" },
    { date: t("MW022"), value: props.data.anxiety_level_mild, height: "0%" },
    {
      date: t("MW023"),
      value: props.data.anxiety_level_moderate,
      height: "0%",
    },
    { date: t("MW024"), value: props.data.anxiety_level_severe, height: "0%" },
  ];

  const depression = [
    { date: t("MW025"), value: props.data.depression_level_none, height: "0%" },
    { date: t("MW026"), value: props.data.depression_level_mild, height: "0%" },
    {
      date: t("MW027"),
      value: props.data.depression_level_moderate,
      height: "0%",
    },
    {
      date: t("MW028"),
      value: props.data.depression_level_moderate_severe,
      height: "0%",
    },
    {
      date: t("MW029"),
      value: props.data.depression_level_severe,
      height: "0%",
    },
  ];

  const mental_diagnosis = [
    { date: t("MW030"), value: props.data.mh_diagnosis_current, height: "0%" },
    { date: t("MW031"), value: props.data.mh_diagnosis_previous, height: "0%" },
    { date: t("MW032"), value: props.data.mh_diagnosis_none, height: "0%" },
  ];

  const mental_treatment = [
    {
      date: t("MW033"),
      value: props.data.received_mental_support_currently,
      height: "0%",
    },
    {
      date: t("MW034"),
      value: props.data.received_mental_support_previously,
      height: "0%",
    },
    {
      date: t("MW035"),
      value: props.data.received_mental_support_never,
      height: "0%",
    },
  ];

  return (
    <React.Fragment>
      {/* <BarChart
        title="Employee Wellbeing"
        subTitle="Measured by the Warwick-Edinburgh Mental Wellbeing Scale"
        data={wellbeing}
        unit="%"
        modalText="This result shows the % breakdown of employee wellbeing over the last 2 weeks, as measured by the Warwick-Edinburgh Wellbeing Scale.<br></br>This is a validated questionnaire that looks at positive aspects of wellbeing, such as feelings of optimism about the future and closeness to others."
        onOpenModal={props.onOpenModal}
        width="wide"
      /> */}
      {/* <CircleGraph
        title="Average Wellbeing Score"
        square
        colour="colour1"
        value={props.data.wellbeing_score}
        large
        modalText="This result shows the average wellbeing score over the last 2 weeks, as measured by the Warwick-Edinburgh Wellbeing Scale.<br></br>This is a validated questionnaire that looks at positive aspects of wellbeing, such as feelings of optimism about the future and closeness to others. The average score in the general population is 23, indicating a moderate level of wellbeing. How does your organisation compare?"
        onOpenModal={props.onOpenModal}
      /> */}

      <Radar
        title={t("MW036")}
        subTitle={t("MW037")}
        labels={anxiety_radar_labels}
        values={anxiety_radar_values}
        wide
        modalText={t("MW038")}
        onOpenModal={props.onOpenModal}
      />

      <div className="restricted_height">
        <BarChart
          title={t("MW039")}
          data={anxiety}
          unit="%"
          modalText={t("MW040")}
          onOpenModal={props.onOpenModal}
        />

        <CircleGraph
          title={t("MW041")}
          square
          colour="colour2"
          value={
            Number(props.data.anxiety_level_severe_no) +
            Number(props.data.anxiety_level_moderate_no)
          }
          large
          percent={Math.round(
            (100 / props.data.total) *
              (Number(props.data.anxiety_level_severe_no) +
                Number(props.data.anxiety_level_moderate_no))
          )}
          onOpenModal={props.onOpenModal}
        />
      </div>

      <Radar
        title={t("MW042")}
        subTitle={t("MW043")}
        labels={depression_radar_labels}
        values={depression_radar_values}
        wide
        modalText={t("MW044")}
        onOpenModal={props.onOpenModal}
      />
      <div className="restricted_height">
        <BarChart
          title={t("MW045")}
          data={depression}
          unit="%"
          modalText={t("MW046")}
          onOpenModal={props.onOpenModal}
        />
        <CircleGraph
          title={t("MW047")}
          square
          colour="colour3"
          value={
            props.data.depression_level_moderate_no +
            props.data.depression_level_moderate_severe_no +
            props.data.depression_level_severe_no
          }
          large
          percent={Math.round(
            (100 / props.data.total) *
              (props.data.depression_level_moderate_no +
                props.data.depression_level_moderate_severe_no +
                props.data.depression_level_severe_no)
          )}
          onOpenModal={props.onOpenModal}
        />
      </div>
      {/* <CircleGraph
        title="Resilience"
        subTitle="Employees dealing with problems well"
        square
        colour="colour1"
        value={props.data.dealing_problems_well_no}
        large
        percent={props.data.dealing_problems_well}
        modalText="This result shows the % of employees who feel they have been dealing with problems well over the last 2 weeks.<br/><br/>This data is taken from the Warwick-Edinburgh Wellbeing Scale and offers insight into the resilience of your workforce.<br/><br/>Resilience is more than an ability to bounce back from difficult situations - it's the ability to not get knocked down in the first place and take problems in your stride.<br/><br/>Resilience is vital for a healthy and productive workforce."
        onOpenModal={props.onOpenModal}
      /> */}

      {!props.activeFilter && !props.isLoading && (
        <CircleGraph
          title={t("MW048")}
          square
          colour="colour3"
          value={props.data.phq9_no}
          large
          percent={props.data.phq9}
          modalText={t("MW049")}
          onOpenModal={props.onOpenModal}
        />
      )}

      {!props.activeFilter && !props.isLoading && (
        <BarChart
          title={t("MW050")}
          data={mental_diagnosis}
          unit="%"
          modalText={t("MW051")}
          onOpenModal={props.onOpenModal}
        />
      )}
      {!props.activeFilter && !props.isLoading && (
        <TopThree
          title={t("MW052")}
          square
          preText={t("MW053")}
          modalText={t("MW054")}
          onOpenModal={props.onOpenModal}
          value={props.data.current_mental_health_conditions}
        />
      )}
      {!props.activeFilter && !props.isLoading && (
        <BarChart
          title={t("MW055")}
          data={mental_treatment}
          unit="%"
          startZero
          width="wide"
          modalText={t("MW056")}
          onOpenModal={props.onOpenModal}
        />
      )}
      <CircleGraph
        title={t("MW057")}
        square
        colour="colour1"
        value={props.data.productivity_mental_health_no}
        subText=""
        large
        percent={props.data.productivity_mental_health}
        modalText={t("MW058")}
        onOpenModal={props.onOpenModal}
      />
    </React.Fragment>
  );
}
export default Demographics;
