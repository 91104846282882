import React, { useEffect, useRef, useState } from "react";
import "../styles/componentStyles/Journal.css";
import { useTranslation } from "react-i18next";

import { colours } from "../../assets/colours.js";

import { formatAssessmentDate } from "../../shared/util/helperfunctions";
import { inh_and_enh } from "../../shared/components/data";
import GreyButton from "../../shared/components/styledComponents/greyButton/GreyButton";

const JournalBlock = (props) => {
  const { t } = useTranslation();
  const [showEntries, setShowEntries] = useState([]);
  const [journals, setJournals] = useState();
  const [isTextClipped, setIsTextClipped] = useState(false);
  const textRefs = useRef([]);

  const getText = (id) => {
    let textLine = inh_and_enh.filter((el) => id === el.id);
    textLine = textLine[0].label;
    return textLine;
  };

  useEffect(() => {
    if (props.journalArr) {
      let cleanArr = props.journalArr.filter((entries) => entries.journal);
      setJournals(cleanArr);
    }
  }, [props.journalArr]);

  useEffect(() => {
    if (props.startWith) {
      setShowEntries(props.startWith);
    }
  }, [props.startWith]);

  useEffect(() => {
    const journals = document.getElementsByClassName("journal-content");
    if (journals && journals.length > 0) {
      setIsTextClipped(
        textRefs.current.map((textRef, index) => {
          if (textRef && textRef.scrollHeight > textRef.clientHeight) {
            journals[index].classList.add("clipped");
            return true;
          }
          return false;
        })
      );
    }
  }, [props.journalArr, journals?.length, showEntries]);

  const [showEnhancers, setShowEnhancers] = useState(null);
  const [showInhibitors, setShowInhibitors] = useState(false);

  const handleShowEnhancers = (date) => {
    setShowEnhancers(date);
  };

  const handleShowInhibitors = (date) => {
    setShowInhibitors(date);
  };

  const handleViewMore = () => {
    setShowEntries(() => showEntries + 4);
  };

  return (
    <>
      {journals &&
        journals.map((entry, index) => {
          if (index < showEntries) {
            return (
              <div
                key={index}
                className={"chart-container tile " + props.width}
              >
                <div className="title-container">
                  <div className="title">
                    {t("D096")}
                    <div className="lines-container">
                      {props.mentalWellbeing && (
                        <div className="mw-subt">{entry.value}</div>
                      )}
                      {entry.enhancers?.length > 0 && (
                        <>
                          <div
                            className="line"
                            style={{ backgroundColor: `${colours.green}` }}
                            onMouseEnter={() => handleShowEnhancers(entry.date)}
                            onMouseLeave={() => setShowEnhancers(null)}
                          >
                            {showEnhancers && showEnhancers === entry.date && (
                              <div className="journal-tooltip">
                                <p>
                                  {props.mentalWellbeing
                                    ? "EMOTIONS"
                                    : t("D098")}
                                </p>
                                {entry.enhancers.map((item, indexX) => {
                                  return (
                                    <div key={indexX}>{t(getText(item))}</div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                      {entry.inhibitors?.length > 0 && (
                        <div
                          className="line"
                          style={{ backgroundColor: `${colours.red}` }}
                          onMouseEnter={() => handleShowInhibitors(entry.date)}
                          onMouseLeave={() => setShowInhibitors(null)}
                        >
                          {" "}
                          {showInhibitors && showInhibitors === entry.date && (
                            <div className="journal-tooltip">
                              <p>
                                {props.mentalWellbeing
                                  ? "STRESSORS"
                                  : t("D097")}
                              </p>
                              {entry.inhibitors.map((item, indexX) => {
                                return (
                                  <div key={indexX}>{t(getText(item))}</div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="date-box" style={{ color: `#fff` }}>
                    {formatAssessmentDate(entry.date.slice(0, 10)).slice(0, 6)}
                  </div>
                </div>
                <div
                  ref={(el) => (textRefs.current[index] = el)}
                  className="journal-content"
                >
                  {entry.journal}{" "}
                </div>
                {isTextClipped[index] && (
                  <span
                    onClick={() =>
                      props.openModal(
                        `${t("D096")} - ${formatAssessmentDate(
                          entry.date
                        ).slice(0, -5)}`,
                        [entry.journal]
                      )
                    }
                  >
                    Read more
                  </span>
                )}
              </div>
            );
          }
        })}
      <div className="journal-button-container">
        {journals?.length > showEntries && (
          <GreyButton className={"download caps"} onClick={handleViewMore}>
            Load more
          </GreyButton>
        )}
      </div>
    </>
  );
};

export default JournalBlock;
