import { useTranslation } from "react-i18next";

import {
  faHourglassHalf,
  faLightbulbOn,
} from "@fortawesome/pro-regular-svg-icons";

import BarChart from "../components/BarChart";
import Slider from "../../shared/components/slider/Slider";

import { colours } from "../../assets/colours";
import MotivationTile from "../components/MotivationTile";
import { enhancersProd, inhibitorsProd } from "../../shared/components/data";
import JournalBlock from "../components/JournalBlock";

const ProductivityResults = (props) => {
  const { t } = useTranslation();
  const { assessScores, trackerScores, trackerJournals } = props.dataObj;
  const { assessDates, assessmentNumber } = props.pageObjRef?.current;

  return (
    <div className="results-container">
      {props.showAssessment && assessScores?.productivity_score_data && (
        <div className="results-row ">
          <BarChart
            title={t("D100")}
            average={assessScores.productivity_score_data_average}
            data={assessScores.productivity_score_data}
            icon={faLightbulbOn}
            units={t("D035")}
            average_type={t("D079")}
            yMaxLimit={10}
            width="half bars"
            color={colours.yellow}
            modalText={[t("D105"), t("D106"), t("D107")]}
            openModal={props.openModal}
            assessmentNumber={assessmentNumber}
            assessDates={assessDates}
            outOfYMax
          />
          <BarChart
            title={t("D101")}
            icon={faHourglassHalf}
            average={assessScores.hours_worked_data_average}
            data={assessScores.hours_worked_data}
            units={t("D113")}
            average_type={t("D079")}
            width="half bars"
            color={colours.blue}
            modalText={[t("D108")]}
            openModal={props.openModal}
            assessmentNumber={assessmentNumber}
            assessDates={assessDates}
            hoursWorked
          />
        </div>
      )}
      {!props.showAssessment &&
        trackerScores?.productivity?.productivity.length > 0 && (
          <div className="results-row ">
            <BarChart //productivity score
              title={t("D100")}
              average={trackerScores.productivity.productivity_average}
              data={trackerScores.productivity.productivity}
              icon={faLightbulbOn}
              units={t("D035")}
              yMaxLimit={10}
              average_type={t("D079")}
              width="half bars"
              color={colours.yellow}
              modalText={[t("D109"), t("D106"), t("D110")]}
              openModal={props.openModal}
              outOfYMax
            />
            <MotivationTile //productivity enhancers
              title={t("D103")}
              colour={colours.green}
              data={trackerScores.productivity.topEnhancers}
              valueArr={enhancersProd}
              width="quarter"
              modalText={[t("D111")]}
              openModal={props.openModal}
            />
            <MotivationTile //productivity inhibitors
              title={t("D104")}
              colour={colours.red}
              data={trackerScores.productivity.topInhibitors}
              valueArr={inhibitorsProd}
              width="quarter"
              modalText={[t("D112")]}
              openModal={props.openModal}
            />
            <JournalBlock
              width="quarter"
              startWith={8}
              journalArr={trackerJournals}
              openModal={props.openModal}
            />
          </div>
        )}

      {/* {props.slidersList?.[1] && (
            <div className="results-row">
              <Slider
                title={props.slidersList[1].title}
                key={props.slidersList[1].id}
                id={props.slidersList[1].id}
                media={props.slidersList[1].media}
                favourites={favourites}
                dislikes={dislikes}
                progressList={progressList}
                onOpenMediaModal={openMediaModal}
                addLikeDislike={addLikeDislike}
                target={props.slidersList[1].target || false}
              />
            </div>
          )} */}
    </div>
  );
};
export default ProductivityResults;
