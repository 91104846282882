import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";

import ContentItem from "./ContentItem";
import "./Style.css";
import { Swiper, SwiperSlide } from "swiper/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight } from "@fortawesome/pro-light-svg-icons";

import SwiperCore, {
  Virtual,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
  Parallax,
  Zoom,
  Lazy,
  Controller,
  A11y,
  History,
  HashNavigation,
  Autoplay,
  EffectFade,
  EffectCube,
  EffectFlip,
  EffectCoverflow,
  Thumbs,
} from "swiper";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

const Slider = (props) => {
  // install Virtual module
  SwiperCore.use([
    Virtual,
    Keyboard,
    Mousewheel,
    Navigation,
    Pagination,
    Scrollbar,
    Parallax,
    Zoom,
    Lazy,
    Controller,
    A11y,
    History,
    HashNavigation,
    Autoplay,
    EffectFade,
    EffectCube,
    EffectFlip,
    EffectCoverflow,
    Thumbs,
  ]);
  const slidesList = props.media;
  let sliderLength = props.promo ? 4 : 5;
  if (props.sliderLength) {
    sliderLength = props.sliderLength;
  }
  let loop = true;
  let centeredSlides = false;
  let allowNext = true;
  let allowPrev = true;
  let spaceBetween = 5;
  let pagination = { clickable: true };
  // fix for short sliders
  if (
    (slidesList && slidesList.length < 5) ||
    slidesList.length <= sliderLength
  ) {
    // loop = false;
    allowNext = false;
    allowPrev = false;
    pagination = false;
  }

  if (window.innerWidth < 1050) {
    !props.actionPlan ? (sliderLength = 4) : (sliderLength = 3);
  }
  if (window.innerWidth < 800) {
    !props.actionPlan ? (sliderLength = 3) : (sliderLength = 2);
  }
  if (window.innerWidth < 600) {
    sliderLength = 2;
  }
  if (props.target && props.title) {
    pagination = false;
  }

  const swlength = sliderLength || 5;
  const swiperRef = useRef(null);
  const allowSlideNext = allowNext;
  const allowSlidePrev = allowPrev;
  const sliderPagination = pagination;

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.update();
      if (
        document.getElementsByClassName(
          "swiper-slide content_slider standard"
        )[0]
      ) {
        let width = document.getElementsByClassName(
          "swiper-slide content_slider standard"
        )[0].offsetWidth;
        let elements = document.getElementsByClassName(
          "swiper content_slider standard"
        );
        if (elements.length > 0) {
          let i;
          for (i = 0; i < elements.length; i++) {
            elements[i].style.height = width + "px";
          }
        }
      }

      if (
        document.getElementsByClassName("swiper-slide content_slider tall")[0]
      ) {
        let width = document.getElementsByClassName(
          "swiper-slide content_slider tall"
        )[0].offsetWidth;
        // alert(width);
        let elements = document.getElementsByClassName(
          "swiper content_slider tall"
        );
        if (elements.length > 0) {
          let i;
          for (i = 0; i < elements.length; i++) {
            elements[i].style.height = Number(width) * 1.3 + "px";
          }
        }
      }
    }
  }, [slidesList]);

  function sliderPosChange() {
    if (props.onChangeSliderPos) {
      let last = swiperRef.current.swiper.activeIndex - 1;
      props.onChangeSliderPos({ id: props.id, pos: last });
    }
  }

  let randoId = "sw" + Math.floor(Math.random() * 90000) + 10000;
  let currentpos = props.currentpos || 0;

  return (
    <React.Fragment>
      {slidesList && (
        <div
          className={`glowrm_slider grid-item-extra-wide ${
            slidesList[0].chid.startsWith("TA") ? "green" : ""
          }`}
          id={props.id}
        >
          <React.Fragment>
            {props.title && <h1>{props.title.replace(/&amp;/gi, "&")}</h1>}
            {/* {props.title && props.target && (
              <NavLink to={"/" + props.target} className="cta">
                View All
                <FontAwesomeIcon
                  icon={faCircleChevronRight}
                  className="icon return-arrow"
                />
              </NavLink>
            )} */}
            {slidesList.length > sliderLength && (
              <React.Fragment>
                <div
                  className={"swiper-button-prev " + "prev"}
                  id={randoId + "-prev"}
                ></div>
                <div
                  className={"swiper-button-next " + "next"}
                  id={randoId + "-next"}
                ></div>
              </React.Fragment>
            )}

            {/* {slidesList.length <= sliderLength && (
              <React.Fragment>
                <div
                  className={
                    "swiper-button-prev " + "prev swiper-button-disabled"
                  }
                  id={randoId + "-prev"}
                ></div>
                <div
                  className={
                    "swiper-button-next " + "next swiper-button-disabled"
                  }
                  id={randoId + "-next"}
                ></div>
              </React.Fragment>
            )} */}
            <Swiper
              ref={swiperRef}
              spaceBetween={spaceBetween}
              slidesPerView={swlength}
              slidesPerGroup={swlength}
              loop={loop}
              centeredSlides={centeredSlides}
              loopFillGroupWithBlank={true}
              allowSlideNext={allowSlideNext}
              allowSlidePrev={allowSlidePrev}
              navigation={{
                nextEl: "#" + randoId + "-next",
                prevEl: "#" + randoId + "-prev",
              }}
              pagination={sliderPagination}
              className={
                props.promo ? "content_slider tall" : "content_slider standard"
              }
              initialSlide={currentpos}
              onSlideChange={() => sliderPosChange()}
              observer={true}
              simulateTouch={false}
            >
              {slidesList.map((slideContent, index) => {
                if (
                  slideContent !== null &&
                  ((props.dislikes &&
                    !props.dislikes.includes(slideContent._id)) ||
                    !props.dislikes)
                ) {
                  return (
                    <SwiperSlide
                      key={randoId + slideContent._id}
                      virtualIndex={index}
                      className={
                        slideContent?.chid.startsWith("TA")
                          ? "content_slider standard green"
                          : props.promo
                          ? "content_slider tall"
                          : "content_slider standard"
                      }
                    >
                      <ContentItem
                        key={slideContent._id}
                        slideContent={slideContent}
                        // onOpenMediaModal={props.onOpenMediaModal}
                        // addLikeDislike={props.addLikeDislike}
                        // onAddFavourite={props.onAddFavourite}
                        // updateFavouritesSlider={props.updateFavouritesSlider}
                        promo={props.promo}
                      />
                    </SwiperSlide>
                  );
                }
              })}
            </Swiper>
          </React.Fragment>
        </div>
      )}
    </React.Fragment>
  );
};
export default Slider;
