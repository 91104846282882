import "../styles/componentStyles/MotivationTile.css";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";

const MotivationTile = (props) => {
  const { t } = useTranslation();
  return (
    <div
      className={"chart-container tile " + props.width}
      style={{
        borderColor: `${
          props.colour ? props.colour : "rgba(98, 90, 106, 0.95)"
        }`,
      }}
    >
      <div className="title-container">
        <div
          className="title"
          style={{
            color: `${props.colour ? props.colour : "#fff"}`,
          }}
        >
          {props.title}
        </div>
        <div className="wtf">
          <FontAwesomeIcon
            icon={faQuestionCircle}
            className="wtf icon"
            onClick={() => props.openModal(props.title, props.modalText)}
          />
        </div>
      </div>
      <div className="tile-content motivation">
        <p>{t("DW027")}</p>
        {props.valueArr?.length > 0 &&
          props.data?.map((item, index) => {
            if (index < 3) {
              let reason = props.valueArr.find((e) => e.id === item);
              return <div key={index}>{t(reason.label)}</div>;
            } else {
              return "";
            }
          })}
        {props.data?.length < 1 && <div className="empty">{t("D075")}</div>}
      </div>
    </div>
  );
};

export default MotivationTile;
