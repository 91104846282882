import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CircleGraph from "../../components/graphs/circleGraph/CircleGraph";
import TopSixGraph from "../../components/graphs/topSixGraph/TopSixGraph";
import TopThreeGraph from "../../components/graphs/topThreeGraph/TopThreeGraph";
import BarChart from "../../components/graphs/barChart/BarChart";

function Demographics(props) {
  const { t } = useTranslation();
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <React.Fragment>
      {/* <CircleGraph
        title="Motivated to Change"
        square
        colour="colour2"
        value={props.data.no_areas_to_improve_no}
        large
        percent={props.data.no_areas_to_improve}
        modalText="<p>This result shows the % of employees that want to make changes to their wellbeing.</p><p>Implementing workplace initiatives is significantly easier when a higher proportion of employees want to make a change. However, it's often those who are  most in need that don't want to make changes to their health. This is where soft behavioural change techniques are most effective.</p><p>In a workforce that's resistant to making changes, avoid using 'smack' techniques (e.g. smoking bans). This will lead to greater resistance and little-to-no change will occur. Look to use nudge techniques instead, which gently prompt healthy changes (e.g. organise branded water bottles to improve hydration levels).</p>"
        onOpenModal={props.onOpenModal}
      /> */}
      <TopSixGraph
        title={t("BC001")}
        preText={t("BC002")}
        square
        modalText={t("BC003")}
        onOpenModal={props.onOpenModal}
        data={props.data.negative_productivity}
      />
      <TopSixGraph
        title={t("BC004")}
        square
        preText={t("BC005")}
        data={props.data.areas_to_change}
        modalText={t("BC006")}
        onOpenModal={props.onOpenModal}
      />
      <TopSixGraph
        title={t("BC007")}
        square
        preText={t("BC008")}
        data={props.data.barriers_to_change}
        modalText={t("BC009")}
        onOpenModal={props.onOpenModal}
      />
    </React.Fragment>
  );
}
export default Demographics;
