import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../styles/componentStyles/ValueTile.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt, faCheck, faTimes } from "@fortawesome/pro-regular-svg-icons";
import {
  faArrowPointer,
  faCircleX,
  faShieldCheck,
  faQuestionCircle,
} from "@fortawesome/pro-solid-svg-icons";

const ValueTile = (props) => {
  const { t } = useTranslation();
  return (
    <div className={"chart-container tile " + props.width}>
      <div className="title-container empty">
        <div className="wtf">
          {props.modalText && (
            <FontAwesomeIcon
              icon={faQuestionCircle}
              className="wtf icon"
              onClick={() => props.openModal(props.title, props.modalText)}
            />
          )}
        </div>
      </div>
      <div className="tile-content">
        <div className="tile-value" style={{ color: `${props.colour}` }}>
          {props.value}
        </div>
        <div className="title descr">{props.title}</div>
        {props.date && (
          <p>
            {t("VT001")} <b>{props.date}</b>
          </p>
        )}
      </div>
    </div>
  );
};

export default ValueTile;
