import React, { useEffect, useState } from "react";
import "./Style.css";

import ModalTrigger from "../../../shared/modal/ModalTrigger";

function Dehydration(props) {
  var rows = [];
  for (var i = 0; i < 8; i++) {
    var k = i + 1;
    rows.push({ index: i, value: k });
  }

  const [radioOption, setRadioOption] = useState();

  function saveValue(event) {
    const element = event.currentTarget.querySelector("input");
    if (!element) return;
    const value = element?.value;
    if (!value) return;
    setRadioOption(value);
    props.onSetValue({ id: props.questionid, value });
  }

  useEffect(() => {
    if (props.assessmentValues[props.questionid] && !radioOption) {
      setRadioOption(props.assessmentValues[props.questionid]);
    }
  });

  return (
    <div id={props.questionid} className={"req question_block" + props.width}>
      <h3>{props.questionTitle}</h3>
      {props.questionTitleSuffix ? (
        <span className="titleSuffix">{props.questionTitleSuffix}</span>
      ) : null}
      {props.modalText && (
        <ModalTrigger
          modalText={props.modalText}
          onOpenModal={props.onOpenModal}
        />
      )}
      <div className="answer_block">
        <div
          className={"dot_radio dehydration " + props.questionid}
          id={props.questionid}
        >
          {rows.map((option) => {
            let checked;
            {
              parseFloat(radioOption) === parseFloat(option.value)
                ? (checked = "checked")
                : (checked = "");
            }
            return (
              <div
                className="input_wrap"
                key={option.index}
                onClick={saveValue}
              >
                <input
                  type="radio"
                  id={props.questionid + option.value}
                  name={props.questionid}
                  value={option.value}
                  onClick={(e) => e.stopPropagation()}
                  readOnly
                  checked={checked}
                />
                <label htmlFor={props.questionid + option.value}></label>
                <br />
                {option.value}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Dehydration;
