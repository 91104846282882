import React, { useState, useEffect } from "react";
import "./Style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/pro-regular-svg-icons";

import ModalTrigger from "../../../shared/modal/ModalTrigger";

function Clock(props) {
  const [hourValue, setHourValue] = useState(props.startHour);
  const [minuteValue, setMinuteValue] = useState(props.startMinute);

  function setIncreaseClockHours() {
    let newHourValue;
    if (hourValue < 23) {
      newHourValue = Number(hourValue) + 1;
    } else {
      newHourValue = 0;
    }
    setHourValue(newHourValue);
    saveValue(newHourValue + ":" + minuteValue);
  }

  function setDecreaseClockHours() {
    let newHourValue = Number(hourValue);
    if (Number(hourValue) > 0) {
      newHourValue = Number(hourValue) - 1;
    } else {
      newHourValue = 23;
    }
    setHourValue(newHourValue);
    saveValue(newHourValue + ":" + minuteValue);
  }

  function setIncreaseClockMinutes() {
    var newMinuteValue = Number(minuteValue);
    if (Number(minuteValue) < 45) {
      newMinuteValue = Number(minuteValue) + 15;
    } else {
      newMinuteValue = "00";
    }
    setMinuteValue(newMinuteValue);
    saveValue(hourValue + ":" + newMinuteValue);
  }

  function setDecreaseClockMinutes() {
    var newMinuteValue = Number(minuteValue);
    if (Number(minuteValue) > 0) {
      newMinuteValue = Number(minuteValue) - 15;
      if (newMinuteValue === 0) {
        newMinuteValue = "00";
      }
    } else {
      newMinuteValue = 45;
    }
    setMinuteValue(newMinuteValue);
    saveValue(hourValue + ":" + newMinuteValue);
  }

  function clockChangeHandler(event) {
    if (event.target.id === "hours") {
      setHourValue(event.target.value);
      saveValue(event.target.value + ":" + minuteValue);
    } else if (event.target.id === "minutes") {
      setMinuteValue(event.target.value);
      saveValue(hourValue + ":" + event.target.value);
    }
  }

  const handleFocusOut = (event) => {
    if (event.target.id === "hours") {
      let newHours = Number(event.target.value);
      if (newHours > 23) {
        newHours = 23;
      }
      if (newHours < 0 || !event.target.value || isNaN(newHours)) {
        newHours = 0;
      }

      setHourValue(newHours);
      saveValue(newHours + ":" + minuteValue);
    } else if (event.target.id === "minutes") {
      let newMins = Number(event.target.value);
      if (newMins > 45) {
        newMins = 45;
      }
      if (newMins < 0 || !event.target.value || isNaN(newMins)) {
        newMins = "00";
      }
      setMinuteValue(newMins);
      saveValue(hourValue + ":" + newMins);
    }
  };

  function saveValue(time) {
    props.onSetValue({ id: props.questionid, value: time });
  }
  useEffect(() => {
    if (props.assessmentValues && props.assessmentValues[props.questionid]) {
      var time = props.assessmentValues[props.questionid];
      var index = time.indexOf(":");
      var hour = time.substr(0, index);
      var minute = time.substr(index + 1);
      setHourValue(hour);
      setMinuteValue(minute);
    } else if (
      props.startMinute === minuteValue &&
      props.startHour === hourValue
    ) {
      saveValue(hourValue + ":" + minuteValue);
    }
  });

  return (
    <div id={props.questionid} className={"question_block" + props.width}>
      <h3>{props.questionTitle}</h3>
      {props.questionTitleSuffix ? (
        <span className="titleSuffix">{props.questionTitleSuffix}</span>
      ) : null}
      {props.modalText && (
        <ModalTrigger
          modalText={props.modalText}
          onOpenModal={props.onOpenModal}
        />
      )}
      <div className="answer_block">
        <div className="clock">
          <div className="clock_padding">
            <input
              type="number"
              className="hours"
              id="hours"
              value={hourValue}
              onChange={clockChangeHandler}
              onBlur={handleFocusOut}
              max="24"
              min="00"
              maxLength={2}
            />
            <div className="number_controls">
              <div className="up_btn" onClick={setIncreaseClockHours}>
                <FontAwesomeIcon icon={faChevronUp} className="icon" />
              </div>
              <div className="down_btn" onClick={setDecreaseClockHours}>
                <FontAwesomeIcon icon={faChevronDown} className="icon" />
              </div>
            </div>
          </div>
          <div className="clock_padding">
            <input
              type="number"
              className="minutes"
              id="minutes"
              value={minuteValue}
              onChange={clockChangeHandler}
              onBlur={handleFocusOut}
              step="15"
              max="45"
              min="00"
              maxLength={2}
            />
            <div className="number_controls">
              <div className="up_btn" onClick={setIncreaseClockMinutes}>
                <FontAwesomeIcon icon={faChevronUp} className="icon" />
              </div>
              <div className="down_btn" onClick={setDecreaseClockMinutes}>
                <FontAwesomeIcon icon={faChevronDown} className="icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Clock;
