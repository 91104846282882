import { useTranslation } from "react-i18next";
import {
  faBowlSpoon,
  faBurgerFries,
  faClock,
  faSalad,
  faShieldCheck,
  faUtensils,
  faWatermelonSlice,
} from "@fortawesome/pro-regular-svg-icons";

import BarChart from "../components/BarChart";
import Slider from "../../shared/components/slider/Slider";

import { colours } from "../../assets/colours";
import LineChart from "../components/LineChart";
import CircleValueTile from "../components/CircleValueTile";
import JournalBlock from "../components/JournalBlock";

const NutritionResults = (props) => {
  const { t } = useTranslation();
  const { assessScores, trackerScores, trackerJournals } = props.dataObj;
  const { assessDates, assessmentNumber } = props.pageObjRef?.current;

  return (
    <div className="results-container">
      {props.showAssessment && assessScores?.first_meal_data && (
        <div className="results-row ">
          <BarChart
            title={t("D166")}
            color={colours.yellow}
            average={assessScores.first_meal_data_average}
            data={assessScores.first_meal_data}
            icon={faBowlSpoon}
            units=""
            average_type={t("D079")}
            yMaxLimit={24}
            width="half bars"
            modalText={[t("D175")]}
            openModal={props.openModal}
            assessmentNumber={assessmentNumber}
            assessDates={assessDates}
            isTime
          />
          <BarChart
            title={t("D167")}
            color={colours.blue}
            average={assessScores.last_meal_data_average}
            data={assessScores.last_meal_data}
            icon={faUtensils}
            units=""
            average_type={t("D079")}
            yMaxLimit={24}
            width="half bars"
            modalText={[t("D176")]}
            openModal={props.openModal}
            assessmentNumber={assessmentNumber}
            assessDates={assessDates}
            isTime
          />
          <LineChart
            title={t("D168")}
            color={colours.red}
            average={assessScores.hours_between_meals_data_average}
            data={assessScores.hours_between_meals_data}
            icon={faClock}
            units={t("D077")}
            average_type={t("D079")}
            width="half bars"
            modalText={[t("D177")]}
            openModal={props.openModal}
            assessmentNumber={assessmentNumber}
            assessDates={assessDates}
          />
          <BarChart
            title={t("D169")}
            color={colours.blue}
            average={assessScores.vegetables_data_average}
            data={assessScores.vegetables_data}
            icon={faSalad}
            units={t("D171")}
            average_type={t("D079")}
            width="half bars"
            modalText={[t("D178"), t("D179"), t("D180")]}
            openModal={props.openModal}
            assessmentNumber={assessmentNumber}
            assessDates={assessDates}
          />
          <BarChart
            title={t("D170")}
            icon={faBurgerFries}
            average={assessScores.takeaways_data_average}
            data={assessScores.takeaways_data}
            units={t("D174")}
            width="half bars"
            average_type={t("D079")}
            color={colours.red}
            modalText={[t("D181")]}
            openModal={props.openModal}
            assessmentNumber={assessmentNumber}
            assessDates={assessDates}
          />
        </div>
      )}
      {!props.showAssessment &&
        trackerScores?.nutrition?.first_meal.length > 0 && (
          <div className="results-row ">
            <BarChart //first meal
              title={t("D166")}
              average={trackerScores.nutrition.first_meal_average}
              data={trackerScores.nutrition.first_meal}
              icon={faBowlSpoon}
              units=""
              yMaxLimit={24}
              average_type={t("D079")}
              width="half bars"
              color={colours.yellow}
              modalText={[t("DW045")]}
              openModal={props.openModal}
              isTime
            />
            <BarChart //last meal
              title={t("D167")}
              average={trackerScores.nutrition.lastMeal_average}
              data={trackerScores.nutrition.lastMeal}
              icon={faUtensils}
              units=""
              average_type={t("D079")}
              width="half bars"
              yMaxLimit={24}
              color={colours.blue}
              modalText={[t("DW046")]}
              openModal={props.openModal}
              isTime
            />
            <LineChart //daily eating window
              title={t("D168")}
              data={trackerScores.nutrition.daily_eating_window}
              icon={faClock}
              average={trackerScores.nutrition.daily_eating_window_average}
              units={t("D077")}
              average_type={t("D079")}
              width="half bars"
              color={colours.red}
              modalText={[t("DW047")]}
              openModal={props.openModal}
            />
            <BarChart //fruit & veg portions
              title={t("D169")}
              average={trackerScores.nutrition.vegetables_average}
              data={trackerScores.nutrition.vegetables}
              icon={faSalad}
              units={t("D171")}
              average_type={t("DW032")}
              width="half bars"
              color={colours.blue}
              modalText={[t("DW048"), t("DW049"), t("DW050")]}
              openModal={props.openModal}
            />
            <CircleValueTile //5-a-day achieved
              title={t("D172")}
              data={trackerScores.nutrition.vegetables_five_a_day}
              value={t("M070")}
              icon={faShieldCheck}
              colour={colours.green}
              width="quarter"
              withIcon
              modalText={[t("DW051")]}
              openModal={props.openModal}
            />
            <CircleValueTile //total fruit & veg
              title={t("D173")}
              data={trackerScores.nutrition.vegetables_total}
              value={t("D171")}
              icon={faWatermelonSlice}
              colour={colours.blue}
              width="quarter"
              withIcon
            />
            <BarChart //takeaways
              title={t("D170")}
              data={trackerScores.nutrition.takeaways}
              icon={faBurgerFries}
              average={trackerScores.nutrition.takeaways_total}
              units="takeaways"
              width="half bars"
              average_type={t("DW033")}
              color={colours.red}
              modalText={[t("DW052")]}
              openModal={props.openModal}
            />
            <JournalBlock
              width="quarter"
              startWith={8}
              journalArr={trackerJournals}
              openModal={props.openModal}
            />
          </div>
        )}

      {/* {props.slidersList?.[1] && (
            <div className="results-row">
              <Slider
                title={props.slidersList[1].title}
                key={props.slidersList[1].id}
                id={props.slidersList[1].id}
                media={props.slidersList[1].media}
                favourites={favourites}
                dislikes={dislikes}
                progressList={progressList}
                onOpenMediaModal={openMediaModal}
                addLikeDislike={addLikeDislike}
                target={props.slidersList[1].target || false}
              />
            </div>
          )} */}
    </div>
  );
};
export default NutritionResults;
