import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CircleGraph from "../../components/graphs/circleGraph/CircleGraph";

import BarChart from "../../components/graphs/barChart/BarChart";

function Demographics(props) {
  const { t } = useTranslation();
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const awake_money_worries = [
    { date: t("F001"), value: props.data.money_worries_yes, height: "0%" },
    {
      date: t("F002"),
      value: props.data.money_worries_sometimes,
      height: "0%",
    },
    { date: t("F003"), value: props.data.money_worries_no, height: "0%" },
  ];

  return (
    <React.Fragment>
      <CircleGraph
        title={t("F004")}
        square
        colour="colour2"
        value={props.data.financial_stress_no}
        large
        percent={props.data.financial_stress}
        modalText={t("F005")}
        onOpenModal={props.onOpenModal}
      />

      {/* <CircleGraph
        title="Unhealthy Relationship with Money"
        square
        colour="colour3"
        value={props.data.unhealthy_money_no}
        large
        percent={props.data.unhealthy_money}
        modalText="This result shows the % of employees with an 'unhealthy' relationship with money.<br/><br/>A healthy relationship with money can be defined as feeling content and in control of your finances, where you're able to spend money based on your values and manage your finances effectively."
        onOpenModal={props.onOpenModal}
      /> */}
      <BarChart
        title={t("F006")}
        data={awake_money_worries}
        unit="%"
        modalText={t("F007")}
        onOpenModal={props.onOpenModal}
      />
    </React.Fragment>
  );
}
export default Demographics;
