import React, { useState, useEffect } from "react";
import "../styles/componentStyles/LargeCircle.css";
import { useTranslation } from "react-i18next";

import rainbow from "../images/circle.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";

const outerWidth = 300;
const PI = Math.PI;
const TAU = 2 * PI;
const centre = { x: outerWidth / 2, y: outerWidth / 2 };
const radius = outerWidth / 2;

function angleForValue(value, startValue, endValue, startAngle, endAngle) {
  const progress = (value - startValue) / (endValue - startValue);
  return (endAngle - startAngle) * progress + startAngle;
}

/** Converts polar coordinates to cartesian, given the parameters of the circle */
function polarToCartesian(centerX, centerY, radius, angleInRadians) {
  return {
    x: centerX + radius * Math.cos(angleInRadians + Math.PI / 2),
    y: centerY + radius * Math.sin(angleInRadians + Math.PI / 2),
  };
}

/** Given the parameters of the arc, returns an SVG path description matching the parameters */
function describeArc(x, y, radius, startAngle, endAngle) {
  var start = polarToCartesian(x, y, radius, endAngle);
  var end = polarToCartesian(x, y, radius, startAngle);
  var largeArcFlag = endAngle - startAngle <= Math.PI ? "0" : "1";
  var d = [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(" ");
  return d;
}

function LargeCircle(
  props,
  {
    startValue = 0,
    endValue = 100,
    startAngle = 0.25 * PI,
    endAngle = 1.75 * PI,
    stroke = 10,
    bgColor = "#ddcedb",
    margin = 20,
  }
) {
  const { t } = useTranslation();
  const [value, setValue] = useState(null);
  const [size, setSize] = useState("450px");

  useEffect(() => {
    if (props.percent) {
      setValue(props.percent);
    }
  }, [props.percent]);

  useEffect(() => {
    const width = window.innerWidth;
    if (width < 470) {
      setSize("300px");
    }
  }, []);

  return (
    <div className={"chart-container " + props.width}>
      <div className="title-container fin">
        <div className="title">{props.title}</div>
        <div className="wtf">
          <FontAwesomeIcon
            icon={faQuestionCircle}
            className="wtf icon"
            onClick={() => props.openModal(props.title, props.modalText)}
          />
        </div>
      </div>
      {props.percent && (
        <div className="chart-box">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: "40px",
            }}
            className="outer-circle"
          >
            <div
              style={{
                width: `${size}`,
                height: `${size}`,
                alignSelf: "centre",
              }}
              className="inner-circle"
            >
              <svg
                id="hs-slider"
                height={size}
                width={size}
                viewBox={`0 0 ${outerWidth} ${outerWidth}`}
                style={{ position: "absolute" }}
              >
                <defs>
                  <pattern
                    id="rainbowPattern"
                    height="1"
                    width="1.15"
                    // patternUnits="objectBoundingBox"
                    patternContentUnits="userSpaceOnUse"
                    x="1.08"
                    y="0"
                  >
                    <image
                      height="100%"
                      width="100%"
                      // preserveAspectRatio="none"
                      preserveAspectRatio="xMidYMid slice"
                      // preserveAspectRatio="none"
                      href={rainbow}
                      x="0"
                      y="0"
                    />
                  </pattern>
                </defs>

                {new Array(19).fill(0).map((_, i) => {
                  const delta = (TAU * 0.75) / 19;
                  const theta = delta * i - PI / 2 - 0.71 * PI;
                  const p1 = {
                    x: centre.x + (radius - 15) * Math.cos(theta),
                    y: centre.y + (radius - 15) * Math.sin(theta),
                  };
                  const p2 = {
                    x: centre.x + (radius - 5) * Math.cos(theta),
                    y: centre.y + (radius - 5) * Math.sin(theta),
                  };
                  return (
                    <React.Fragment key={i}>
                      <line
                        stroke="#ddcedb"
                        strokeWidth={1}
                        strokeLinecap="round"
                        x1={p1.x}
                        y1={p1.y}
                        x2={p2.x}
                        y2={p2.y}
                      />
                    </React.Fragment>
                  );
                })}
                <path
                  d={describeArc(
                    centre.x,
                    centre.y,
                    radius - stroke / 2 - margin,
                    startAngle,
                    endAngle
                  )}
                  stroke={bgColor}
                  strokeWidth={stroke}
                  fill="transparent"
                  strokeLinecap="round"
                />
                <path
                  d={describeArc(
                    centre.x,
                    centre.y,
                    radius - stroke / 2 - margin,
                    startAngle,
                    angleForValue(
                      value,
                      startValue,
                      endValue,
                      startAngle,
                      endAngle
                    )
                  )}
                  stroke="url(#rainbowPattern)"
                  strokeWidth={stroke}
                  // fill="url(#rainbowPattern)"
                  fill="transparent"
                  strokeLinecap="round"
                />
                <g>
                  <text
                    x={centre.x}
                    y={centre.y - 45}
                    textAnchor="middle"
                    dominantBaseline="central"
                    style={{
                      fontFamily: "futura-pt-medium",
                      fontSize: "20px",
                      textTransform: "uppercase",
                      fill: "#fff",
                    }}
                  >
                    {t("D127")}
                  </text>
                  <text
                    x={centre.x}
                    y={centre.y}
                    textAnchor="middle"
                    dominantBaseline="central"
                    style={{
                      fontFamily: "futura-pt-demi",
                      fontSize: "70px",
                      fill: "#fff",
                    }}
                  >
                    {props.percent}%
                  </text>{" "}
                </g>
              </svg>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default LargeCircle;
