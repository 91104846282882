import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatAssessmentDate } from "../../shared/util/helperfunctions";
import { Chart, registerables } from "chart.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";

const monthsArr = ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"];

const stress_levels = [
  "Low",
  "Low-moderate",
  "Moderate",
  "Moderate-high",
  "High",
];

const convertToTime = (value) => {
  let timeStr = value.toString();
  if (timeStr.length === 5) {
    timeStr = timeStr.replace(".", ":");
  } else if (timeStr.includes(".")) {
    // let h = timeStr.split(".")[0]
    let m = timeStr.split(".")[1];
    if (m.length === 0) {
      m = "00";
    } else if (m.length === 1) {
      m = m + "0";
    }
    timeStr = `${timeStr.split(".")[0]}:${m}`;
  } else {
    timeStr = timeStr + ":00";
  }
  return timeStr;
};

const calculateStressLevel = (level) => {
  if (level <= 1) {
    return stress_levels[0];
  } else if (level <= 2) {
    return stress_levels[1];
  } else if (level <= 3) {
    return stress_levels[2];
  } else if (level <= 4) {
    return stress_levels[3];
  } else {
    return stress_levels[4];
  }
};

const createCustomTooltip = (
  units,
  average_type,
  data_length,
  assessDates,
  yMaxLimit,
  outOfYMax,
  stressDescriptor,
  hoursWorked,
  isTime
) => ({
  id: "customTooltip",

  afterDraw(chart, args, options) {
    if (chart.tooltip?._active[0]) {
      const {
        ctx,
        chartArea: { top, bottom },
      } = chart;
      ctx.save();
      const activePoint = chart.tooltip._active[0];
      const datapoint = activePoint.index;
      const datasetIndex = activePoint.datasetIndex;

      //get text for tooltip
      const value =
        chart.getDatasetMeta(datasetIndex).data[datapoint].$context.raw;
      let stat;
      if (value === null || value === undefined) {
        stat = "No data";
      } else if (yMaxLimit && outOfYMax) {
        stat = `${Number(value).toString()}/${yMaxLimit}`;
      } else if (hoursWorked) {
        stat = `${Number(value).toString()} hours`;
      } else if (isTime) {
        if (Array.isArray(value)) {
          stat = convertToTime(value[1]);
        } else {
          stat = convertToTime(value);
        }
      } else {
        stat = `${Number(value).toString()} ${units}`;
      }

      const date =
        chart.getDatasetMeta(datasetIndex).iScale._labelItems[datapoint].label;
      let textWidth = ctx.measureText(stat).width + 35; // 35 for padding
      if (stressDescriptor && value !== null) {
        textWidth = ctx.measureText(calculateStressLevel(value)).width + 35;
      }
      if (average_type) {
        let averageWidth = ctx.measureText(average_type).width + 35;
        if (averageWidth > textWidth) {
          textWidth = averageWidth;
        }
      }

      const xPoint = chart
        .getDatasetMeta(datasetIndex)
        .data[datapoint].tooltipPosition().x;
      const yPoint = chart
        .getDatasetMeta(datasetIndex)
        .data[datapoint].tooltipPosition().y;

      const labelsLength =
        chart.getDatasetMeta(datasetIndex).iScale._labelItems.length;

      const activeLabel =
        chart.getDatasetMeta(datasetIndex).data[datapoint].$context.index;
      // for the last two values show the lable on the left of the bar
      if (activeLabel >= labelsLength - 2) {
        //if it is tracker data
        if (data_length !== 7 && !assessDates) {
          // draw rounded rectangle
          ctx.beginPath();
          if (stressDescriptor && value !== null) {
            ctx.roundRect(xPoint - 105, yPoint - 25, textWidth, 70, 12);
          } else {
            ctx.roundRect(xPoint - 105, yPoint - 25, textWidth, 65, 12);
          }
          ctx.strokeStyle = "#64596b";
          ctx.lineWidth = 1;
          ctx.stroke();
          ctx.fillStyle = "#64596b";
          ctx.fill();
          ctx.closePath();

          // draw date
          ctx.font = "10px futura-pt-book";
          ctx.fillStyle = "#fff";
          ctx.textAlign = "start";
          ctx.fillText(`${date[0]} ${date[1]}`, xPoint - 90, yPoint - 5);

          //draw average
          ctx.font = "15px futura-pt-book";
          ctx.fillText(`${average_type}`, xPoint - 90, yPoint + 10);
          // draw value
          ctx.font = "15px futura-pt-demi";
          ctx.fillText(`${stat}`, xPoint - 90, yPoint + 25);
          //draw stress_level
          if (stressDescriptor && value !== null) {
            ctx.font = "15px futura-pt-book";
            ctx.fillText(
              `${calculateStressLevel(value)}`,
              xPoint - 90,
              yPoint + 35
            );
          }
        } else {
          //if it is assessment data
          // draw rounded rectangle
          ctx.beginPath();
          if (stressDescriptor && value !== null) {
            ctx.roundRect(xPoint - 105, yPoint - 25, textWidth, 65, 12);
          } else {
            ctx.roundRect(xPoint - 105, yPoint - 25, textWidth, 50, 12);
          }
          ctx.strokeStyle = "#64596b";
          ctx.lineWidth = 1;
          ctx.stroke();
          ctx.fillStyle = "#64596b";
          ctx.fill();
          ctx.closePath();

          // draw date
          ctx.font = "10px futura-pt-book";
          ctx.fillStyle = "#fff";
          ctx.textAlign = "start";
          ctx.fillText(`${date[0]} ${date[1]}`, xPoint - 90, yPoint - 5);

          // draw value
          ctx.font = "15px futura-pt-demi";
          ctx.fillText(`${stat}`, xPoint - 90, yPoint + 10);

          //draw stress_level
          if (stressDescriptor && value !== null) {
            ctx.font = "15px futura-pt-book";
            ctx.fillText(
              `${calculateStressLevel(value)}`,
              xPoint - 90,
              yPoint + 25
            );
          }
        }
      } else {
        if (data_length !== 7 && !assessDates) {
          // draw rounded rectangle
          ctx.beginPath();
          if (stressDescriptor && value !== null) {
            ctx.roundRect(xPoint + 15, yPoint - 25, textWidth, 80, 12);
          } else {
            ctx.roundRect(xPoint + 15, yPoint - 25, textWidth, 65, 12);
          }
          ctx.strokeStyle = "#64596b";
          ctx.lineWidth = 1;
          ctx.stroke();
          ctx.fillStyle = "#64596b";
          ctx.fill();
          ctx.closePath();

          // draw date
          ctx.font = "10px futura-pt-book";
          ctx.fillStyle = "#fff";
          ctx.textAlign = "start";
          ctx.fillText(`${date[0]} ${date[1]}`, xPoint + 25, yPoint - 5);

          //draw average
          ctx.font = "15px futura-pt-book";
          ctx.fillText(`${average_type}`, xPoint + 25, yPoint + 10);

          // draw value
          ctx.font = "15px futura-pt-demi";
          ctx.fillText(`${stat}`, xPoint + 25, yPoint + 25);

          //draw stress_level
          if (stressDescriptor && value !== null) {
            ctx.font = "15px futura-pt-book";
            ctx.fillText(
              `${calculateStressLevel(value)}`,
              xPoint + 25,
              yPoint + 40
            );
          }
        } else {
          // draw rounded rectangle
          ctx.beginPath();
          if (stressDescriptor && value !== null) {
            ctx.roundRect(xPoint + 15, yPoint - 25, textWidth, 60, 12);
          } else {
            ctx.roundRect(xPoint + 15, yPoint - 25, textWidth, 50, 12);
          }
          ctx.strokeStyle = "#64596b";
          ctx.lineWidth = 1;
          ctx.stroke();
          ctx.fillStyle = "#64596b";
          ctx.fill();
          ctx.closePath();

          // draw date
          ctx.font = "10px futura-pt-book";
          ctx.fillStyle = "#fff";
          ctx.textAlign = "start";
          ctx.fillText(`${date[0]} ${date[1]}`, xPoint + 25, yPoint - 5);

          // draw value
          ctx.font = "15px futura-pt-demi";
          ctx.fillText(`${stat}`, xPoint + 25, yPoint + 10);

          //draw stress_level
          if (stressDescriptor && value !== null) {
            ctx.font = "15px futura-pt-book";
            ctx.fillText(
              `${calculateStressLevel(value)}`,
              xPoint + 25,
              yPoint + 25
            );
          }
        }
      }

      ctx.restore();
    }
  },
});

const customMouseOut = {
  id: "myEventCatcher",
  afterEvent(chart, args, pluginOptions) {
    const event = args.event;
    if (event.type === "mouseout") {
      if (chart) {
        const dataset = chart.data.datasets[0];
        const dataLength = dataset.data.length;
        const newBackgroundColor = Array(dataLength).fill("#e92364");
        dataset.backgroundColor = newBackgroundColor;
        dataset.data.forEach((el, index) => {
          if (el === 0) {
            newBackgroundColor[index] = "transparent";
          }
        });

        //reset tick colors
        let labelColors = chart.config.options.scales["x"].ticks.color;
        let foundIndex = labelColors.findIndex(
          (x) => x === "rgba(221,206,219, 1)"
        );
        labelColors[foundIndex] = "rgba(221,206,219, 0.5)";
        chart.config.options.scales["x"].ticks.color = labelColors;

        chart.update();
      }
    }
  },
};

const customBarContinuation = {
  id: "customBarContinuation",
  beforeDraw: (chart) => {
    const ctx = chart.ctx;
    const xAxis = chart.scales["x"];
    const yAxis = chart.scales["y"];
    const datasets = chart.data.datasets;

    ctx.save();
    ctx.fillStyle = "#362b3c";

    datasets.forEach((dataset) => {
      const data = dataset.data;
      const barSpacing = xAxis.getPixelForValue(1) - xAxis.getPixelForValue(0);
      //   const barWidth = barSpacing * 0.68; // Adjust the width as needed
      const barWidth = 14;

      for (let i = 0; i < data.length; i++) {
        const radius = barWidth / 2;
        const startX =
          xAxis.left + i * barSpacing + (barSpacing - barWidth) / 2;
        const startY = yAxis.top + radius;

        ctx.beginPath();
        ctx.moveTo(startX, startY);
        ctx.lineTo(startX, yAxis.bottom - radius);
        ctx.arc(
          startX + radius,
          yAxis.bottom - radius,
          radius,
          Math.PI,
          0,
          true
        );
        ctx.lineTo(startX + barWidth, startY);
        ctx.arc(startX + radius, startY, radius, 0, Math.PI, true);
        ctx.closePath();
        ctx.fill();
      }
    });
    ctx.restore();
  },
};

const BarChart = (props) => {
  const { t } = useTranslation();
  const chartRef = useRef(null);
  // const customTooltipWithUnits = createCustomTooltip(
  //   props.units,
  //   props.average_type,
  //   props.data.length,
  //   props.assessDates,
  //   props.yMaxLimit,
  //   props.outOfYMax,
  //   props.stressDescriptor,
  //   props.hoursWorked,
  //   props.isTime
  // );
  const [dataAvailable, setDataAvailable] = useState(true);

  useEffect(() => {
    setDataAvailable(true);
    Chart.register(...registerables);
    let labels = [];
    let dataArr = [];
    let labelColor = [];
    let highest = 0;
    let lowest = 0;
    let assessmentDate;
    let dateIndex;
    if (props.assessDates) {
      assessmentDate = formatAssessmentDate(
        props.assessDates[props.assessmentNumber]
      ).slice(0, -5);
    } else {
      let currentDate = new Date().toJSON().slice(0, 10);
      assessmentDate = formatAssessmentDate(currentDate).slice(0, 6);
    }
    if (props.data) {
      props.data.forEach((item, index) => {
        let date = item.date;
        if (!props.assessDates && props.data.length !== 12) {
          date = formatAssessmentDate(date.slice(0, 10)).slice(0, 6);
        } else if (!props.assessDates && props.data.length === 12) {
          let newDate = new Date(date);
          date = newDate.getFullYear().toString();
        }
        if (date.startsWith("0")) date = date.substring(1);
        if (assessmentDate === date) {
          labelColor.push("#fff");
          dateIndex = index;
        } else {
          labelColor.push("rgba(221,206,219, 0.5)");
        }
        date = date.toUpperCase();
        if (!props.assessDates && props.data.length === 5) {
          labels.push([date.split(" ")[1], `WEEK ${index + 1}`]);
        } else if (!props.assessDates && props.data.length === 12) {
          labels.push([monthsArr[index], date.substring(2)]);
        } else {
          labels.push([date.split(" ")[0], date.split(" ")[1]]);
        }
        dataArr.push(item.value);
      });
      //if there is no tracker data, create mock data
      if (props.average === null) {
        const arr = [];

        for (let i = 0; i < dataArr.length; i++) {
          const randomNumber = Math.floor(Math.random() * 10) + 1; // Generate a random number between 1 and 10
          arr.push(randomNumber);
        }
        setDataAvailable(false);
        dataArr = [...arr];
      }

      highest = Math.max(...dataArr);
      if (props.isTime) {
        highest = Math.ceil(highest) + 2;
        if (highest > 24) highest = 24;
        lowest = Math.floor(Math.min(...dataArr.filter((a) => a !== null))) - 2;
        if (lowest < 0) lowest = 0;
        if (lowest > 0) {
          dataArr = dataArr.map((item, index) => {
            if (item !== null) return (item = [lowest, item]);
          });
        }
      } else if (props.yMaxLimit && !props.isTime) {
        highest = props.yMaxLimit;
      } else if (highest <= 20) {
        highest = Math.ceil(highest / 5) * 5;
      } else {
        highest = Math.ceil(highest / 10) * 10;
      }

      // if only 1 assessment value add 4 blank values
      if (dataArr.length === 1) {
        for (let x = 0; x < 4; x++) {
          labelColor.push("rgba(221,206,219, 0.5)");
          dataArr.push(null);
          labels.push(["DD", "MM"]);
        }
      }
    }

    const data = {
      labels,
      datasets: [
        {
          data: dataArr,
          backgroundColor: (context) => {
            const value = context.dataset.data[context.dataIndex];
            return value === 0
              ? "transparent"
              : props.average !== null
              ? "#e92364"
              : "#615668";
          },
          hoverBackgroundColor: (context) => {
            const value = context.dataset.data[context.dataIndex];
            return value === 0
              ? "transparent"
              : props.average !== null
              ? "#e92364"
              : "#615668";
          },
          barThickness: 14, // number (pixels) or 'flex'
          borderRadius: Number.MAX_VALUE,
          borderSkipped: false,
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 50,
          right: 20,
          bottom: 0,
          left: 0,
        },
      },
      indexAxis: "x",
      scales: {
        x: {
          grid: { display: false },
          border: { display: false },
          ticks: {
            beginAtZero: true,
            font: "futura-pt-medium",
            size: 12.5,
            color: labelColor,
          },
        },
        y: {
          max: highest,
          min: lowest,
          ticks: {
            display: props.average !== null ? true : false,
            precision: 0,
          },
          beginAtZero: true,
          grid: { display: false },
          border: {
            display: false,
          },
          position: "right",
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      interaction: {
        intersect: false,
        mode: "index",
      },
      onHover: (event, activeElements) => {
        const chart = event.chart;

        if (chart && props.average !== null) {
          const dataset = chart.data.datasets[0];
          const dataLength = dataset.data.length;
          const newBackgroundColor = Array(dataLength).fill("#615668");

          labelColor.forEach((item, index) => {
            if (index === dateIndex) {
              labelColor[index] = "#fff";
            } else {
              labelColor[index] = "rgba(221,206,219, 0.5)";
            }
          });

          if (activeElements.length > 0) {
            activeElements.forEach((activeElement) => {
              const { datasetIndex, index } = activeElement;
              newBackgroundColor[index] = "#e92364";
              // Change the active tick label opacity to 1
              if (index === dateIndex) {
                labelColor[index] = "#fff";
              } else {
                labelColor[index] = "rgba(221,206,219, 1)";
              }
            });
          }
          dataset.data.forEach((el, index) => {
            if (el === 0) {
              newBackgroundColor[index] = "transparent";
            }
          });

          chart.config.options.scales["x"].ticks.color = labelColor;
          dataset.backgroundColor = newBackgroundColor;
          chart.update();
        }
      },
    };

    if (chartRef && chartRef.current && props.average !== null) {
      const chartInstance = chartRef.current.chartInstance;
      if (chartInstance) {
        chartInstance.destroy();
      }
      chartRef.current.chartInstance = new Chart(
        chartRef.current.getContext("2d"),
        {
          type: "bar",
          data,
          options,
          plugins: [
            customMouseOut,
            customBarContinuation,
            createCustomTooltip(
              props.units,
              props.average_type,
              props.data.length,
              props.assessDates,
              props.yMaxLimit,
              props.outOfYMax,
              props.stressDescriptor,
              props.hoursWorked,
              props.isTime
            ),
          ],
        }
      );
    } else if (chartRef && chartRef.current && props.average === null) {
      const chartInstance = chartRef.current.chartInstance;
      if (chartInstance) {
        chartInstance.destroy();
      }
      chartRef.current.chartInstance = new Chart(
        chartRef.current.getContext("2d"),
        {
          type: "bar",
          data,
          options,
          plugins: [customBarContinuation],
        }
      );
    }

    return () => {
      if (chartRef && chartRef.current && chartRef.current.chartInstance) {
        chartRef.current.chartInstance.destroy();
      }
    };
  }, [props.data, props.assessmentNumber]);

  return (
    <div className={"chart-container " + props.width}>
      <div className="title-container">
        <div className="title">{props.title}</div>
        <div className="wtf">
          <FontAwesomeIcon
            icon={faQuestionCircle}
            className="wtf icon"
            onClick={() => props.openModal(props.title, props.modalText)}
          />
        </div>
      </div>
      <div className="charts-box">
        {!dataAvailable && <div className="chart-no-data">{t("D075")}</div>}
        <div className="chart-wrap">
          <canvas ref={chartRef} />{" "}
        </div>

        <div className="average">
          <div
            className="title"
            style={{ color: `${!dataAvailable ? "#615668" : ""}` }}
          >
            {props.average_type}
          </div>
          <div
            className="stats"
            style={{ color: `${dataAvailable ? props.color : "#615668"}` }}
          >
            <FontAwesomeIcon icon={props.icon} className="icon alarm" />
            <div className="stat">
              {props.average && dataAvailable
                ? props.isTime
                  ? convertToTime(props.average)
                  : props.units === t("D076")
                  ? Number(props.average).toFixed().toString()
                  : Number(props.average).toString()
                : " "}
              {/* {props.units && <span>{props.units}</span>} */}
            </div>
            {props.units && <div className="unit">{props.units}</div>}
            {props.stressDescriptor && (
              <div className="av-descr">
                {calculateStressLevel(props.average)}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BarChart;
