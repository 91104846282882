import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./styles/Results.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
} from "@fortawesome/sharp-solid-svg-icons";
import { faPlus } from "@fortawesome/sharp-light-svg-icons";

import LoadingSpinner from "../shared/components/UIElements/LoadingSpinner";

import { setTakeAction } from "../forYou/mediaSlidersSlice";
import { useFetchTakeActionQuery } from "../forYou/mediaSlidersApiSlice";

import { AuthContext } from "../shared/context/auth-context";
import { useHttpClient } from "../shared/hooks/http-hook";

import Trackers from "../trackers/Trackers";
import SleepResults from "./resultsByHealthArea/SleepResults";
import PhysicalWellbeingResults from "./resultsByHealthArea/PhysicalWellbeingResults";
import MovementResults from "./resultsByHealthArea/MovementResults";
import MentalWellbeingResults from "./resultsByHealthArea/MentalWellbeingResults";
import FinancialWellbeingResults from "./resultsByHealthArea/FinancialWellbeingResults";
import NutritionResults from "./resultsByHealthArea/NutritionResults";
import HydrationResults from "./resultsByHealthArea/HydrationResults";
import ProductivityResults from "./resultsByHealthArea/ProductivityResults";
import EnergyResults from "./resultsByHealthArea/EnergyResults";
import Footer from "../shared/navfooter/Footer";
import Header from "../shared/header/MainHeader";
import { faHouse } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import Slider from "../shared/components/slider/Slider";
import PWHeroImg from "./images/Physical_Wellbeing.jpg";
import EnHeroImg from "./images/Energy.jpg";
import FiHeroImg from "./images/Financial_Wellbeing.jpg";
import HyHeroImg from "./images/Hydration.jpg";
import MeHeroImg from "./images/Mental_Wellbeing.jpg";
import MoHeroImg from "./images/Movement.jpg";
import NuHeroImg from "./images/Nutrition.jpg";
import PrHeroImg from "./images/Productivity.jpg";
import SlHeroImg from "./images/Sleep.jpg";
import WhitePopUpNoTitle from "../shared/modal/whitePopUp/WhitePopUpNoTitle";
import {
  formatAssessmentDate,
  formatDate,
  formatDateRange,
  getPeriodInDates,
} from "../shared/util/helperfunctions";
import { haNavigation, pages } from "../assets/constants";
import {
  getChallengeType,
  shouldHideNavChallenge,
} from "../shared/util/challenges";
import { useFetchChallengeQuery } from "../challenges/challengesApiSlice";
import { emptyChallenge, setChallenge } from "../challenges/challengesSlice";

const HealthArea = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pageid = window.location.pathname;
  const healthArea = pageid.split("/")[1];
  const pageName = pageid.split("/")[1].replaceAll("-", " ");
  const activePage = pageid.split("/")[2];
  const { error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const { state } = useLocation();
  const takeAction = useSelector((state) => state.mediaSliders.takeAction);
  const challenge = useSelector((state) => state?.challenges?.challenge);

  const [isLoading, setIsLoading] = useState(false);
  const [showTrackers, setShowTrackers] = useState(false);
  const [showAssessment, setShowAssessment] = useState(false);
  const [keepTrackerOpen, setKeepTrackerOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalTextContent, setModalTextContent] = useState("");
  const [updateSmoking, setUpdateSmoking] = useState(false);

  // results navigation
  const [defaultDate, setDefaultDate] = useState(null);
  const [showDropDown, setShowDropDown] = useState(false);
  const [activePeriod, setActivePeriod] = useState(null);
  const [datesString, setDatesString] = useState(null);
  const [trackerDates, setTrackerDates] = useState([]);

  const pageObjRef = useRef({
    heroImage: "",
    heroTitle: "",
    heroText: "",
    assessDates: [],
    assessmentNumber: "",
    trackerNames: [],
  });

  const [dataObj, setDataObj] = useState({
    isLoading: false,
    assessScores: "",
    trackerScores: [],
    trackerJournals: [],
    slidersList: [],
  });

  useEffect(() => {
    if (!healthArea) return;
    const pageObj = pageObjRef.current;
    switch (healthArea) {
      case "physical-wellbeing":
        pageObj.heroImage = PWHeroImg;
        pageObj.heroTitle = t("DW001");
        pageObj.heroText = t("DW002");
        pageObj.trackerNames = ["smoking"];
        break;
      case "energy":
        pageObj.heroImage = EnHeroImg;
        pageObj.heroTitle = t("DW021");
        pageObj.heroText = t("DW022");
        pageObj.trackerNames = ["energy"];
        break;
      case "financial-wellbeing":
        pageObj.heroImage = FiHeroImg;
        pageObj.heroTitle = t("DW012");
        pageObj.heroText = t("DW013");
        break;
      case "hydration-&-alcohol":
        pageObj.heroImage = HyHeroImg;
        pageObj.heroTitle = t("DW016");
        pageObj.heroText = t("DW017");
        pageObj.trackerNames = ["hydration", "alcohol"];
        break;
      case "mental-wellbeing":
        pageObj.heroImage = MeHeroImg;
        pageObj.heroTitle = t("DW010");
        pageObj.heroText = t("DW011");
        pageObj.trackerNames = ["mood", "stress"];
        break;
      case "movement":
        pageObj.heroImage = MoHeroImg;
        pageObj.heroTitle = t("DW006");
        pageObj.heroText = t("DW007");
        pageObj.trackerNames = ["activity"];
        break;
      case "nutrition":
        pageObj.heroImage = NuHeroImg;
        pageObj.heroTitle = t("DW014");
        pageObj.heroText = t("DW015");
        pageObj.trackerNames = ["nutrition"];
        break;
      case "work-&-productivity":
        pageObj.heroImage = PrHeroImg;
        pageObj.heroTitle = t("DW019");
        pageObj.heroText = t("DW020");
        pageObj.trackerNames = ["productivity"];
        break;
      case "sleep-&-rest":
        pageObj.heroImage = SlHeroImg;
        pageObj.heroTitle = t("DW008");
        pageObj.heroText = t("DW009");
        pageObj.trackerNames = ["sleep"];
        break;
      default:
        break;
    }
    const fetchData = async () => {
      setIsLoading(true);
      let today = new Date().toJSON().slice(0, 10);
      setDefaultDate(today);
      setActivePeriod(t("DW003"));
      let currentPeriod = getPeriodInDates(today, "week");
      setTrackerDates(currentPeriod);
      let string = formatDateRange(currentPeriod, "week");
      setDatesString(string);
      await getAssessmentScores();
      if (pageObjRef.current.heroTitle !== t("DW012")) {
        await getTrackerData(currentPeriod);
      } else {
        setIsLoading(false);
        setShowAssessment(true);
      }
    };

    fetchData();
  }, [healthArea]);

  useEffect(() => {
    if (dataObj.trackerScores[pageObjRef.current.trackerNames[0]]) {
      let hasAverage = Object.entries(
        dataObj.trackerScores[pageObjRef.current.trackerNames[0]]
      ).some(([key, value]) => key.includes("_average") && value !== null);
      if (pageObjRef.current.trackerNames.length > 1 && !hasAverage) {
        hasAverage = Object.entries(
          dataObj.trackerScores[pageObjRef.current.trackerNames[1]]
        ).some(([key, value]) => key.includes("_average") && value !== null);
      }
      if (activePage === "track") {
        setShowTrackers(true);
        setShowAssessment(false);
      } else {
        setShowTrackers(false);
        if (state?.from === "tracker") {
          setShowAssessment(false);
        } else if (hasAverage) {
          setShowAssessment(false);
        } else if (keepTrackerOpen) {
          setShowAssessment(false);
        } else {
          setShowAssessment(true);
        }
      }
      setIsLoading(false);
    }
  }, [
    healthArea,
    activePage,
    state?.from,
    dataObj.trackerScores[pageObjRef.current.trackerNames[0]],
  ]);

  let section = pages.find((page) => page.url === healthArea);
  let sectionid = section.id;

  const { data: takeActionData, isLoading: isFetchingTakeAction } =
    useFetchTakeActionQuery(
      {
        token: auth.token,
        pageid: sectionid,
      },
      { skip: !sectionid }
    );

  const {
    data: challengeData,
    isLoading: isChallengeDataLoading,
    error: challengeDataError,
  } = useFetchChallengeQuery(
    {
      token: auth.token,
      challengeType: getChallengeType(sectionid),
    },
    { skip: !getChallengeType(sectionid), refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (
      !isChallengeDataLoading &&
      !challengeDataError &&
      challengeData &&
      !challengeData?.message &&
      challengeData?.start_date &&
      new Date().getTime() >= new Date(challengeData.start_date).getTime()
    ) {
      dispatch(
        setChallenge({
          challenge: challengeData,
        })
      );
    } else {
      dispatch(
        setChallenge({
          challenge: emptyChallenge,
        })
      );
    }
  }, [challengeData, isChallengeDataLoading, challengeDataError]);

  useEffect(() => {
    if (!isFetchingTakeAction && takeActionData) {
      dispatch(setTakeAction(takeActionData));
    }
  }, [dispatch, isFetchingTakeAction]);

  useEffect(() => {
    let pageCont = document.getElementsByClassName("page-cont");
    if (
      pageCont.length > 0 &&
      healthArea &&
      activePage &&
      !isFetchingTakeAction
    ) {
      pageCont[0].classList.add("visible");
    }
  }, [healthArea, activePage, isFetchingTakeAction]);

  const getPreviousAssessment = () => {
    let newAssessment = pageObjRef.current.assessmentNumber - 1;
    if (newAssessment >= 0) {
      getAssessmentScores(newAssessment);
    }
  };

  const getLaterAssessment = () => {
    let newAssessment = pageObjRef.current.assessmentNumber + 1;
    if (newAssessment < pageObjRef.current.assessDates.length) {
      getAssessmentScores(newAssessment);
    }
  };

  const openModal = useCallback(
    (title, text) => {
      setModalIsOpen(true);
      setModalTextContent({ title: title, text: text });
    },
    [modalIsOpen, modalTextContent]
  );

  const modalClickHandler = useCallback(() => {
    setModalIsOpen(false);
  }, [modalIsOpen]);

  const getAssessmentScores = async (index) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/assessments/individualReportContent/${auth.userId}`;
    if (index !== undefined) {
      url = `${process.env.REACT_APP_BACKEND_URL}/assessments/individualReportContent/${auth.userId}/${pageObjRef.current.assessDates[index]}`;
    }

    try {
      const response = await sendRequest(url);
      if (response.scores) {
        setDataObj((prev) => ({ ...prev, assessScores: response.scores }));

        if (pageObjRef.current.assessDates.length < 1) {
          pageObjRef.current.assessDates = response.dates;
        }

        if (index === undefined && !pageObjRef.current.assessmentNumber) {
          pageObjRef.current.assessmentNumber = response.dates.length - 1;
        } else {
          pageObjRef.current.assessmentNumber = index;
        }
      }
    } catch (err) {}
  };

  const getTrackerDataByName = async (name, period, trackerDates) => {
    try {
      let url =
        `${process.env.REACT_APP_BACKEND_URL}/trackers/${name}/${trackerDates[0]}/${trackerDates[1]}` +
        (period ? `/${period}` : ``);

      const response = await sendRequest(url);

      if (response) {
        return {
          score: { [name]: response },
          journals: response.journals,
        };
      }
    } catch (err) {}
  };

  const getTrackerData = async (trackerDates, period) => {
    const pageObj = pageObjRef.current;
    let scores = {};
    let journals = [];
    let promises = [];

    for (let name of pageObj.trackerNames) {
      promises.push(getTrackerDataByName(name, period, trackerDates));
    }

    let response = await Promise.all(promises);

    for (let data of response) {
      scores = { ...scores, ...data.score };
      journals = journals.concat(data.journals);
    }

    setDataObj((prev) => ({
      ...prev,
      trackerScores: scores,
      trackerJournals: journals,
    }));
  };

  //scroll top on page change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activePage]);

  const toggleActivePage = (tabid) => {
    navigate(`/${healthArea}/${haNavigation[tabid].title}`);
  };

  const handleCloseTrackers = () => {
    if (state?.from === "challenge" && challenge?.isActive) {
      navigate(`/${healthArea}/${haNavigation[3].title}`);
      return;
    }
    if (dataObj?.trackerScores?.smoking?.smoke_per_day?.length > 0) {
      setUpdateSmoking(true);
    }

    let today = new Date().toJSON().slice(0, 10);
    setDefaultDate(today);
    setActivePeriod(t("DW003"));
    let currentPeriod = getPeriodInDates(today, "week");
    setTrackerDates(currentPeriod);
    let string = formatDateRange(currentPeriod, "week");
    setDatesString(string);
    getTrackerData(currentPeriod);
    setShowAssessment(false);
    navigate(`/${healthArea}/${haNavigation[1].title}`, {
      state: { from: "tracker" },
    });
  };

  const toggeleShowAssessment = (bool) => {
    setShowAssessment(bool);
  };

  const toggleShowDropDown = () => {
    setShowDropDown(() => !showDropDown);
  };

  const handleChangePeriod = (period) => {
    setShowDropDown(false);
    let newPeriod;
    let string;
    if (period === "week") {
      setActivePeriod(t("DW003"));
      newPeriod = getPeriodInDates(defaultDate, "week");
      string = formatDateRange(newPeriod, "week");
      getTrackerData(newPeriod);
    } else if (period === "month") {
      setActivePeriod(t("DW004"));
      newPeriod = getPeriodInDates(defaultDate, "month");
      string = formatDateRange(newPeriod, "month");
      getTrackerData(newPeriod, "weekly");
    } else if (period === "year") {
      setActivePeriod(t("DW005"));
      newPeriod = getPeriodInDates(defaultDate, "year");
      string = formatDateRange(newPeriod, "year");
      getTrackerData(newPeriod, "monthly");
    }
    setKeepTrackerOpen(true);
    setTrackerDates(newPeriod);
    setDatesString(string);
  };

  const getPreviousPeriod = () => {
    // Initialize new default and tracker dates
    let newDefaultDate;
    let newTrackerDates;
    let newDatesString;

    const inputDate = new Date(defaultDate);
    switch (activePeriod) {
      case t("DW003"): //week
        inputDate.setDate(inputDate.getDate() - 7);
        newDefaultDate = formatDate(inputDate);
        newTrackerDates = getPeriodInDates(newDefaultDate, "week");
        newDatesString = formatDateRange(newTrackerDates, "week");
        getTrackerData(newTrackerDates);
        break;

      case t("DW004"): //month
        inputDate.setMonth(inputDate.getMonth() - 1);
        newDefaultDate = formatDate(inputDate);
        newTrackerDates = getPeriodInDates(newDefaultDate, "month");
        newDatesString = formatDateRange(newTrackerDates, "month");
        getTrackerData(newTrackerDates, "weekly");
        break;

      case t("DW005"): //year
        inputDate.setFullYear(inputDate.getFullYear() - 1);
        newDefaultDate = formatDate(inputDate);
        newTrackerDates = getPeriodInDates(newDefaultDate, "year");
        newDatesString = formatDateRange(newTrackerDates, "year");
        getTrackerData(newTrackerDates, "monthly");
        break;

      default:
        throw new Error("Invalid tracker period specified.");
    }

    // Update the state values
    setKeepTrackerOpen(true);
    setDefaultDate(newDefaultDate);
    setTrackerDates(newTrackerDates);
    setDatesString(newDatesString);
  };

  const getLaterPeriod = () => {
    // Initialize new default and tracker dates
    let newDefaultDate;
    let newTrackerDates;
    let newDatesString;

    const inputDate = new Date(defaultDate);
    switch (activePeriod) {
      case t("DW003"):
        inputDate.setDate(inputDate.getDate() + 7);
        newDefaultDate = formatDate(inputDate);
        newTrackerDates = getPeriodInDates(newDefaultDate, "week");
        newDatesString = formatDateRange(newTrackerDates, "week");
        getTrackerData(newTrackerDates);
        break;

      case t("DW004"):
        inputDate.setMonth(inputDate.getMonth() + 1);
        newDefaultDate = formatDate(inputDate);
        newTrackerDates = getPeriodInDates(newDefaultDate, "month");
        newDatesString = formatDateRange(newTrackerDates, "month");
        getTrackerData(newTrackerDates, "weekly");
        break;

      case t("DW005"):
        inputDate.setFullYear(inputDate.getFullYear() + 1);
        newDefaultDate = formatDate(inputDate);
        newTrackerDates = getPeriodInDates(newDefaultDate, "year");
        newDatesString = formatDateRange(newTrackerDates, "year");
        getTrackerData(newTrackerDates, "monthly");
        break;

      default:
        throw new Error("Invalid tracker period specified.");
    }

    // Update the state values
    setKeepTrackerOpen(true);
    setDefaultDate(newDefaultDate);
    setTrackerDates(newTrackerDates);
    setDatesString(newDatesString);
  };

  return (
    <div id="main_container" className="foryou glowrm platform results">
      {isLoading && <LoadingSpinner asOverlay />}
      <div id="nav_container">
        <Header />
      </div>
      <div className="heading">
        <Link to="/for-you">
          <FontAwesomeIcon icon={faHouse} className="icon home" />
        </Link>
        <FontAwesomeIcon icon={faChevronRight} className="icon" />
        <h2>{pageName}</h2>
      </div>

      <ul className="nav_slider na">
        {haNavigation.map((item, index) => {
          if (healthArea === "financial-wellbeing" && item.target === 23) {
            return null;
          } else if (
            item &&
            (shouldHideNavChallenge(healthArea) ||
              auth.access === null ||
              auth.access === "2") &&
            item.target === 24
          ) {
            return null;
          } else {
            return (
              <li
                key={item.target}
                id={index}
                className={item.title === activePage ? "active" : ""}
                onClick={() => {
                  toggleActivePage(index);
                }}
              >
                {item.title}
              </li>
            );
          }
        })}
      </ul>
      {showTrackers && (
        <Trackers pageName={pageName} closeTrackers={handleCloseTrackers} />
      )}
      {modalIsOpen && (
        <WhitePopUpNoTitle
          popupContents={modalTextContent}
          ctaBtn={t("T015")}
          handleSubmit={modalClickHandler}
        />
      )}
      <div className="page-cont">
        <div
          className="results-img"
          style={{
            background: `url(${pageObjRef.current.heroImage}) center/cover no-repeat`,
          }}
        >
          <div className="gradient-overlay">
            <div className="hero-contents">
              <h1 className="hero-title">{pageObjRef.current.heroTitle}</h1>
              <h3>{pageObjRef.current.heroText}</h3>
            </div>
            <div className="results-nav">
              <div className="results-menu">
                {pageObjRef?.current?.trackerNames[0] && (
                  <div className="results-toggle">
                    <div
                      className={
                        showAssessment ? "assessment active" : "assessment"
                      }
                      onClick={() => {
                        toggeleShowAssessment(true);
                        setActivePeriod(t("DW003"));
                      }}
                    >
                      {t("D001")}
                    </div>
                    <div
                      className={!showAssessment ? "tracker active" : "tracker"}
                      onClick={() => toggeleShowAssessment(false)}
                    >
                      {t("D002")}
                    </div>
                  </div>
                )}
                {!showAssessment && (
                  <button
                    className="open-tracker"
                    onClick={() => {
                      toggleActivePage(2);
                      setActivePeriod(t("DW003"));
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faPlus} className="add-tracker" />
                  </button>
                )}
              </div>
              {showAssessment && pageObjRef.current.assessDates.length > 0 && (
                <div className="dates-toggle">
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    onClick={getPreviousAssessment}
                  />
                  <div>
                    {formatAssessmentDate(
                      pageObjRef.current.assessDates[
                        pageObjRef.current.assessmentNumber
                      ]
                    )}
                  </div>
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    onClick={getLaterAssessment}
                  />
                </div>
              )}

              {!showAssessment && (
                <div className="results-menu tracker-data">
                  <div
                    className="dates-toggle with-dd"
                    onClick={toggleShowDropDown}
                  >
                    {activePeriod && <div>{activePeriod}</div>}
                    <FontAwesomeIcon
                      icon={showDropDown ? faChevronUp : faChevronDown}
                    />
                    {showDropDown && (
                      <ul className="period-dd">
                        <li
                          style={{
                            color: `${
                              activePeriod === t("DW003") ? "#ffffff70" : "#fff"
                            }`,
                          }}
                          onClick={() => handleChangePeriod("week")}
                        >
                          {t("DW003")}
                        </li>
                        <li
                          style={{
                            color: `${
                              activePeriod === t("DW004") ? "#ffffff70" : "#fff"
                            }`,
                          }}
                          onClick={() => handleChangePeriod("month")}
                        >
                          {t("DW004")}
                        </li>
                        <li
                          style={{
                            color: `${
                              activePeriod === t("DW005") ? "#ffffff70" : "#fff"
                            }`,
                          }}
                          onClick={() => handleChangePeriod("year")}
                        >
                          {t("DW005")}
                        </li>
                      </ul>
                    )}
                  </div>
                  <div className="dates-toggle">
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      onClick={getPreviousPeriod}
                    />
                    {datesString && <div>{datesString}</div>}
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      onClick={getLaterPeriod}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* )} */}
        </div>
        {healthArea === "sleep-&-rest" && (
          <SleepResults
            pageObjRef={pageObjRef}
            dataObj={dataObj}
            showAssessment={showAssessment}
            openModal={openModal}
          />
        )}
        {healthArea === "movement" && (
          <MovementResults
            pageObjRef={pageObjRef}
            dataObj={dataObj}
            showAssessment={showAssessment}
            openModal={openModal}
          />
        )}
        {healthArea === "mental-wellbeing" && (
          <MentalWellbeingResults
            pageObjRef={pageObjRef}
            dataObj={dataObj}
            showAssessment={showAssessment}
            openModal={openModal}
          />
        )}
        {healthArea === "financial-wellbeing" && (
          <FinancialWellbeingResults
            pageObjRef={pageObjRef}
            dataObj={dataObj}
            showAssessment={showAssessment}
            openModal={openModal}
          />
        )}
        {healthArea === "nutrition" && (
          <NutritionResults
            pageObjRef={pageObjRef}
            dataObj={dataObj}
            showAssessment={showAssessment}
            openModal={openModal}
          />
        )}
        {healthArea === "hydration-&-alcohol" && (
          <HydrationResults
            pageObjRef={pageObjRef}
            dataObj={dataObj}
            showAssessment={showAssessment}
            openModal={openModal}
          />
        )}
        {healthArea === "work-&-productivity" && (
          <ProductivityResults
            pageObjRef={pageObjRef}
            dataObj={dataObj}
            showAssessment={showAssessment}
            openModal={openModal}
          />
        )}
        {healthArea === "energy" && (
          <EnergyResults
            pageObjRef={pageObjRef}
            dataObj={dataObj}
            showAssessment={showAssessment}
            openModal={openModal}
          />
        )}
        {healthArea === "physical-wellbeing" && (
          <PhysicalWellbeingResults
            pageObjRef={pageObjRef}
            dataObj={dataObj}
            showAssessment={showAssessment}
            openModal={openModal}
            updateSmoking={updateSmoking}
            closeUpdateSmoking={() => setUpdateSmoking(false)}
          />
        )}
        <div className="take-action-cont">
          {takeAction?.length > 0 && (
            <Slider
              title="Take Action"
              id="grekljhot"
              media={takeAction}
              target={false}
            />
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default HealthArea;
