import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./Style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalWrapper from "../../../../../shared/modal/ModalWrapper";
import { faInfo } from "@fortawesome/pro-solid-svg-icons";

function TopSix(props) {
  const { t } = useTranslation();
  const [list, setList] = useState(
    props.data
      ? props.data
      : [
          { title: t("TS001"), value: 42 },
          { title: t("TS002"), value: 12 },
          { title: t("TS003"), value: 5 },
        ]
  );

  useEffect(() => {
    let newlist = props.data;
    let setlist = [];
    newlist.map((item, index) => {
      item.newwidth = item.value;
      setlist.push(item);
    });
    setList(setlist);
  }, [props.data]);

  return (
    <div className={`${props.square ? "square wide" : "narrow"}`}>
      <div className="square_content">
        {props.modalText && (
          <ModalWrapper
            modalText={props.modalText}
            onOpenModal={props.onOpenModal}
          >
            <FontAwesomeIcon icon={faInfo} className="icon info_icon" />
          </ModalWrapper>
        )}
        {props.title ? <h3>{props.title}</h3> : ""}
        <div className={"top_three top_three_graph top_six"}>
          <div className="top_six_column">
            {props.preText && <span className="sub_head">{props.preText}</span>}
            {list.slice(0, 3).map((item, index) => {
              {
                !item.newwidth
                  ? (item.newwidth = "0%")
                  : (item.newwidth = item.newwidth);
              }
              return (
                <div key={index} className="top_three_row">
                  <h4>{item.title.replaceAll("_", " ")}</h4>
                  <div className="ttg_container">
                    <div
                      className="ttg_fill"
                      style={{ width: item.newwidth + "%" }}
                    >
                      <span>{item.value + "%"}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="top_six_column">
            {props.preText && <span className="sub_head">&nbsp;</span>}
            {list.slice(3, 6).map((item, index) => {
              {
                !item.newwidth
                  ? (item.newwidth = "0%")
                  : (item.newwidth = item.newwidth);
              }
              return (
                <div key={index} className="top_three_row">
                  <h4>{item.title.replaceAll("_", " ")}</h4>
                  <div className="ttg_container">
                    <div
                      className="ttg_fill"
                      style={{ width: item.newwidth + "%" }}
                    >
                      <span>{item.value + "%"}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopSix;
