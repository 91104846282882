import React, { useState, useEffect } from "react";
import "./Style.css";

import ModalTrigger from "../../../shared/modal/ModalTrigger";

function DotRadio(props) {
  const [radioOption, setRadioOption] = useState();

  function saveValue(event) {
    let whoToContact;
    let whoToContactOptions = [];
    if (props.regionContent) {
      whoToContact = [
        { key: 0, value: props.regionContent.self_harm_box_1 },
        { key: 1, value: props.regionContent.self_harm_box_2 },
        { key: 2, value: props.regionContent.self_harm_box_3 },
        { key: 3, value: props.regionContent.self_harm_box_4 },
      ];
      whoToContact.map((contact) => whoToContactOptions.push(contact));
    }
    if (event.target.value === radioOption) {
      //double click deselect
      setRadioOption("");
      props.onSetValue({ id: event.target.name, value: "" });
    } else {
      setRadioOption(event.target.value);
      props.onSetValue({ id: event.target.name, value: event.target.value });
    }

    //warning pop up
    if (event.target.name === "self_harm" && event.target.value > 0) {
      const modalWithBoxesText = {
        main: props.regionContent.self_harm_intro,
        secondary: whoToContactOptions,
      };
      let html = modalWithBoxesText;
      props.onOpenModal(html);
    }
  }

  useEffect(() => {
    if (
      (props.assessmentValues[props.questionid] ||
        props.assessmentValues[props.questionid] === 0) &&
      !radioOption
    ) {
      setRadioOption(props.assessmentValues[props.questionid]);
    }
  });

  var rows = [];
  var k;
  for (var i = 0; i < parseFloat(props.range); i++) {
    if (parseFloat(props.range) !== 4 && parseFloat(props.range) !== 8) {
      k = i + 1;
    } else {
      k = i;
    }
    rows.push({ index: i, value: k });
  }

  return (
    <React.Fragment>
      {props.type === "question" && (
        <div
          id={props.questionid}
          className={
            "question_block" + props.width + (props.skippable ? "" : " req")
          }
        >
          <h3>{props.questionTitle}</h3>
          {props.modalText && (
            <ModalTrigger
              modalText={props.modalText}
              onOpenModal={props.onOpenModal}
            />
          )}
          <div className="answer_block">
            <div className="dot_radio" id={props.questionid}>
              {rows.map((option) => {
                let checked;
                {
                  parseFloat(radioOption) === parseFloat(option.value)
                    ? (checked = "checked")
                    : (checked = "");
                }
                return (
                  <div className="input_wrap" key={option.index}>
                    <input
                      type="radio"
                      id={props.questionid + option.value}
                      name={props.questionid}
                      value={option.value}
                      readOnly
                      onClick={saveValue}
                      checked={checked}
                    />
                    <label htmlFor={props.questionid + option.value}>
                      {option.value}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      {props.type === "gad" && (
        <div className="gad_row">
          <h3>{props.questionTitle}</h3>
          <div className="dot_radio req" id={props.questionid}>
            {rows.map((option) => {
              let checked;
              {
                parseFloat(radioOption) === parseFloat(option.value)
                  ? (checked = "checked")
                  : (checked = "");
              }
              return (
                <div className="input_wrap" key={option.index}>
                  <input
                    type="radio"
                    id={props.questionid + option.value}
                    name={props.questionid}
                    value={option.value}
                    readOnly
                    onClick={saveValue}
                    checked={checked}
                  />
                  <label htmlFor={props.questionid + option.value}>
                    {option.value}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default DotRadio;
