import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import "../styles/componentStyles/ThinHorizontalSlider.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMeh, faFrown, faSmile } from "@fortawesome/pro-light-svg-icons";

const Slider = (props) => {
  const { t } = useTranslation();
  const [isDragging, setIsDragging] = useState(false);
  const [cursor, setCursor] = useState("grab");

  const handleMouseDown = () => {
    setIsDragging(true);
    setCursor("dragging");
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setCursor("drag");
  };

  useEffect(() => {
    let slider = document.getElementById("ths-slider-cont");

    if (isDragging) {
      slider.addEventListener("mousemove", handleMouseMove);
      slider.addEventListener("touchmove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
      document.addEventListener("touchend", handleMouseUp);
      return () => {
        slider.removeEventListener("mousemove", handleMouseMove);
        slider.removeEventListener("touchmove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
        document.removeEventListener("touchend", handleMouseUp);
      };
    }
  }, [isDragging]);

  const handleMouseMove = (event) => {
    if (isDragging) {
      if (event.type === "touchmove") {
        event.preventDefault();
        event = event.touches[0];
      }
      const slider = document.getElementById("ths-slider");
      const rect = slider.getBoundingClientRect();
      const offsetX = event.clientX - rect.left;
      const newValue = (offsetX * 100) / rect.width;

      if (newValue <= 100 && newValue >= 0) {
        props.handleMoodList(newValue);
      }
    }
  };

  const getThumbIcon = () => {
    if (props.moodValue < 34) {
      return faFrown;
    } else if (props.moodValue > 66) {
      return faSmile;
    } else {
      return faMeh;
    }
  };

  return (
    <div className="slider-cont" id="ths-slider-cont">
      <div
        id="ths-slider"
        style={{
          background:
            "linear-gradient(to right, #e92364 0%, #e92364 " +
            (props.moodValue - 1) +
            "%, #ddcedb " +
            (props.moodValue - 1) +
            "%, #ddcedb 100%)",
        }}
      >
        <div
          id="ths-thumb"
          style={{
            left: `calc(-25px + ${props.moodValue - 1}%)`,
            cursor: `${cursor}`,
          }}
          onMouseDown={handleMouseDown}
          onTouchStart={handleMouseDown}
        >
          <FontAwesomeIcon icon={getThumbIcon()} className="thumb-icon" />
        </div>
      </div>
      <div className="ths-values">
        <p>{t("T203")}</p>
        <p>{t("T204")}</p>
      </div>
    </div>
  );
};

export default Slider;
