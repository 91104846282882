import React, { useEffect, useState } from "react";

import Nouislider from "@x1mrdonut1x/nouislider-react";
import "nouislider/distribute/nouislider.css";

import "./Style.css";

import ModalTrigger from "../../../shared/modal/ModalTrigger";

const DialSlider = (props) => {
  const [textValue, setTextValue] = useState("");
  const [rotation, setRotation] = useState("");
  const [startValue, setStartValue] = useState("");

  const convertPercentToRotation = (value) => {
    let newRotation = -90 + (180 / 100) * value;
    if (newRotation >= 360) {
      newRotation = -360;
    }
    return newRotation;
  };

  const convertPercentValues = (value) => {
    if (props.scale === 5) {
      switch (value) {
        case "1":
          return 10;
        case "2":
          return 30;
        case "3":
          return 50;
        case "4":
          return 70;
        default:
          return 90;
      }
    } else {
      switch (value) {
        case "1":
          return 5;
        case "2":
          return 15;
        case "3":
          return 25;
        case "4":
          return 35;
        case "5":
          return 45;
        case "6":
          return 55;
        case "7":
          return 65;
        case "8":
          return 75;
        case "9":
          return 85;
        default:
          return 95;
      }
    }
  };

  useEffect(() => {
    if (props.assessmentValues[props.questionid]) {
      if (props.assessmentValues[props.questionid].textValue) {
        let newRotation = convertPercentToRotation(
          props.assessmentValues[props.questionid].percentValue
        );
        setRotation(newRotation);
        setTextValue(props.assessmentValues[props.questionid].textValue);
        setStartValue(props.assessmentValues[props.questionid].percentValue);
      } else {
        setTextValue(props.assessmentValues[props.questionid].toString());
        let percentages = convertPercentValues(
          props.assessmentValues[props.questionid].toString()
        );
        setStartValue(percentages);
        let newRotation = convertPercentToRotation(percentages);
        setRotation(newRotation);
      }
    } else {
      setRotation(0);
      props.scale === 5 ? setTextValue("3") : setTextValue("5");
      setStartValue(50);
      props.onSetValue({
        id: props.questionid,
        value: props.scale === 5 ? "3" : "5",
      });
    }
  }, []);

  const onSlide = (render, handle, value, un, percent) => {
    let degree = -90 + (180 / 100) * value[0].toFixed(0);
    let newRotation = degree;
    if (newRotation >= 360) {
      newRotation = -360;
    }

    var statusValue;
    if (value[0].toFixed(0) < 10) {
      statusValue = 1;
    } else if (value[0].toFixed(0) < 20) {
      props.scale === 5 ? (statusValue = 1) : (statusValue = 2);
    } else if (value[0].toFixed(0) < 30) {
      props.scale === 5 ? (statusValue = 2) : (statusValue = 3);
    } else if (value[0].toFixed(0) < 40) {
      props.scale === 5 ? (statusValue = 2) : (statusValue = 4);
    } else if (value[0].toFixed(0) < 50) {
      props.scale === 5 ? (statusValue = 3) : (statusValue = 5);
    } else if (value[0].toFixed(0) < 60) {
      props.scale === 5 ? (statusValue = 3) : (statusValue = 6);
    } else if (value[0].toFixed(0) < 70) {
      props.scale === 5 ? (statusValue = 4) : (statusValue = 7);
    } else if (value[0].toFixed(0) < 80) {
      props.scale === 5 ? (statusValue = 4) : (statusValue = 8);
    } else if (value[0].toFixed(0) < 90) {
      props.scale === 5 ? (statusValue = 5) : (statusValue = 9);
    } else {
      props.scale === 5 ? (statusValue = 5) : (statusValue = 10);
    }

    setRotation(newRotation);
    setTextValue(statusValue);

    props.onSetValue({ id: props.questionid, value: statusValue.toString() });
  };

  return (
    <div id={props.questionid} className={"req question_block" + props.width}>
      <h3>{props.questionTitle}</h3>
      {props.modalText && (
        <ModalTrigger
          modalText={props.modalText}
          onOpenModal={props.onOpenModal}
        />
      )}
      <div className="answer_block">
        <div className={"dialSlider " + props.width} id={props.questionid}>
          <div className="dial">
            <div
              className="needle"
              style={{ transform: `rotate(${rotation}deg)` }}
            ></div>
          </div>

          <Nouislider
            id="dial_slider"
            range={{ min: 0, max: 100 }}
            start={startValue}
            connect="lower"
            onSlide={onSlide}
          />

          <div className="sliderValues" id={props.questionid + "_value_handle"}>
            <div>{props.textValue[0]} </div>
            <div
              className="valueHandle"
              id={props.questionid + "_value_handle"}
            >
              {textValue}
            </div>
            <div>{props.textValue[1]}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DialSlider;
