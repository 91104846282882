import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CircleGraph from "../../components/graphs/circleGraph/CircleGraph";
import BarChart from "../../components/graphs/barChart/BarChart";

function Demographics(props) {
  const { t } = useTranslation();
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const alcohol_consumption = [
    { date: t("AS001"), value: props.data.alcohol_units_14, height: "0%" },
    { date: t("AS002"), value: props.data.alcohol_units_27, height: "0%" },
    { date: t("AS003"), value: props.data.alcohol_units_28, height: "0%" },
  ];
  const smoking_status = [
    { date: t("AS004"), value: props.data.smoke_yes, height: "0%" },
    { date: t("AS005"), value: props.data.smoke_no, height: "0%" },
  ];

  const binge_drink = [
    { date: t("AS006"), value: props.data.binge_drinking_never, height: "0%" },
    {
      date: t("AS007"),
      value: props.data.binge_drinking_less_than_monthly,
      height: "0%",
    },
    {
      date: t("AS008"),
      value: props.data.binge_drinking_monthly,
      height: "0%",
    },
    { date: t("AS009"), value: props.data.binge_drinking_weekly, height: "0%" },
    { date: t("AS010"), value: props.data.binge_drinking_daily, height: "0%" },
  ];
  return (
    <React.Fragment>
      {!props.activeFilter && !props.isLoading && (
        <BarChart
          title={t("AS011")}
          data={alcohol_consumption}
          unit="%"
          width="wide"
          startZero
          modalText={t("AS012")}
          onOpenModal={props.onOpenModal}
        />
      )}
      <CircleGraph
        title={t("AS013")}
        square
        colour="colour1"
        value={props.data.dont_drink_no}
        large
        percent={props.data.dont_drink}
        modalText={t("AS014")}
        onOpenModal={props.onOpenModal}
      />
      <CircleGraph
        title={t("AS015")}
        square
        colour="colour2"
        value={props.data.drink_over_14_no}
        large
        percent={props.data.drink_over_14}
        modalText={t("AS016")}
        onOpenModal={props.onOpenModal}
      />
      <BarChart
        title={t("AS017")}
        data={binge_drink}
        unit="%"
        width="wide"
        startZero
        modalText={t("AS018")}
        onOpenModal={props.onOpenModal}
      />
      <CircleGraph
        title={t("AS019")}
        square
        colour="colour3"
        value={props.data.hangover_productivity_no}
        large
        percent={props.data.hangover_productivity}
        modalText={t("AS020")}
        onOpenModal={props.onOpenModal}
      />

      <BarChart
        title={t("AS021")}
        data={smoking_status}
        unit="%"
        startZero
        modalText={t("AS022")}
        onOpenModal={props.onOpenModal}
      />
      <CircleGraph
        title={t("AS023")}
        square
        colour="colour1"
        value={props.data.smoked_per_day}
        subText={t("AS024")}
        large
        modalText={t("AS025")}
        onOpenModal={props.onOpenModal}
      />
    </React.Fragment>
  );
}
export default Demographics;
