import React, { useState, useEffect } from "react";
import "./Style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalWrapper from "../../../../../shared/modal/ModalWrapper";
import { faInfo } from "@fortawesome/pro-solid-svg-icons";

function BarChart(props) {
  const [values, setDataValues] = useState(props.data);

  const unit = props.unit;
  const lowerVal = 0;

  const [barsList, setBarsList] = useState([]);

  useEffect(() => {
    let newvalues = props.data;

    let valuearray = [];
    newvalues.map((item) => {
      valuearray.push(item.value);
    });
    let upperVal = props.max || Math.round(Math.max(...valuearray));

    let step1 = Math.ceil((upperVal - lowerVal) / 4);
    let rows = 5;
    if(props.max === 10){
      step1 = Math.ceil((props.max - lowerVal) / 5);
      rows = 6;
    }
    if (step1 < 1) {
      step1 = 1;
    }
    let step = step1;
    let avVal = Math.round((lowerVal + upperVal) / 2);

    //buildRows();
    var bars = [];
    let p = 0;
    for (var i = lowerVal; i < rows; i++) {
      //upperval
      //alert(i);
      bars[i] = { key: i, value: p }; //i
      p = p + step; //-1
    }
    setBarsList(bars.reverse());

    //setColumnHeights();
    var newHeight = "";
    //var range = upperVal - lowerVal;
    var range = props.max || (step * (rows - 1));

    for (var n = 0; n < newvalues.length; n++) {
      newHeight = Math.round((100 / range) * (newvalues[n].value - lowerVal));
      //newHeight = (100 / range) * (values[n].value - lowerVal);
      if (newHeight === 0) {
        newHeight = 1;
      }
      newvalues[n].height = newHeight + "%";
    }

    setDataValues(newvalues);
  }, [props.data]);

  return (
    <div className={`${props.width ? "square " + props.width : "square"}`}>
      <div className="square_content bar_chart_container ">
        {props.modalText && (
          <ModalWrapper
            modalText={props.modalText}
            onOpenModal={props.onOpenModal}
          >
            <FontAwesomeIcon icon={faInfo} className="icon info_icon" />
          </ModalWrapper>
        )}
        {props.title ? <h3>{props.title}</h3> : ""}
        {props.subTitle ? (
          <span className="subtitle">{props.subTitle}</span>
        ) : (
          ""
        )}
        <div className={`${props.yaxis ? "bar-chart yaxis" : "bar-chart"}`}>
          {props.yaxis && <div className="yaxis">{props.yaxis}</div>}
          {barsList && values && (
            <React.Fragment>
              {barsList.map((item) => {
                return (
                  <div className="row" key={item.key}>
                    <span>
                      {item.value} {unit}
                    </span>
                  </div>
                );
              })}
              <div className="column-container">
                {values.map((item, index) => {
                  return (
                    <div
                      className={`${
                        item.date === "DD MM" ? "blank column" : "column"
                      }`}
                      style={{ height: item.height }}
                      key={item.date + index}
                    >
                      <span>{item.date}</span>
                      <span className="hover_box">
                        {item.value}
                        {unit}
                      </span>
                    </div>
                  );
                })}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

export default BarChart;
