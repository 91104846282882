import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  faBoltLightning,
  faFaceSmile,
  faSpa,
} from "@fortawesome/pro-regular-svg-icons";

import BarChart from "../components/BarChart";
import Slider from "../../shared/components/slider/Slider";

import { colours } from "../../assets/colours";
import LineChart from "../components/LineChart";
import TopThreeChart from "../components/TopThreeChart";
import CircleValueTile from "../components/CircleValueTile";
import JournalBlock from "../components/JournalBlock";
import MotivationTile from "../components/MotivationTile";
import { emotions } from "../../shared/components/data";

const MentalWellbeingResults = (props) => {
  const { t } = useTranslation();
  const { assessScores, trackerScores, trackerJournals } = props.dataObj;
  const { assessDates, assessmentNumber } = props.pageObjRef?.current;
  const [journalEntries, setJournalEntries] = useState([]);

  useEffect(() => {
    if (trackerJournals?.length > 0) {
      trackerJournals.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateB - dateA; // Newest to oldest
      });
      setJournalEntries(trackerJournals);
    }
  }, [trackerJournals, trackerJournals?.length]);

  return (
    <div className="results-container">
      {props.showAssessment &&
        assessScores?.mood_rating_data &&
        assessScores?.stress_rating_data && (
          <div className="results-row ">
            <LineChart
              title={t("D198")}
              icon={faFaceSmile}
              average={assessScores.mood_rating_data_average}
              data={assessScores.mood_rating_data}
              units="out of 5"
              yMaxLimit={5}
              average_type={t("D079")}
              width="half bars"
              color={colours.blue}
              modalText={[t("D199"), t("D200"), t("D201")]}
              openModal={props.openModal}
              assessmentNumber={assessmentNumber}
              assessDates={assessDates}
              outOfYMax //for the custom tooltip
            />
            <BarChart
              title={t("D202")}
              icon={faBoltLightning}
              color={colours.red}
              data={assessScores.stress_rating_data}
              average={assessScores.stress_rating_data_average}
              units="out of 5"
              yMaxLimit={5}
              average_type={t("D079")}
              width="half bars"
              modalText={[t("D203"), t("D204"), t("D205")]}
              openModal={props.openModal}
              assessmentNumber={assessmentNumber}
              assessDates={assessDates}
              outOfYMax //for the custom tooltip
              stressDescriptor //to show stress descriptor
            />
            <CircleValueTile
              title={t("D206")}
              textValue={assessScores.anxiety_level}
              value={assessScores.gad_score}
              maxValue={21}
              colour={colours[assessScores.anxiety_colour]}
              width="quarter"
              modalText={[t("D207")]}
              openModal={props.openModal}
            />
            <CircleValueTile
              title={t("D208")}
              textValue={assessScores.depression_level}
              value={assessScores.phq_score}
              maxValue={27}
              colour={colours[assessScores.depression_colour]}
              width="quarter"
              modalText={[t("D209")]}
              openModal={props.openModal}
            />
          </div>
        )}
      {!props.showAssessment &&
        trackerScores?.mood?.mood_rating.length > 0 &&
        trackerScores?.stress?.stress_at_moment.length > 0 && (
          <div className="results-row ">
            <LineChart //mood rating
              title={t("D198")}
              average={trackerScores.mood.mood_rating_average}
              data={trackerScores.mood.mood_rating}
              icon={faFaceSmile}
              units={`${t("D216")} 5`}
              yMaxLimit={5}
              average_type={t("D079")}
              width="half bars"
              color={colours.blue}
              modalText={[t("DW028"), t("D200"), t("DW029")]}
              openModal={props.openModal}
              outOfYMax
            />
            <BarChart //stress rating
              title={t("D202")}
              average={trackerScores.stress.stress_at_moment_average}
              data={trackerScores.stress.stress_at_moment}
              icon={faBoltLightning}
              units={`${t("D216")} 5`}
              yMaxLimit={5}
              average_type={t("D079")}
              width="half bars"
              color={colours.red}
              modalText={[t("DW030"), t("D204"), t("DW031")]}
              openModal={props.openModal}
              outOfYMax //for the custom tooltip
              stressDescriptor //to show stress descriptor
            />
            <TopThreeChart //causes of stress
              title={t("D210")}
              data={trackerScores.stress.top_causes_of_stress}
              units="%"
              width="half bars"
              colour={colours.blue}
              modalText={[t("D211")]}
              openModal={props.openModal}
            />
            <BarChart //mindfulness
              title={t("D212")}
              data={trackerScores.mood.mindfulness}
              icon={faSpa}
              average={trackerScores.mood.mindfulness_average}
              units={t("D076")}
              average_type={t("DW033")}
              width="half bars"
              color={colours.green}
              modalText={[t("D213")]}
              openModal={props.openModal}
            />
            <MotivationTile
              title={t("D214")} //emotions
              modalText={[t("D215")]}
              openModal={props.openModal}
              // colour={colours.blue}
              data={trackerScores.mood.topEnhancers}
              valueArr={emotions}
              width="quarter"
            />

            {journalEntries.length > 0 && (
              <JournalBlock
                width="quarter"
                startWith={7}
                journalArr={journalEntries}
                openModal={props.openModal}
                mentalWellbeing
              />
            )}
          </div>
        )}

      {/* {props.slidersList?.[1] && (
            <div className="results-row">
              <Slider
                title={props.slidersList[1].title}
                key={props.slidersList[1].id}
                id={props.slidersList[1].id}
                media={props.slidersList[1].media}
                favourites={favourites}
                dislikes={dislikes}
                progressList={progressList}
                onOpenMediaModal={openMediaModal}
                addLikeDislike={addLikeDislike}
                target={props.slidersList[1].target || false}
              />
            </div>
          )} */}
    </div>
  );
};
export default MentalWellbeingResults;
