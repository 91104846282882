import React, { useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useHttpClient } from "../../../hooks/http-hook";

import microsoftLogo from "./Images/microsoft-logo.svg";
import { checkImage } from "../../../util/helperfunctions";

if (typeof window !== "undefined") {
  window.Buffer = require("buffer").Buffer;
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
const loginRequest = { scopes: ["User.Read"] };

// Add the endpoints here for Microsoft Graph API services you'd like to use.
const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  graphMePhotoEndpoint: "https://graph.microsoft.com/v1.0/me/photo/$value",
};

//Example here https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-react-samples/react-router-sample/src/authConfig.js
const msalInstanceSignIn = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_MS_AUTH_CLIENT_ID,
    authority: "https://login.microsoftonline.com/common", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: `${window.location.origin}/sign-in`,
  },
});

const msalInstanceSignUp = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_MS_AUTH_CLIENT_ID,
    authority: "https://login.microsoftonline.com/common", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: `${window.location.origin}/signup`,
  },
});

const SignInButton = (props) => {
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { t } = useTranslation();
  const [token, setToken] = useState();

  const { isLoading, error, sendRequest } = useHttpClient();

  useEffect(() => {
    props.setCompLoading(isLoading);
  }, [isLoading, props]);

  useEffect(() => {
    if (error) {
      props.setErrorMessage(error);
    }
  }, [error, props]);

  const requestUserInfo = useCallback(
    async (tokenInfo) => {
      await setToken(tokenInfo);
      const headers = new Headers();
      headers.append("Authorization", `Bearer ${tokenInfo.accessToken}`);

      const options = {
        method: "GET",
        headers: headers,
      };

      // Get User Data
      const userData = await fetch(graphConfig.graphMeEndpoint, options)
        .then((response) => response.json())
        .catch((error) => console.log(error));

      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/signInMicrosoft`,
        "POST",
        JSON.stringify({
          account: userData,
          token: tokenInfo,
          fandfUser: props.fandfUser,
          clientId: props.clientid,
          deviceLanguage: localStorage.getItem("deviceLanguage") || "",
        })
      );

      if (!props.signIn || !response.user?.completed_registration) {
        //Fetch Microsoft profile picture
        const photoBlob = await fetch(graphConfig.graphMePhotoEndpoint, options)
          .then((response) => response.blob())
          .catch((error) => console.log(error));

        const validImage = await checkImage(photoBlob);

        if (validImage) {
          const fileName = response.user?._id ?? response.userId;

          // Convert the blob to a File object
          const profileImageFile = new File([photoBlob], fileName, {
            type: photoBlob.type,
          });

          // Get Signed URL for AWS S3 upload
          const presignedUrl = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/users/getSignedUrl/${
              response.user?._id ?? response.userId
            }?filename=${encodeURIComponent(fileName)}`,
            "PUT"
          );

          if (presignedUrl) {
            // Upload image to AWS S3
            await sendRequest(
              presignedUrl.toString(),
              "PUT",
              profileImageFile,
              {
                "Content-Type": "image/jpeg",
              }
            );

            let url = presignedUrl.split("?")[0];

            //Pass response and profile picture to ensure it saves on user's profile creation
            if (props.handleResponse) {
              props.handleResponse({ ...response, profilePic: url });
            }
          }
        } else {
          if (props.handleResponse) {
            props.handleResponse(response);
          }
        }
      } else {
        if (props.handleResponse) {
          props.handleResponse(response);
        }
      }
    },
    [props, sendRequest]
  );

  const validateAuthentication = useCallback(async () => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then(async (response) => {
        await requestUserInfo(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [accounts, instance, requestUserInfo]);

  useEffect(() => {
    instance
      .handleRedirectPromise()
      .then(async (response) => {
        if (response != null && response.account != null) {
          if (isAuthenticated && inProgress === "none" && token == null) {
            await validateAuthentication(response);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [validateAuthentication, inProgress, isAuthenticated, instance, token]);

  const handleLogin = () => {
    instance.loginRedirect(loginRequest);
  };

  return (
    <button className="grey_theme" onClick={handleLogin} id="microsoft">
      <img src={microsoftLogo} className="icon signup" alt="Microsoft Logo" />
      {t("R048")}
    </button>
  );
};

export default function MicrosoftAuthButton(props) {
  return (
    <MsalProvider
      instance={props.signIn ? msalInstanceSignIn : msalInstanceSignUp}
    >
      <SignInButton {...props} />
    </MsalProvider>
  );
}
